import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API, API_FORM } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, isUndefined, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit, FiUpload} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"
import { Link } from "react-router-dom"

class Layanan extends React.Component{
    state={
        layanan:[],
        hapus_layanan:{
            is_open:false,
            id_layanan:""
        }
    }

    componentDidMount=()=>{
        this.getsLayanan()
    }
    getsLayanan=()=>{
        API("/adminweb/layanan/gets_layanan", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                layanan:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //HAPUS
    showHapusLayanan=data=>{
        this.setState({
            hapus_layanan:{
                is_open:true,
                id_layanan:data.id_layanan
            }
        })
    }
    hideHapusLayanan=()=>{
        this.setState({
            hapus_layanan:{
                is_open:false,
                id_layanan:""
            }
        })
    }
    deleteLayanan=()=>{
        API("/adminweb/layanan/delete_layanan", {
            tokenID:access_token(),
            data:this.state.hapus_layanan
        })
        .then(res=>{
            this.getsLayanan()
            this.hideHapusLayanan()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }


    render(){
        const {layanan, hapus_layanan}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex mb-4">
                                        <Link to="/layanan/add" className="btn btn-primary">
                                            <AiOutlinePlus/> Tambah Layanan
                                        </Link>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">Nama Layanan</th>
                                                    <th class="border-0">Link To</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {layanan.map((list, idx)=>(
                                                    <tr>
                                                        <td className="align-middle">{(idx+1)}</td>
                                                        <td>{list.nama_layanan}</td>
                                                        <td>{`/layanan/${list.id_layanan}/${list.nama_layanan_slug}`}</td>
                                                        <td>
                                                            <Link to={`/layanan/edit/${list.id_layanan}`} className="btn btn-warning btn-sm">
                                                                Edit
                                                            </Link>
                                                            <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusLayanan(list)}>
                                                                Hapus
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_layanan.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusLayanan()}
                    deleteAction={()=>this.deleteLayanan()}
                />
            </>
        )
    }
}

export default navAuth(Layanan, {
    title:"Semua Layanan"
})