import React from "react"
import { toast } from "react-toastify"
import { API } from "../config/api"
import { goto_page } from "../config/config"

class Login extends React.Component{
    state={
        username:"",
        password:"",
        remember:false
    }

    componentDidMount=()=>{
    }

    //login
    typeLogin=e=>{
        const target=e.target

        let value
        if(target.type==="checkbox"){
            value=target.checked
        }
        else{
            value=target.value
        }

        this.setState({
            [target.name]:value
        })
    }
    login=e=>{
        e.preventDefault()

        const {username, password, remember}=this.state

        API("/adminweb/authentication/verify_login", {
            data:{
                username:username,
                password:password,
                remember:remember
            }
        })
        .then(res=>{
            localStorage.setItem("user_data", JSON.stringify(res.data.data))
            goto_page("/")
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_LOGIN_FAILED":
                    toast.warning("Login Gagal! Username atau Password salah.")
                break;
            }
        })
    }

    render(){
        const {username, password, remember}=this.state

        return  (
            <main>
                <section class="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                    <div class="container">
                        <div class="row justify-content-center form-bg-image" style={{background:"url('https://demo.themesberg.com/volt/assets/img/illustrations/signin.svg')"}}>
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <div class="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                    <div class="text-center text-md-center mb-4 mt-md-0">
                                        <h1 class="mb-0 h3">Masuk ke Akun Anda!</h1>
                                    </div>
                                    <form onSubmit={this.login}>
                                        <div class="form-group mb-4">
                                            <label for="email">Username</label>
                                            <div class="input-group">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <svg class="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                                </span>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    placeholder="Username" 
                                                    value={username}
                                                    name="username"
                                                    onChange={this.typeLogin}
                                                />
                                            </div>  
                                        </div>
                                        <div class="form-group">
                                            <div class="form-group mb-4">
                                                <label for="password">Password</label>
                                                <div class="input-group">
                                                    <span class="input-group-text" id="basic-addon2">
                                                        <svg class="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd"></path></svg>
                                                    </span>
                                                    <input 
                                                        type="password" 
                                                        placeholder="Password" 
                                                        class="form-control"
                                                        value={password}
                                                        name="password"
                                                        onChange={this.typeLogin}
                                                    />
                                                </div>  
                                            </div>
                                            <div class="d-flex justify-content-between align-items-top mb-4">
                                                <div class="form-check">
                                                    <input 
                                                        class="form-check-input" 
                                                        type="checkbox" 
                                                        checked={remember}
                                                        name="remember"
                                                        onChange={this.typeLogin}
                                                        id="remember"
                                                    />
                                                    <label class="form-check-label mb-0" for="remember">
                                                        Remember me
                                                    </label>
                                                </div>
                                                <div><a href="" class="small text-right">Lost password?</a></div>
                                            </div>
                                        </div>
                                        <div class="d-grid">
                                            <button type="submit" class="btn btn-gray-800">Sign in</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default Login