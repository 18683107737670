import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"

class Spesialis extends React.Component{
    state={
        spesialis:[],
        filter:{
            page:1,
            per_page:10,
            count_page:0,
            count_data:0
        },
        tambah_spesialis:{
            is_open:false,
            nama_spesialis:""
        },
        edit_spesialis:{
            is_open:false,
            id_spesialis:"",
            nama_spesialis:""
        },
        hapus_spesialis:{
            is_open:false,
            id_spesialis:""
        }
    }

    componentDidMount=()=>{
        this.getListSpesialis()
    }
    getListSpesialis=()=>{
        API("/adminweb/spesialis/gets_spesialis", {
            tokenID:access_token(),
            data:{
                filter:this.state.filter
            }
        })
        .then(res=>{
            this.setState({
                spesialis:res.data.data,
                filter:update(this.state.filter, {
                    count_page:{$set:res.data.count_page},
                    count_data:{$set:res.data.count_data}
                })
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    goToPage=page=>{
        this.setState({
            filter:update(this.state.filter, {
                page:{$set:page}
            })
        }, ()=>{
            this.getListSpesialis()
        })
    }

    //TAMBAH SPESIALIS
    showTambahSpesialis=()=>{
        this.setState({
            tambah_spesialis:{
                is_open:true,
                nama_spesialis:""
            }
        })
    }
    hideTambahSpesialis=()=>{
        this.setState({
            tambah_spesialis:{
                is_open:false,
                nama_spesialis:""
            }
        })
    }
    typeTambahSpesialis=e=>{
        const target=e.target

        this.setState({
            tambah_spesialis:update(this.state.tambah_spesialis, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addSpesialis=e=>{
        e.preventDefault()

        const {tambah_spesialis}=this.state
        API("/adminweb/spesialis/add_spesialis", {
            tokenID:access_token(),
            data:{
                nama_spesialis:tambah_spesialis.nama_spesialis
            }
        })
        .then(res=>{
            this.getListSpesialis()
            this.hideTambahSpesialis()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //EDIT SPESIALIS
    showEditSpesialis=data=>{
        this.setState({
            edit_spesialis:{
                is_open:true,
                id_spesialis:data.id_spesialis,
                nama_spesialis:data.nama_spesialis
            }
        })
    }
    hideEditSpesialis=()=>{
        this.setState({
            edit_spesialis:{
                is_open:false,
                id_spesialis:"",
                nama_spesialis:""
            }
        })
    }
    typeEditSpesialis=e=>{
        const target=e.target

        this.setState({
            edit_spesialis:update(this.state.edit_spesialis, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateSpesialis=e=>{
        e.preventDefault()

        API("/adminweb/spesialis/update_spesialis", {
            tokenID:access_token(),
            data:this.state.edit_spesialis
        })
        .then(res=>{
            this.getListSpesialis()
            this.hideEditSpesialis()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //HAPUS SPESIALIS
    showHapusSpesialis=data=>{
        this.setState({
            hapus_spesialis:{
                is_open:true,
                id_spesialis:data.id_spesialis
            }
        })
    }
    hideHapusSpesialis=()=>{
        this.setState({
            hapus_spesialis:{
                is_open:false,
                id_spesialis:""
            }
        })
    }
    deleteSpesialis=()=>{
        API("/adminweb/spesialis/delete_spesialis", {
            tokenID:access_token(),
            data:this.state.hapus_spesialis
        })
        .then(res=>{
            this.getListSpesialis()
            this.hideHapusSpesialis()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    render(){
        const {filter, spesialis, tambah_spesialis, edit_spesialis, hapus_spesialis}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex mb-4">
                                        <button className="btn btn-primary" onClick={this.showTambahSpesialis}>
                                            <AiOutlinePlus/> Tambah Spesialis
                                        </button>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">Nama Spesialis</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {spesialis.map((list, idx)=>(
                                                    <tr>
                                                        <td className="align-middle">{(idx+1)+((filter.page-1)*filter.per_page)}</td>
                                                        <td>
                                                            {list.nama_spesialis}
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-warning btn-sm" onClick={()=>this.showEditSpesialis(list)}>
                                                                Edit
                                                            </button>
                                                            <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusSpesialis(list)}>
                                                                Hapus
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-4">
                                        <div className="d-flex flex-column">
                                            <div>Halaman {filter.page} dari {filter.count_page} Halaman</div>
                                            <div>Total Data : <span class="fw-bold fs-6">{filter.count_data}</span></div>
                                        </div>
                                        <div>
                                            <button 
                                                className="btn btn-gray-700" 
                                                disabled={filter.page<=1}
                                                onClick={()=>this.goToPage(filter.page-1)}
                                            >
                                                Sebelumnya
                                            </button>
                                            <button 
                                                className="btn btn-gray-700 ms-2" 
                                                disabled={filter.page>=filter.count_page}
                                                onClick={()=>this.goToPage(filter.page+1)}
                                            >
                                                Selanjutnya
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH SPESIALIS */}
                <Modal show={tambah_spesialis.is_open} onHide={this.hideTambahSpesialis}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Spesialis</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addSpesialis}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Nama Spesialis</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_spesialis"
                                onChange={this.typeTambahSpesialis}
                                value={tambah_spesialis.nama_spesialis}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahSpesialis}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={tambah_spesialis.nama_spesialis===""}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL EDIT SPESIALIS */}
                <Modal show={edit_spesialis.is_open} onHide={this.hideEditSpesialis}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Spesialis</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateSpesialis}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Nama Spesialis</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_spesialis"
                                onChange={this.typeEditSpesialis}
                                value={edit_spesialis.nama_spesialis}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditSpesialis}>Batal</button>
                        <button type="submit" class="btn btn-info">Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_spesialis.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusSpesialis()}
                    deleteAction={()=>this.deleteSpesialis()}
                />
            </>
        )
    }
}

export default navAuth(Spesialis, {
    title:"Manajemen Spesialis"
})