import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API, API_FORM } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, isUndefined, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit, FiUpload} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"
import { Link } from "react-router-dom"

class WidgetDashboard extends React.Component{
    state={
        layanan:[],
        hapus_widget:{
            is_open:false,
            id_widget:""
        }
    }

    componentDidMount=()=>{
        this.getsWidget()
    }
    getsWidget=()=>{
        API("/adminweb/widget_dashboard/gets_widget", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                layanan:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //HAPUS
    showHapusWidget=data=>{
        this.setState({
            hapus_widget:{
                is_open:true,
                id_widget:data.id_widget
            }
        })
    }
    hideHapusWidget=()=>{
        this.setState({
            hapus_widget:{
                is_open:false,
                id_widget:""
            }
        })
    }
    deleteWidget=()=>{
        API("/adminweb/widget_dashboard/delete_widget", {
            tokenID:access_token(),
            data:this.state.hapus_widget
        })
        .then(res=>{
            this.getsWidget()
            this.hideHapusWidget()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }


    render(){
        const {layanan, hapus_widget}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex mb-4">
                                        <Link to="/widget_dashboard/add" className="btn btn-primary">
                                            <AiOutlinePlus/> Tambah Widget
                                        </Link>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">Widget/Block</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {layanan.map((list, idx)=>(
                                                    <tr>
                                                        <td className="align-middle">{(idx+1)}</td>
                                                        <td>{list.judul}</td>
                                                        <td>
                                                            <Link to={`/widget_dashboard/edit/${list.id_widget}`} className="btn btn-warning btn-sm">
                                                                Edit
                                                            </Link>
                                                            <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusWidget(list)}>
                                                                Hapus
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_widget.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusWidget()}
                    deleteAction={()=>this.deleteWidget()}
                />
            </>
        )
    }
}

export default navAuth(WidgetDashboard, {
    title:"Widgets Dashboard"
})