import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../../config/config"
import navAuth from "../../nav_auth"
import Avatar from "../../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit} from "react-icons/fi"
import {datetime} from "../../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import {ConfirmDelete} from "../../../../component/ui/confirm"
import {toast} from "react-toastify"

class Penjual extends React.Component{
    state={
        users:[],
        regency:[],
        filter:{
            page:1,
            per_page:10,
            count_page:0,
            count_data:0
        },
        edit_user:{
            is_open:false,
            id_user:"",
            status:"unverified"
        },
        tambah_penjual:{
            is_open:false,
            nama_penjual:"",
            alamat:"",
            regency:"",
            username:"",
            password:""
        },
        hapus_user:{
            is_open:false,
            id_user:""
        }
    }

    componentDidMount=()=>{
        this.getsPenjual()
        this.getsRegency()
    }
    getsPenjual=()=>{
        API("/adminweb/penjual/gets_user", {
            tokenID:access_token(),
            data:{
                filter:this.state.filter
            }
        })
        .then(res=>{
            this.setState({
                users:res.data.data,
                filter:update(this.state.filter, {
                    count_page:{$set:res.data.count_page},
                    count_data:{$set:res.data.count_data}
                })
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getsRegency=()=>{
        API("/adminweb/region/gets_all_kabupaten", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                regency:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getUserStatus=status=>{
        switch(status){
            case "active":
                return <span className="badge bg-success">Aktif</span>
            break;
            case "unverified":
                return <span className="badge bg-warning text-dark">Belum Diverifikasi</span>
            break;
            case "suspended":
                return <span className="badge bg-danger">Disuspend</span>
            break;
        }
    }
    goToPage=page=>{
        this.setState({
            filter:update(this.state.filter, {
                page:{$set:page}
            })
        }, ()=>{
            this.getsPenjual()
        })
    }

    //EDIT USER
    showEditUser=data=>{
        this.setState({
            edit_user:{
                is_open:true,
                id_user:data.id_user,
                status:data.status
            }
        })
    }
    hideEditUser=()=>{
        this.setState({
            edit_user:{
                is_open:false,
                id_user:"",
                status:""
            }
        })
    }
    typeEditUser=e=>{
        const target=e.target

        this.setState({
            edit_user:update(this.state.edit_user, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updatePenjual=e=>{
        e.preventDefault()

        const {edit_user}=this.state
        API("/adminweb/penjual/update_penjual", {
            tokenID:access_token(),
            data:edit_user
        })
        .then(res=>{
            this.getsPenjual()
            this.hideEditUser()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }   

    //TAMBAH PENJUAL
    toggleTambahPenjual=()=>{
        this.setState({
            tambah_penjual:{
                is_open:!this.state.tambah_penjual.is_open,
                nama_penjual:"",
                alamat:"",
                regency:"",
                username:"",
                password:""
            }
        })
    }
    typeTambahPenjual=e=>{
        const target=e.target
        
        this.setState(prevState=>{
            return {
                ...prevState,
                tambah_penjual:{
                    ...prevState.tambah_penjual,
                    [target.name]:target.value
                }
            }
        })
    }
    addPenjual=e=>{
        e.preventDefault()

        const {tambah_penjual}=this.state
        API("/adminweb/penjual/add_penjual", {
            tokenID:access_token(),
            data:tambah_penjual
        })
        .then(res=>{
            this.getsPenjual()
            this.toggleTambahPenjual()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
                case "ERR_USERNAME_FOUND":
                    toast.warn("Username telah digunakan!")
                break;
            }
        })
    }

    //HAPUS USER
    showHapusUser=data=>{
        this.setState({
            hapus_user:{
                is_open:true,
                id_user:data.id_user
            }
        })
    }
    hideHapusUser=()=>{
        this.setState({
            hapus_user:{
                is_open:false,
                id_user:""
            }
        })
    }
    deleteUser=()=>{
        API("/adminweb/penjual/delete_penjual", {
            tokenID:access_token(),
            data:this.state.hapus_user
        })
        .then(res=>{
            this.hideHapusUser()
            this.getsPenjual()
        })
        .catch(err=>{
            if(err.response.status===401){
                this.props.history.push("/?status=token_expired")
            }
        })
    }

    render(){
        const {filter, users, edit_user, tambah_penjual, regency, hapus_user}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex mb-4">
                                        <button className="btn btn-primary" onClick={this.toggleTambahPenjual}>
                                            <AiOutlinePlus/> Tambah Penjual
                                        </button>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">User/Pengguna</th>
                                                    <th class="border-0">Region/Lokasi</th>
                                                    <th class="border-0">Alamat Lengkap</th>
                                                    <th class="border-0">Bergabung pada</th>
                                                    <th class="border-0">Status</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((list, idx)=>(
                                                    <tr>
                                                            <td className="align-middle">{(idx+1)+((filter.page-1)*filter.per_page)}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="avatar">
                                                                            <Avatar 
                                                                                data={list}
                                                                                circle
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <span className="fw-semibold text-capitalize ms-2">{list.nama_lengkap}</span>
                                                                </div>
                                                            </td>
                                                            <td>{list.regency_name}</td>
                                                            <td>{list.alamat}</td>
                                                            <td>
                                                                {datetime(list.created_at)}
                                                            </td>
                                                            <td>
                                                                {this.getUserStatus(list.status)}
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-warning btn-sm" onClick={()=>this.showEditUser(list)}>
                                                                    Edit
                                                                </button>
                                                                <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusUser(list)}>
                                                                    Hapus
                                                                </button>
                                                            </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-4">
                                        <div className="d-flex flex-column">
                                            <div>Halaman {filter.page} dari {filter.count_page} Halaman</div>
                                            <div>Total Data : <span class="fw-bold fs-6">{filter.count_data}</span></div>
                                        </div>
                                        <div>
                                            <button 
                                                className="btn btn-gray-200" 
                                                disabled={filter.page<=1}
                                                onClick={()=>this.goToPage(filter.page-1)}
                                            >
                                                Sebelumnya
                                            </button>
                                            <button 
                                                className="btn btn-gray-200 ms-2" 
                                                disabled={filter.page>=filter.count_page}
                                                onClick={()=>this.goToPage(filter.page+1)}
                                            >
                                                Selanjutnya
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH PENJUAL */}
                <Modal show={tambah_penjual.is_open} onHide={this.toggleTambahPenjual}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Penjual</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addPenjual}>
                    <Modal.Body>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Nama Penjual</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_penjual"
                                onChange={this.typeTambahPenjual}
                                value={tambah_penjual.nama_penjual}
                            />
                        </div>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Username</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="username"
                                onChange={this.typeTambahPenjual}
                                value={tambah_penjual.username}
                            />
                        </div>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Password</label>
                            <input 
                                type="password" 
                                className="form-control"
                                name="password"
                                onChange={this.typeTambahPenjual}
                                value={tambah_penjual.password}
                            />
                        </div><div class="mb-3">
                            <label class="my-1 me-2" for="country">Region/Lokasi</label>
                            <select 
                                class="form-select" 
                                name="regency"
                                value={tambah_penjual.regency}
                                onChange={this.typeTambahPenjual}
                            >
                                <option value="">-- Pilih Region</option>
                                {regency.map(reg=>(
                                    <option value={reg.id}>{reg.regency_name}</option>
                                ))}
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Alamat Lengkap</label>
                            <textarea
                                rows={2}
                                className="form-control"
                                name="alamat"
                                onChange={this.typeTambahPenjual}
                                value={tambah_penjual.alamat}
                            />
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer className="mt-3">
                        <button 
                            type="button" 
                            class="btn btn-link text-gray me-auto" 
                            onClick={this.toggleTambahPenjual}
                        >
                            Batal
                        </button>
                        <button 
                            type="submit" 
                            class="btn btn-info"
                            disabled={
                                tambah_penjual.nama_penjual.trim()===""||
                                tambah_penjual.regency.trim()===""||
                                tambah_penjual.username.trim()===""||
                                tambah_penjual.password.trim()===""
                            }
                        >
                            Save Changes
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL EDIT STATUS */}
                <Modal show={edit_user.is_open} onHide={this.hideEditUser}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Penjual</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updatePenjual}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Status</label>
                            <select 
                                class="form-select" 
                                name="status"
                                value={edit_user.status}
                                onChange={this.typeEditUser}
                            >
                                <option value="active">Aktif</option>
                                <option value="unverified">Belum Diverifikasi</option>
                                <option value="suspended">Disuspend</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditUser}>Batal</button>
                        <button type="submit" class="btn btn-info">Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_user.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusUser()}
                    deleteAction={()=>this.deleteUser()}
                />
            </>
        )
    }
}

export default navAuth(Penjual, {
    title:"Manajemen Penjual/Merchant"
})