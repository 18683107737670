import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API, API_FORM } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, isUndefined, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit, FiUpload} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"
import CustomLink from "../../../component/custom_link"
import uniqid from "uniqid"

class Jumbotron extends React.Component{
    state={
        banner:{
            judul:"",
            deskripsi:"",
            gambar:{},
            shortcut:[]
        },
        tambah_shortcut:{
            is_open:false,
            judul:"",
            icon:{},
            link_to:""
        },
        edit_shortcut:{
            is_open:false,
            shortcut:{},
            judul:"",
            icon:{},
            link_to:""
        },
        hapus_shortcut:{
            is_open:false,
            shortcut:{}
        },
        select_link:{
            is_open:false
        }
    }

    componentDidMount=()=>{
        this.getBanner()
    }
    getBanner=()=>{
        API("/adminweb/banners/get_banner_jumbotron", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                banner:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //EDIT BANNER
    typeEditBanner=e=>{
        const target=e.target

        this.setState({
            banner:update(this.state.banner, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateBanner=e=>{
        e.preventDefault()

        API("/adminweb/banners/update_banner_jumbotron", {
            tokenID:access_token(),
            data:this.state.banner
        })
        .then(res=>{
            this.getBanner()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //UPLOAD IMAGE
    uploadImage=(e)=>{
        const files=e.target.files
        const name=e.target.name

        //initialize form data
        const form_data=new FormData()
        form_data.append("image", files[0])
        form_data.append("path", "/banners")

        //upload to server
        API_FORM("/adminweb/file_manager/upload_image", {
            tokenID:access_token(),
            data:form_data
        })
        .then(res=>{
            if(name==="banner"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        banner:{
                            ...prevState.banner,
                            gambar:res.data.data
                        }
                    }
                })
            }
            else if(name==="tambah_shortcut"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        tambah_shortcut:{
                            ...prevState.tambah_shortcut,
                            icon:res.data.data
                        }
                    }
                })
            }
            else if(name==="edit_shortcut"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        edit_shortcut:{
                            ...prevState.edit_shortcut,
                            icon:res.data.data
                        }
                    }
                })
            }
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //TAMBAH SHORTCUT ICON
    toggleTambahShortcut=()=>{
        this.setState({
            tambah_shortcut:{
                is_open:!this.state.tambah_shortcut.is_open,
                judul:"",
                icon:{},
                link_to:""
            }
        })
    }
    typeTambahShortcut=e=>{
        const target=e.target

        this.setState({
            tambah_shortcut:update(this.state.tambah_shortcut, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addShortcut=e=>{
        e.preventDefault()

        const {tambah_shortcut}=this.state
        const data={
            id:uniqid(),
            judul:tambah_shortcut.judul,
            icon:tambah_shortcut.icon,
            link_to:tambah_shortcut.link_to
        }

        const shortcut=this.state.banner.shortcut.concat([data])
        this.typeEditBanner({
            target:{
                name:"shortcut",
                value:shortcut
            }
        })
        this.toggleTambahShortcut()
    }

    //EDIT SHORTCUT ICON
    showEditShortcut=data=>{
        this.setState({
            edit_shortcut:{
                is_open:true,
                shortcut:data,
                judul:data.judul,
                icon:data.icon,
                link_to:data.link_to
            }
        })
    }
    hideEditShortcut=()=>{
        this.setState({
            edit_shortcut:{
                is_open:false,
                shortcut:{},
                judul:"",
                icon:{},
                link_to:""
            }
        })
    }
    typeEditShortcut=e=>{
        const target=e.target

        this.setState({
            edit_shortcut:update(this.state.edit_shortcut, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateShortcut=e=>{
        e.preventDefault()

        const {edit_shortcut}=this.state
        const index=this.state.banner.shortcut.map(s=>JSON.stringify(s)).indexOf(JSON.stringify(edit_shortcut.shortcut))
        const data={
            id:uniqid(),
            judul:edit_shortcut.judul,
            icon:edit_shortcut.icon,
            link_to:edit_shortcut.link_to
        }

        const shortcut=update(this.state.banner.shortcut, {
            [index]:{$set:data}
        })
        this.typeEditBanner({
            target:{
                name:"shortcut",
                value:shortcut
            }
        })
        this.hideEditShortcut()
    }

    //HAPUS SHORTCUT ICON
    showHapusShortcut=data=>{
        this.setState({
            hapus_shortcut:{
                is_open:true,
                shortcut:data
            }
        })
    }
    hideHapusShortcut=()=>{
        this.setState({
            hapus_shortcut:{
                is_open:false,
                shortcut:{}
            }
        })
    }
    deleteShortcut=()=>{
        const shortcut=this.state.banner.shortcut.filter(s=>JSON.stringify(s)!==JSON.stringify(this.state.hapus_shortcut.shortcut))
        this.typeEditBanner({
            target:{
                name:"shortcut",
                value:shortcut
            }
        })
        this.hideHapusShortcut()
    }

    //SELECT LINK
    setLink=(data)=>{
        let target
        if(this.state.tambah_shortcut.is_open){
            target="tambah_shortcut"
        }
        else{
            target="edit_shortcut"
        }

        this.setState(prevState=>{
            return {
                ...prevState,
                [target]:{
                    ...prevState[target],
                    link_to:data
                }
            }
        })
    }
    showSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:true
            }
        })
    }
    hideSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:false
            }
        })
    }


    render(){
        const {banner, tambah_shortcut, edit_shortcut, hapus_shortcut, select_link}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <form onSubmit={this.updateBanner} class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-md-7 mx-auto">
                                            <div class="mb-4">
                                                <label class="my-1 me-2" for="country">Judul</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control"
                                                    name="judul"
                                                    onChange={this.typeEditBanner}
                                                    value={banner.judul}
                                                />
                                            </div>
                                            <div class="mb-4">
                                                <label class="my-1 me-2" for="country">Deskripsi</label>
                                                <textarea
                                                    rows={3} 
                                                    className="form-control"
                                                    name="deskripsi"
                                                    onChange={this.typeEditBanner}
                                                    value={banner.deskripsi}
                                                />
                                            </div>
                                            <div class="mb-4">
                                                <label class="my-1 me-2" for="country">Gambar</label>
                                                <div className="w-100 d-grid grip-1">
                                                    <button 
                                                        className="btn btn-gray-200" 
                                                        type="button"
                                                        onClick={()=>this.inputElement.click()}
                                                    >
                                                        <FiUpload/> Upload Gambar
                                                    </button>
                                                    <input
                                                        ref={input=>this.inputElement=input}
                                                        type="file"
                                                        style={{display:"none"}}
                                                        accept=".png, .jpg, .jpeg"
                                                        name="banner"
                                                        onChange={e=>this.uploadImage(e)}
                                                    />
                                                </div>
                                                <div className="mt-3">
                                                    {!isUndefined(banner.gambar.path)&&
                                                        <img src={`${BASE_URL}/${banner.gambar.path}`} alt="" className="img-fluid"/>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 mt-4">
                                                <div class="form-label d-flex justify-content-between">
                                                    <span className="fw-semibold">Shortcut/Link Icon</span>
                                                    {banner.shortcut.length<2&&
                                                        <button type="button" className="btn btn-info" onClick={this.toggleTambahShortcut}><AiOutlinePlus/> Baru</button>
                                                    }
                                                </div>
                                                <div className="input-icon col">
                                                    <div class="table-responsive">
                                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                                            <thead class="thead-light">
                                                                <tr>
                                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                                    <th class="border-0">Judul/Label</th>
                                                                    <th class="border-0">Icon</th>
                                                                    <th class="border-0 rounded-end p-0" width="90"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {banner.shortcut.map((list, idx)=>(
                                                                    <tr>
                                                                        <td className="align-middle">{(idx+1)}</td>
                                                                        <td>{list.judul}</td>
                                                                        <td><img src={`${BASE_URL}/${list.icon.path}`} className="img-fluid" style={{maxWidth:"80px"}}/></td>
                                                                        <td>
                                                                            <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditShortcut(list)}>
                                                                                Edit
                                                                            </button>
                                                                            <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusShortcut(list)}>
                                                                                Hapus
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="card-footer d-flex justify-content-center">
                                    <button type="submit" class="btn btn-info" disabled={isUndefined(banner.gambar.path)||banner.judul.trim()===""||banner.deskripsi.trim()===""}>Save Changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                {/* TAMBAH SHORTCUT */}
                <Modal show={tambah_shortcut.is_open} onHide={this.toggleTambahShortcut}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Link Icon</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addShortcut}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Icon</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    name="tambah_shortcut"
                                    onChange={e=>this.uploadImage(e)}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(tambah_shortcut.icon.path)&&
                                    <img src={`${BASE_URL}/${tambah_shortcut.icon.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeTambahShortcut}
                                value={tambah_shortcut.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={tambah_shortcut.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.toggleTambahShortcut}>Batal</button>
                        <button 
                            type="submit" 
                            class="btn btn-info" 
                            disabled={
                                tambah_shortcut.judul.trim()===""||
                                tambah_shortcut.link_to.trim()===""||
                                isUndefined(tambah_shortcut.icon.path)
                            }
                        >
                            Tambahkan
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* EDIT SHORTCUT */}
                <Modal show={edit_shortcut.is_open} onHide={this.hideEditShortcut}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Link Icon</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateShortcut}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Icon</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    name="edit_shortcut"
                                    onChange={e=>this.uploadImage(e)}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(edit_shortcut.icon.path)&&
                                    <img src={`${BASE_URL}/${edit_shortcut.icon.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeEditShortcut}
                                value={edit_shortcut.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={edit_shortcut.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditShortcut}>Batal</button>
                        <button 
                            type="submit" 
                            class="btn btn-info" 
                            disabled={
                                edit_shortcut.judul.trim()===""||
                                edit_shortcut.link_to.trim()===""||
                                isUndefined(edit_shortcut.icon.path)
                            }
                        >
                            Update
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <ConfirmDelete
                    show={hapus_shortcut.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusShortcut()}
                    deleteAction={()=>this.deleteShortcut()}
                />

                {/* LINK NAVIGASI */}
                <CustomLink
                    is_open={select_link.is_open}
                    setLink={data=>this.setLink(data)}
                    closeSelectLink={()=>this.hideSelectLink()}
                />
            </>
        )
    }
}

export default navAuth(Jumbotron, {
    title:"Banners Jumbotron"
})