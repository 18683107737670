import React, { useState } from "react"
import classNames from "classnames"
import { Accordion, Dropdown } from "react-bootstrap"
import { Link, Switch, Route } from "react-router-dom"
import Avatar from "../../component/ui/avatar"
import { akronim, login_data } from "../../config/config"
import {ImAidKit, ImCog, ImDisplay, ImHome, ImLab, ImLocation, ImMug, ImNewspaper, ImQuestion, ImStack, ImUsers} from "react-icons/im"
import {FaHospitalAlt} from "react-icons/fa"
import {BiHealth} from "react-icons/bi"

//pages
import DashboardMenu from "./dashboard"
import Users from "./users"
import Dokter from "./dokter"
import PetugasKesehatan from "./petugas"
import Puskesmas from "./puskesmas"
import Spesialis from "./spesialis"
import Lokasi from "./lokasi"
import Posts from "./posts"
import AddPost from "./posts/add"
import EditPost from "./posts/edit"
import Kategori from "./kategori"
import Banners from "./banners"
import Jumbotron from "./banners/jumbotron"
import Menu from "./menu"
import Layanan from "./layanan"
import AddLayanan from "./layanan/add"
import EditLayanan from "./layanan/edit"
import WidgetDashboard from "./widgets_dashboard"
import AddWidgetDashboard from "./widgets_dashboard/add"
import EditWidgetDashboard from "./widgets_dashboard/edit"
import FuzzyMetode from "./fuzzy_metode"
import AddFuzzyMetode from "./fuzzy_metode/add"
import EditFuzzyMetode from "./fuzzy_metode/edit"
import QNA from "./qna"
import AddQNA from "./qna/add"
import EditQNA from "./qna/edit"
import KategoriPenjual from "./merchant/kategori_penjual"
import Penjual from "./merchant/penjual"

class Dashboard extends React.Component{
    state={
        active_accordion:"",
        mobile_menu_show:false
    }

    //menu
    toggleAccordion=menu=>{
        let accordion
        if(menu===this.state.active_accordion) accordion=""
        else accordion=menu

        this.setState({
            active_accordion:accordion
        })
    }
    toggleMobileMenu=()=>{
        this.setState({
            mobile_menu_show:!this.state.mobile_menu_show
        })
    }

    //action
    logout=()=>{
        localStorage.removeItem("user_data")
        window.location.href="/?status=logout"
    }

    render(){
        const {active_accordion, mobile_menu_show}=this.state

        return (
            <>
                <nav class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
                    <a class="navbar-brand me-lg-5" href="">
                        <img class="navbar-brand-dark" src="/img/logo/light.svg" alt="Logo"/>
                        <img class="navbar-brand-light" src="/img/logo/dark.svg" alt="logo" />
                    </a>
                    <div class="d-flex align-items-center">
                        <button class="navbar-toggler d-lg-none collapsed" type="button" onClick={this.toggleMobileMenu}>
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </nav>
    
                <nav id="sidebarMenu" class={classNames("sidebar d-lg-block bg-gray-800 text-white collapse", {"show":mobile_menu_show})}>
                    <div class="sidebar-inner px-4 pt-3">
                        <div class="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div class="d-flex align-items-center">
                                <div class="avatar avatar-lg bg-white rounded-circle text-dark me-4">
                                    <Avatar
                                        data={login_data()}
                                        circle
                                    />
                                </div>
                                <div class="d-block">
                                    <h2 class="h5 mb-3 text-capitalize">Hi, {login_data().nama_lengkap}</h2>
                                    <button type="button" className="btn btn-secondary btn-sm d-inline-flex align-items-center" onClick={this.logout}>
                                        <svg class="icon icon-xxs me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>            
                                        Sign Out
                                    </button>
                                </div>
                            </div>
                            <div class="collapse-close d-md-none cursor-pointer">
                                <span onClick={this.toggleMobileMenu}>
                                    <svg class="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </span>
                            </div>
                        </div>
                        <Accordion as="ul" className="nav flex-column pt-3 pt-md-0" activeKey={active_accordion}>
                            <li>
                                <a href="#" className="nav-link d-flex align-items-center">
                                    <span class="sidebar-icon">
                                        <img src="/img/logo/light.svg" height="20" width="20" alt="Logo"/>
                                    </span>
                                    <span class="mt-1 ms-1 sidebar-text">TeleMedicine</span>
                                </a>
                            </li>
                            <li class="nav-item mt-3">
                                <Link to="/" className="nav-link">
                                    <span class="sidebar-icon">
                                        <ImHome className="icon icon-xs me-2"/>
                                    </span> 
                                    <span class="sidebar-text">Dashboard</span>
                                </Link>
                            </li>
                            <Accordion.Item as="li" eventKey="users" className={classNames("nav-item", {"active":active_accordion==="users"})}>
                                <span
                                    class="nav-link collapsed d-flex justify-content-between align-items-center"
                                    onClick={()=>this.toggleAccordion("users")}
                                >
                                    <span>
                                        <span class="sidebar-icon">
                                            <ImUsers className="icon icon-xs me-2"/>
                                        </span> 
                                        <span class="sidebar-text">Manajemen Users</span>
                                    </span>
                                    <span class={classNames("link-arrow", {"rotate-90":active_accordion==="users"})}>
                                        <svg class="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    </span>
                                </span>
                                <Accordion.Collapse
                                    as="div"
                                    eventKey="users"
                                    className={classNames("multi-level")}
                                >
                                    <ul class="flex-column nav">
                                        <li class="nav-item">
                                            <Link to="/users" className="nav-link">
                                                <span class="sidebar-text">User/Pasien</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/dokter" className="nav-link">
                                                <span class="sidebar-text">Dokter</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/petugas_kesehatan" className="nav-link">
                                                <span class="sidebar-text">Petugas Kesehatan</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/admin" className="nav-link">
                                                <span class="sidebar-text">Admin</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </Accordion.Collapse>
                            </Accordion.Item>
                            <Accordion.Item as="li" eventKey="posts" className={classNames("nav-item", {"active":active_accordion==="posts"})}>
                                <span
                                    class="nav-link collapsed d-flex justify-content-between align-items-center"
                                    onClick={()=>this.toggleAccordion("posts")}
                                >
                                    <span>
                                        <span class="sidebar-icon">
                                            <ImNewspaper className="icon icon-xs me-2"/>
                                        </span> 
                                        <span class="sidebar-text">Post/Artikel</span>
                                    </span>
                                    <span class={classNames("link-arrow", {"rotate-90":active_accordion==="posts"})}>
                                        <svg class="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    </span>
                                </span>
                                <Accordion.Collapse
                                    as="div"
                                    eventKey="posts"
                                    className={classNames("multi-level")}
                                >
                                    <ul class="flex-column nav">
                                        <li class="nav-item">
                                            <Link to="/posts" className="nav-link">
                                                <span class="sidebar-text">Posts</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/kategori" className="nav-link">
                                                <span class="sidebar-text">Kategori</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </Accordion.Collapse>
                            </Accordion.Item>
                            <li class="nav-item">
                                <Link to="/cek_kesehatan" className="nav-link">
                                    <span class="sidebar-icon">
                                        <ImLab className="icon icon-xs me-2"/>
                                    </span>
                                    <span class="sidebar-text">Cek Kesehatan</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/tanya_jawab" className="nav-link">
                                    <span class="sidebar-icon">
                                        <ImQuestion className="icon icon-xs me-2"/>
                                    </span>
                                    <span class="sidebar-text">Tanya Jawab</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/layanan" className="nav-link">
                                    <span class="sidebar-icon">
                                        <ImAidKit className="icon icon-xs me-2"/>
                                    </span>
                                    <span class="sidebar-text">Layanan</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/puskesmas" className="nav-link">
                                    <span class="sidebar-icon">
                                        <FaHospitalAlt className="icon icon-xs me-2"/>
                                    </span>
                                    <span class="sidebar-text">Puskesmas</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/spesialis" className="nav-link">
                                    <span class="sidebar-icon">
                                        <ImStack className="icon icon-xs me-2"/>
                                    </span>
                                    <span class="sidebar-text">Spesialis</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/lokasi" className="nav-link">
                                    <span class="sidebar-icon">
                                        <ImLocation className="icon icon-xs me-2"/>
                                    </span>
                                    <span class="sidebar-text">Lokasi/Region</span>
                                </Link>
                            </li>
                            <Accordion.Item as="li" eventKey="merchant" className={classNames("nav-item", {"active":active_accordion==="merchant"})}>
                                <span
                                    class="nav-link collapsed d-flex justify-content-between align-items-center"
                                    onClick={()=>this.toggleAccordion("merchant")}
                                >
                                    <span>
                                        <span class="sidebar-icon">
                                            <ImMug className="icon icon-xs me-2"/>
                                        </span> 
                                        <span class="sidebar-text">Penjual</span>
                                    </span>
                                    <span class={classNames("link-arrow", {"rotate-90":active_accordion==="merchant"})}>
                                        <svg class="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    </span>
                                </span>
                                <Accordion.Collapse
                                    as="div"
                                    eventKey="merchant"
                                    className={classNames("multi-level")}
                                >
                                    <ul class="flex-column nav">
                                        <li class="nav-item">
                                            <Link to="/penjual" className="nav-link">
                                                <span class="sidebar-text">Penjual/Merchant</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="kategori_penjual" className="nav-link">
                                                <span class="sidebar-text">Kategori Penjual</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </Accordion.Collapse>
                            </Accordion.Item>
                            <Accordion.Item as="li" eventKey="setting" className={classNames("nav-item", {"active":active_accordion==="setting"})}>
                                <span
                                    class="nav-link collapsed d-flex justify-content-between align-items-center"
                                    onClick={()=>this.toggleAccordion("setting")}
                                >
                                    <span>
                                        <span class="sidebar-icon">
                                            <ImCog className="icon icon-xs me-2"/>
                                        </span> 
                                        <span class="sidebar-text">Pengaturan</span>
                                    </span>
                                    <span class={classNames("link-arrow", {"rotate-90":active_accordion==="setting"})}>
                                        <svg class="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    </span>
                                </span>
                                <Accordion.Collapse
                                    as="div"
                                    eventKey="setting"
                                    className={classNames("multi-level")}
                                >
                                    <ul class="flex-column nav">
                                        <li class="nav-item">
                                            <Link to="/banner_jumbotron" className="nav-link">
                                                <span class="sidebar-text">Jumbotron</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/banner_grid" className="nav-link">
                                                <span class="sidebar-text">Banner Grid/Kolom</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/menu" className="nav-link">
                                                <span class="sidebar-text">Menu Navigasi</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/widget_dashboard" className="nav-link">
                                                <span class="sidebar-text">Dashboard Widget</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/menu" className="nav-link">
                                                <span class="sidebar-text">Lainnya</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </nav>
                
                <main class="content">
                    <Switch>
                        <Route exact path="/" component={DashboardMenu}/>
                        <Route exact path="/users" component={Users}/>
                        <Route exact path="/dokter" component={Dokter}/>
                        <Route exact path="/petugas_kesehatan" component={PetugasKesehatan}/>
                        <Route exact path="/puskesmas" component={Puskesmas}/>
                        <Route exact path="/spesialis" component={Spesialis}/>
                        <Route exact path="/lokasi" component={Lokasi}/>
                        <Route exact path="/posts" component={Posts}/>
                        <Route exact path="/posts/add" component={AddPost}/>
                        <Route exact path="/posts/edit/:id_post" component={EditPost}/>
                        <Route exact path="/kategori" component={Kategori}/>
                        <Route exact path="/banner_grid" component={Banners}/>
                        <Route exact path="/banner_jumbotron" component={Jumbotron}/>
                        <Route exact path="/menu" component={Menu}/>
                        <Route exact path="/layanan" component={Layanan}/>
                        <Route exact path="/layanan/add" component={AddLayanan}/>
                        <Route exact path="/layanan/edit/:id_layanan" component={EditLayanan}/>
                        <Route exact path="/widget_dashboard" component={WidgetDashboard}/>
                        <Route exact path="/widget_dashboard/add" component={AddWidgetDashboard}/>
                        <Route exact path="/widget_dashboard/edit/:id_widget" component={EditWidgetDashboard}/>
                        <Route exact path="/cek_kesehatan" component={FuzzyMetode}/>
                        <Route exact path="/cek_kesehatan/add" component={AddFuzzyMetode}/>
                        <Route exact path="/cek_kesehatan/edit/:id" component={EditFuzzyMetode}/>
                        <Route exact path="/tanya_jawab" component={QNA}/>
                        <Route exact path="/tanya_jawab/add" component={AddQNA}/>
                        <Route exact path="/tanya_jawab/edit/:id" component={EditQNA}/>
                        <Route exact path="/kategori_penjual" component={KategoriPenjual}/>
                        <Route exact path="/penjual" component={Penjual}/>
                    </Switch>

                    {/* FOOTER */}
                    <footer class="bg-white rounded shadow p-5 mb-4 mt-4">
                        <div class="row">
                            <div class="col-12 col-md-4 col-xl-6 mb-4 mb-md-0">
                                <p class="mb-0 text-center text-lg-start">Copyright © <span class="current-year">2021</span> <a class="text-primary fw-normal text-decoration-underline" href="" target="_blank">Telemedicine.com</a></p>
                            </div>
                        </div>
                    </footer>
                </main>
            </>
        )
    }
}

export default Dashboard