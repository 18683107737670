
import React from "react"
import qs from 'querystring'
import { BASE_URL, access_token, remove_login_data, isUndefined } from "../../../config/config"
import { API, API_FORM } from "../../../config/api"
import update from 'immutability-helper'
import uniqid from "uniqid"
import { toast } from "react-toastify"
import {EditorState, convertFromRaw, convertToRaw, RichUtils} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Link } from "react-router-dom"
import navAuth from "../nav_auth"
import { Modal, Spinner } from "react-bootstrap"
import { AiOutlinePlus, AiOutlineTable } from "react-icons/ai"
import { FiUpload } from "react-icons/fi"
import { ConfirmDelete } from "../../../component/ui/confirm"
import InputPertanyaan from "./excerpt/input_pertanyaan"
import CustomLink from "../../../component/custom_link"


class AddFuzzyMetode extends React.Component{
    state={
        judul:"",
        pertanyaan:[],
        rule:[],
        perhitungan_z:[],
        perhitungan_hasil:[],
        checklist:[],
        tambah_pertanyaan:{
            is_open:false,
            data:{},
            disabled:true
        },
        edit_pertanyaan:{
            is_open:false,
            pertanyaan:{},
            data:{},
            disabled:true
        },
        hapus_pertanyaan:{
            is_open:false,
            pertanyaan:{}
        },
        tambah_pz:{
            is_open:false,
            text:"",
            dari:0,
            sampai:0
        },
        edit_pz:{
            is_open:false,
            pz:{},
            text:"",
            dari:0,
            sampai:0
        },
        hapus_pz:{
            is_open:false,
            pz:{}
        },
        tambah_ph:{
            is_open:false,
            text:"",
            dari:0,
            sampai:0,
            deskripsi:"",
            link_to:"",
            btn_link:""
        },
        edit_ph:{
            is_open:false,
            ph:{},
            text:"",
            dari:0,
            sampai:0,
            deskripsi:"",
            link_to:"",
            btn_link:""
        },
        hapus_ph:{
            is_open:false,
            ph:{}
        },
        rule_aturan:{
            is_open:false,
            copy_rule:[]
        },
        tambah_checklist:{
            is_open:false,
            text:""
        },
        edit_checklist:{
            is_open:false,
            checklist:{},
            text:""
        },
        hapus_checklist:{
            is_open:false,
            checklist:{}
        },
        is_adding:false,
        select_link:{
            is_open:false
        }
    }

    componentDidMount=()=>{
    }

    //ACTION
    typeInput=e=>{
        const target=e.target

        this.setState({
            [target.name]:target.value
        })
    }
    renderInputPertanyaan=(type)=>{
        let data, tipe_widget, setData, action
        if(type==="tambah"){
            data=this.state.tambah_pertanyaan.data
            action="tambah"
            setData=(data, disabled)=>this.setDataTambahPertanyaan(data, disabled)
        }
        else if(type==="edit"){
            data=this.state.edit_pertanyaan.data
            action="edit"
            setData=(data, disabled)=>this.setDataEditPertanyaan(data, disabled)
        }
        
        return <InputPertanyaan data={data} setData={setData} action={action}/>
    }

    //TAMBAH PERTANYAAN
    showTambahPertanyaan=()=>{
        this.setState({
            tambah_pertanyaan:{
                is_open:true,
                data:{},
                disabled:true
            }
        })
    }
    hideTambahPertanyaan=()=>{
        this.setState({
            tambah_pertanyaan:{
                is_open:false,
                data:{},
                disabled:true
            }
        })
    }
    setDataTambahPertanyaan=(data, disabled)=>{
        this.setState({
            tambah_pertanyaan:update(this.state.tambah_pertanyaan, {
                data:{$set:data},
                disabled:{$set:disabled}
            })
        })
    }
    addPertanyaan=(e)=>{
        e.preventDefault()
        
        let data=Object.assign({}, this.state.tambah_pertanyaan.data, {
            id:uniqid()
        })
        this.setState({
            pertanyaan:this.state.pertanyaan.concat([data])
        }, ()=>{
            this.hideTambahPertanyaan()
        })
    }

    //EDIT PERTANYAAN
    showEditPertanyaan=data=>{
        this.setState({
            edit_pertanyaan:{
                is_open:true,
                pertanyaan:data,
                data:data,
                disabled:true
            }
        })
    }
    hideEditPertanyaan=()=>{
        this.setState({
            edit_pertanyaan:{
                is_open:false,
                pertanyaan:{},
                data:{},
                disabled:true
            }
        })
    }
    setDataEditPertanyaan=(data, disabled)=>{
        this.setState({
            edit_pertanyaan:update(this.state.edit_pertanyaan, {
                data:{$set:data},
                disabled:{$set:disabled}
            })
        })
    }
    updatePertanyaan=(e)=>{
        e.preventDefault()
        
        const index=this.state.pertanyaan.map(p=>JSON.stringify(p)).indexOf(JSON.stringify(this.state.edit_pertanyaan.pertanyaan))
        let data=Object.assign({}, this.state.edit_pertanyaan.data, {
            id:uniqid()
        })
        this.setState({
            pertanyaan:update(this.state.pertanyaan, {
                [index]:{$set:data}
            })
        }, ()=>{
            this.hideEditPertanyaan()
        })
    }

    //HAPUS PERTANYAAN
    showHapusPertanyaan=data=>{
        this.setState({
            hapus_pertanyaan:{
                is_open:true,
                pertanyaan:data
            }
        })
    }
    hideHapusPertanyaan=()=>{
        this.setState({
            hapus_pertanyaan:{
                is_open:false,
                pertanyaan:{}
            }
        })
    }
    delPertanyaan=()=>{
        const pertanyaan=this.state.pertanyaan.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_pertanyaan.pertanyaan))

        this.setState({
            pertanyaan:pertanyaan
        }, ()=>{
            this.hideHapusPertanyaan()
        })
    }

    //TAMBAH PERHITUNGAN Z
    showTambahPZ=()=>{
        this.setState({
            tambah_pz:{
                is_open:true,
                text:"",
                dari:0,
                sampai:0
            }
        })
    }
    hideTambahPZ=()=>{
        this.setState({
            tambah_pz:{
                is_open:false,
                text:"",
                dari:0,
                sampai:0
            }
        })
    }
    typeTambahPZ=e=>{
        const target=e.target
    
        this.setState({
            tambah_pz:update(this.state.tambah_pz, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addPZ=e=>{
        e.preventDefault()
        
        const {tambah_pz}=this.state
        let data={
            id:uniqid(),
            text:tambah_pz.text,
            dari:tambah_pz.dari,
            sampai:tambah_pz.sampai
        }
    
        this.setState({
            perhitungan_z:this.state.perhitungan_z.concat([data])
        }, ()=>{
            this.hideTambahPZ()
        })
    }
    
    //EDIT PERHITUNGAN Z
    showEditPZ=data=>{
        this.setState({
            edit_pz:{
                is_open:true,
                pz:data,
                text:data.text,
                dari:data.dari,
                sampai:data.sampai
            }
        })
    }
    hideEditPZ=()=>{
        this.setState({
            edit_pz:{
                is_open:false,
                pz:{},
                text:"",
                dari:0,
                sampai:0
            }
        })
    }
    typeEditPZ=e=>{
        const target=e.target
    
        this.setState({
            edit_pz:update(this.state.edit_pz, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updatePZ=e=>{
        e.preventDefault()
        
        const {edit_pz}=this.state
        const index=this.state.perhitungan_z.map(pz=>JSON.stringify(pz)).indexOf(JSON.stringify(this.state.edit_pz.pz))
        let data={
            id:uniqid(),
            text:edit_pz.text,
            dari:edit_pz.dari,
            sampai:edit_pz.sampai
        }
    
        this.setState({
            perhitungan_z:update(this.state.perhitungan_z, {
                [index]:{$set:data}
            })
        }, ()=>{
            this.hideEditPZ()
        })
    }
    
    //HAPUS PERHITUNGAN Z
    showHapusPZ=data=>{
        this.setState({
            hapus_pz:{
                is_open:true,
                pz:data
            }
        })
    }
    hideHapusPZ=()=>{
        this.setState({
            hapus_pz:{
                is_open:false,
                pz:{}
            }
        })
    }
    delPZ=()=>{
        const pz=this.state.perhitungan_z.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_pz.pz))
    
        this.setState({
            perhitungan_z:pz
        }, ()=>{
            this.hideHapusPZ()
        })
    }

    //TAMBAH PERHITUNGAN HASIL
    showTambahPH=()=>{
        this.setState({
            tambah_ph:{
                is_open:true,
                text:"",
                dari:0,
                sampai:0,
                deskripsi:"",
                link_to:"",
                btn_link:""
            }
        })
    }
    hideTambahPH=()=>{
        this.setState({
            tambah_ph:{
                is_open:false,
                text:"",
                dari:0,
                sampai:0,
                deskripsi:"",
                link_to:"",
                btn_link:""
            }
        })
    }
    typeTambahPH=e=>{
        const target=e.target

        this.setState({
            tambah_ph:update(this.state.tambah_ph, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addPH=e=>{
        e.preventDefault()
        
        const {tambah_ph}=this.state
        let data={
            id:uniqid(),
            text:tambah_ph.text,
            dari:tambah_ph.dari,
            sampai:tambah_ph.sampai,
            deskripsi:tambah_ph.deskripsi,
            link_to:tambah_ph.link_to,
            btn_link:tambah_ph.btn_link
        }

        this.setState({
            perhitungan_hasil:this.state.perhitungan_hasil.concat([data])
        }, ()=>{
            this.hideTambahPH()
        })
    }

    //EDIT PERHITUNGAN HASIL
    showEditPH=data=>{
        this.setState({
            edit_ph:{
                is_open:true,
                ph:data,
                text:data.text,
                dari:data.dari,
                sampai:data.sampai,
                deskripsi:data.deskripsi,
                link_to:data.link_to,
                btn_link:data.btn_link
            }
        })
    }
    hideEditPH=()=>{
        this.setState({
            edit_ph:{
                is_open:false,
                ph:{},
                text:"",
                dari:0,
                sampai:0,
                deskripsi:"",
                link_to:"",
                btn_link:""
            }
        })
    }
    typeEditPH=e=>{
        const target=e.target
    
        this.setState({
            edit_ph:update(this.state.edit_ph, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updatePH=e=>{
        e.preventDefault()
        
        const {edit_ph}=this.state
        const index=this.state.perhitungan_hasil.map(ph=>JSON.stringify(ph)).indexOf(JSON.stringify(this.state.edit_ph.ph))
        let data={
            id:uniqid(),
            text:edit_ph.text,
            dari:edit_ph.dari,
            sampai:edit_ph.sampai,
            deskripsi:edit_ph.deskripsi,
            link_to:edit_ph.link_to,
            btn_link:edit_ph.btn_link
        }
    
        this.setState({
            perhitungan_hasil:update(this.state.perhitungan_hasil, {
                [index]:{$set:data}
            })
        }, ()=>{
            this.hideEditPH()
        })
    }

    //HAPUS PERHITUNGAN HASIL
    showHapusPH=data=>{
        this.setState({
            hapus_ph:{
                is_open:true,
                ph:data
            }
        })
    }
    hideHapusPH=()=>{
        this.setState({
            hapus_ph:{
                is_open:false,
                ph:{}
            }
        })
    }
    delPH=()=>{
        const ph=this.state.perhitungan_hasil.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_ph.ph))

        this.setState({
            perhitungan_hasil:ph
        }, ()=>{
            this.hideHapusPH()
        })
    }

    //RULE/ATURAN
    toggleRule=(data=[])=>{
        this.setState({
            rule_aturan:{
                is_open:!this.state.rule_aturan.is_open,
                copy_rule:data
            }
        })
    }
    renderListAturan=()=>{
        const {rule}=this.state
        
        let keys=[];
        if(rule.length>0){
            Object.keys(rule[0]).map((k, idx)=>{
                keys=keys.concat([{key:idx, value:rule[0][k].konteks}])
            })
        }

        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <div>Total Data : <strong>{rule.length}</strong></div>
                    <div>
                        <button className="btn btn-info me-2" type="button" onClick={this.generateAturan}>Buat Baru dari Pertanyaan</button>
                        <button className="btn btn-primary" type="button" onClick={this.resetAturan}>Reset</button>
                    </div>
                </div>
                <div class="table-responsive mt-4">
                    <table class="table table-centered table-nowrap mb-0 rounded">
                        <thead class="thead-light">
                            <tr>
                                {keys.length>0?
                                    <>
                                        {keys.map(k=><th>{k.value}</th>)}
                                        <th width="80"></th>
                                    </>
                                :
                                    <th></th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {rule.length>0?
                                <>
                                    {rule.map(r=>(
                                        <tr>
                                            {keys.map(k=><td>{r[k.key].nama_kategori}</td>)}
                                            <td>
                                                <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.delAturan(r)}>
                                                    Hapus
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            :
                                <tr>
                                    <td className="text-center text-muted">Belum ada rule/aturan</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
    generateAturan=()=>{
        //array
        let data=this.state.pertanyaan.map(tanya=>{
            return {
                konteks:tanya.konteks,
                kategori:tanya.kategori
            }
        })
        data.push({
            kategori:this.state.perhitungan_z.map(ph=>{
                return Object.assign({}, ph, {
                    nama_kategori:ph.text
                })
            }),
            konteks:"Hasil"
        })
        let push_data=[]

        let recursive_permutation=(idx, data_old)=>{
            if(idx+1<=data.length){
                if(data[idx].kategori.length>0){
                    for(var i=0; i<data[idx].kategori.length; i++){
                        let data_old_new=Object.assign({}, data_old, {
                            [idx]:Object.assign({}, data[idx].kategori[i], {
                                konteks:data[idx].konteks
                            })
                        })
                        recursive_permutation(idx+1, data_old_new)
                    }
                }
            }
            else{
                if(idx!==0){
                    push_data.push(data_old)
                }
            }
        }

        recursive_permutation(0, {})

        //save to rule
        this.setState({
            rule:push_data
        })
    }
    delAturan=list=>{
        const rule=this.state.rule.filter(r=>JSON.stringify(r)!==JSON.stringify(list))
        this.setState({
            rule:rule
        })
    }
    resetAturan=()=>{
        this.setState({
            rule:this.state.rule_aturan.copy_rule
        })
    }

    //TAMBAH CHECKLIST
    showTambahCK=()=>{
        this.setState({
            tambah_checklist:{
                is_open:true,
                text:""
            }
        })
    }
    hideTambahCK=()=>{
        this.setState({
            tambah_checklist:{
                is_open:false,
                text:""
            }
        })
    }
    typeTambahCK=e=>{
        const target=e.target

        this.setState({
            tambah_checklist:update(this.state.tambah_checklist, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addCK=e=>{
        e.preventDefault()
        
        const {tambah_checklist}=this.state
        let data={
            id:uniqid(),
            text:tambah_checklist.text
        }

        this.setState({
            checklist:this.state.checklist.concat([data])
        }, ()=>{
            this.hideTambahCK()
        })
    }

    //EDIT CHECKLIST
    showEditCK=data=>{
        this.setState({
            edit_checklist:{
                is_open:true,
                checklist:data,
                text:data.text
            }
        })
    }
    hideEditCK=()=>{
        this.setState({
            edit_checklist:{
                is_open:false,
                checklist:{},
                text:""
            }
        })
    }
    typeEditCK=e=>{
        const target=e.target
    
        this.setState({
            edit_checklist:update(this.state.edit_checklist, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateCK=e=>{
        e.preventDefault()
        
        const {edit_checklist}=this.state
        const index=this.state.checklist.map(ph=>JSON.stringify(ph)).indexOf(JSON.stringify(this.state.edit_checklist.checklist))
        let data={
            id:uniqid(),
            text:edit_checklist.text
        }
    
        this.setState({
            checklist:update(this.state.checklist, {
                [index]:{$set:data}
            })
        }, ()=>{
            this.hideEditCK()
        })
    }

    //HAPUS CHECKLIST
    showHapusCK=data=>{
        this.setState({
            hapus_checklist:{
                is_open:true,
                checklist:data
            }
        })
    }
    hideHapusCK=()=>{
        this.setState({
            hapus_checklist:{
                is_open:false,
                checklist:{}
            }
        })
    }
    delCK=()=>{
        const ph=this.state.checklist.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_checklist.checklist))

        this.setState({
            checklist:ph
        }, ()=>{
            this.hideHapusCK()
        })
    }

    //SUBMIT
    addCekKesehatan=e=>{
        e.preventDefault()

        const data={
            judul:this.state.judul,
            pertanyaan:this.state.pertanyaan,
            rule:this.state.rule,
            perhitungan_hasil:this.state.perhitungan_hasil,
            perhitungan_z:this.state.perhitungan_z,
            checklist:this.state.checklist
        }

        this.setState({is_adding:true})
        API("/adminweb/fuzzy_metode/add_cek_kesehatan", {
            tokenID:access_token(),
            data:data
        })
        .then(res=>{
            this.setState({
                judul:"",
                pertanyaan:[],
                rule:[],
                perhitungan_hasil:[],
            })
            toast.success("Berhasil menambahkan Cek Kesehatan Mandiri")
            this.setState({is_adding:false})
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //SELECT LINK
    setLink=(data)=>{
        let target
        if(this.state.tambah_ph.is_open){
            target="tambah_ph"
        }
        else if(this.state.edit_ph.is_open){
            target="edit_ph"
        }

        this.setState(prevState=>{
            return {
                ...prevState,
                [target]:{
                    ...prevState[target],
                    link_to:data
                }
            }
        })
    }
    showSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:true
            }
        })
    }
    hideSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:false
            }
        })
    }
    

    render(){
        const {
            judul,
            pertanyaan,
            perhitungan_hasil,
            rule,
            is_adding,
            tambah_pertanyaan,
            edit_pertanyaan,
            hapus_pertanyaan,
            tambah_ph,
            edit_ph,
            hapus_ph,
            rule_aturan,
            perhitungan_z,
            tambah_pz,
            edit_pz,
            hapus_pz,
            select_link,
            checklist,
            tambah_checklist,
            edit_checklist,
            hapus_checklist

        }=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <form className="card" onSubmit={this.addCekKesehatan}>
                                <div className="card-body">
                                    <div className="form-group mb-3 mt-4">
                                        <label class="form-label">Judul</label>
                                        <div className="input-icon col">
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                value={judul}
                                                name="judul"
                                                onChange={this.typeInput}
                                            />
                                            <small className="form-hint"></small>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mt-4">
                                        <div class="form-label d-flex justify-content-between">
                                            <span className="fw-semibold">Perhitungan Z</span>
                                            <button type="button" className="btn btn-info" onClick={this.showTambahPZ}><AiOutlinePlus/> Baru</button>
                                        </div>
                                        <div className="input-icon col">
                                            <div class="table-responsive">
                                                <table class="table table-centered table-nowrap mb-0 rounded">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th class="border-0 rounded-start" width="50">#</th>
                                                            <th class="border-0">Text/Label</th>
                                                            <th class="border-0">Range</th>
                                                            <th class="border-0 rounded-end p-0" width="90"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {perhitungan_z.map((list, idx)=>(
                                                            <tr>
                                                                <td className="align-middle">{(idx+1)}</td>
                                                                <td>{list.text}</td>
                                                                <td>{list.dari}-{list.sampai}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditPZ(list)}>
                                                                        Edit
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusPZ(list)}>
                                                                        Hapus
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mt-4">
                                        <div class="form-label d-flex justify-content-between">
                                            <span className="fw-semibold">Perhitungan Hasil</span>
                                            <button type="button" className="btn btn-info" onClick={this.showTambahPH}><AiOutlinePlus/> Baru</button>
                                        </div>
                                        <div className="input-icon col">
                                            <div class="table-responsive">
                                                <table class="table table-centered table-nowrap mb-0 rounded">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th class="border-0 rounded-start" width="50">#</th>
                                                            <th class="border-0">Text/Label</th>
                                                            <th class="border-0">Range</th>
                                                            <th class="border-0 rounded-end p-0" width="90"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {perhitungan_hasil.map((list, idx)=>(
                                                            <tr>
                                                                <td className="align-middle">{(idx+1)}</td>
                                                                <td>{list.text}</td>
                                                                <td>{list.dari}-{list.sampai}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditPH(list)}>
                                                                        Edit
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusPH(list)}>
                                                                        Hapus
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mt-4">
                                        <div class="form-label d-flex justify-content-between">
                                            <span className="fw-semibold">List Pertanyaan</span>
                                            {/* <button type="button" className="btn btn-info" onClick={this.showTambahPertanyaan}><AiOutlinePlus/> Baru</button> */}
                                        </div>
                                        <div className="input-icon col">
                                            <div class="table-responsive">
                                                <table class="table table-centered table-nowrap mb-0 rounded">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th class="border-0 rounded-start" width="50">#</th>
                                                            <th class="border-0">Pertanyaan</th>
                                                            <th class="border-0 rounded-end p-0" width="90"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pertanyaan.map((list, idx)=>(
                                                            <tr>
                                                                <td className="align-middle">{(idx+1)}</td>
                                                                <td>{list.pertanyaan}</td>
                                                                <td>
                                                                {/* <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditPertanyaan(list)}>
                                                                    Edit
                                                                </button>
                                                                <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusPertanyaan(list)}>
                                                                    Hapus
                                                                </button> */}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mt-4">
                                        <div class="form-label d-flex justify-content-between">
                                            <span className="fw-semibold">Checklist Gejala</span>
                                            <button type="button" className="btn btn-info" onClick={this.showTambahCK}><AiOutlinePlus/> Baru</button>
                                        </div>
                                        <div className="input-icon col">
                                            <div class="table-responsive">
                                                <table class="table table-centered table-nowrap mb-0 rounded">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th class="border-0 rounded-start" width="50">#</th>
                                                            <th class="border-0">Text/Label</th>
                                                            <th class="border-0 rounded-end p-0" width="90"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {checklist.map((list, idx)=>(
                                                            <tr>
                                                                <td className="align-middle">{(idx+1)}</td>
                                                                <td>{list.text}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditCK(list)}>
                                                                        Edit
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusCK(list)}>
                                                                        Hapus
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group d-flex flex-column align-items-start mb-3 mt-4">
                                        <label class="form-label">Data Aturan</label>
                                        <span className="text-muted"><span className="text-danger">*</span>Saat mengubah/menambahkan pertanyaan atau perhitungan Z, data aturan harus diubah</span>
                                        {/* <button className="btn btn-primary mt-2" type="button" onClick={e=>this.toggleRule(this.state.rule)}><AiOutlineTable/> Lihat Data Aturan</button> */}
                                    </div>
                                </div>
                                <div className="card-footer">
                                   <div className="d-flex justify-content-center">
                                        <button 
                                            type="submit" 
                                            class="btn btn-primary btn-width-min" 
                                            disabled={
                                                judul.trim()===""||
                                                rule.length===0||
                                                perhitungan_hasil.length===0||
                                                pertanyaan.length===0||
                                                is_adding
                                            }
                                        >
                                            {is_adding?
                                                <Spinner size="sm" variant="light" animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            :
                                                <>Save Changes</>
                                            }
                                            
                                        </button>
                                   </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                {/* TAMBAH PERTANYAAN */}
                <Modal show={tambah_pertanyaan.is_open} size="lg" onHide={this.hideTambahPertanyaan}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Pertanyaan</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addPertanyaan}>
                    <Modal.Body>
                        {this.renderInputPertanyaan("tambah")}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahPertanyaan}>Batal</button>
                        <button 
                            type="submit" 
                            class="btn btn-info" 
                            disabled={this.state.tambah_pertanyaan.disabled}
                        >
                            Tambahkan
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>
                {/* EDIT PERTANYAAN */}
                <Modal show={edit_pertanyaan.is_open} size="lg" onHide={this.hideEditPertanyaan}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Pertanyaan</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updatePertanyaan}>
                    <Modal.Body>
                        {this.renderInputPertanyaan("edit")}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditPertanyaan}>Batal</button>
                        <button 
                            type="submit" 
                            class="btn btn-info" 
                            disabled={this.state.edit_pertanyaan.disabled}
                        >
                            Update
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>
                {/* HAPUS PERTANYAAN */}
                <ConfirmDelete
                    show={hapus_pertanyaan.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusPertanyaan()}
                    deleteAction={()=>this.delPertanyaan()}
                    nested
                />

                {/* TAMBAH HASIL */}
                <Modal show={tambah_ph.is_open} onHide={this.hideTambahPH}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Perhitungan Hasil</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addPH}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Text/Label</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="text"
                                onChange={this.typeTambahPH}
                                value={tambah_ph.text}
                            />
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">Dari</span>
                                    <input 
                                        type="text" 
                                        class="form-control"
                                        name="dari"
                                        value={tambah_ph.dari}
                                        onChange={this.typeTambahPH}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">Sampai</span>
                                    <input 
                                        type="text" 
                                        class="form-control"
                                        name="sampai"
                                        value={tambah_ph.sampai}
                                        onChange={this.typeTambahPH}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <textarea
                                rows="2"
                                className="form-control"
                                name="deskripsi"
                                onChange={this.typeTambahPH}
                                value={tambah_ph.deskripsi}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-1">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={tambah_ph.link_to}
                                    disabled
                                />
                            </div>
                            <span className="text-muted">Kosongkan apabila tidak ada link!</span>
                        </div>
                        {tambah_ph.link_to!==""&&
                            <div class="mb-4">
                                <label class="my-1 me-2" for="country">Button Link</label>
                                <input 
                                    type="text" 
                                    className="form-control"
                                    name="btn_link"
                                    onChange={this.typeTambahPH}
                                    value={tambah_ph.btn_link}
                                />
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahPH}>Batal</button>
                        <button 
                            type="submit" 
                            class="btn btn-info" 
                            disabled={
                                tambah_ph.text===""
                            }
                        >
                            Tambahkan
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <Modal show={edit_ph.is_open} onHide={this.hideEditPH}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Perhitungan Hasil</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updatePH}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Text/Label</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="text"
                                onChange={this.typeEditPH}
                                value={edit_ph.text}
                            />
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">Dari</span>
                                    <input 
                                        type="text" 
                                        class="form-control"
                                        name="dari"
                                        value={edit_ph.dari}
                                        onChange={this.typeEditPH}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div class="input-group mb-3">
                                    <span class="input-group-text">Sampai</span>
                                    <input 
                                        type="text" 
                                        class="form-control"
                                        name="sampai"
                                        value={edit_ph.sampai}
                                        onChange={this.typeEditPH}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <textarea
                                rows="2"
                                className="form-control"
                                name="deskripsi"
                                onChange={this.typeEditPH}
                                value={edit_ph.deskripsi}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-1">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={edit_ph.link_to}
                                    disabled
                                />
                            </div>
                            <span className="text-muted">Kosongkan apabila tidak ada link!</span>
                        </div>
                        {edit_ph.link_to!==""&&
                            <div class="mb-4">
                                <label class="my-1 me-2" for="country">Button Link</label>
                                <input 
                                    type="text" 
                                    className="form-control"
                                    name="btn_link"
                                    onChange={this.typeEditPH}
                                    value={edit_ph.btn_link}
                                />
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditPH}>Batal</button>
                        <button 
                            type="submit" 
                            class="btn btn-info" 
                            disabled={
                                edit_ph.text===""
                            }
                        >
                            Update
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>
                {/* HAPUS PERHITUNGAN HASIL */}
                <ConfirmDelete
                    show={hapus_ph.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusPH()}
                    deleteAction={()=>this.delPH()}
                    nested
                />

                {/* TAMBAH Z */}
                <Modal show={tambah_pz.is_open} onHide={this.hideTambahPZ}>
                <Modal.Header closeButton>
                    <Modal.Title>Tambah Perhitungan Z</Modal.Title>
                </Modal.Header>
                <form onSubmit={this.addPZ}>
                <Modal.Body>
                    <div class="mb-4">
                        <label class="my-1 me-2" for="country">Text/Label</label>
                        <input 
                            type="text" 
                            className="form-control"
                            name="text"
                            onChange={this.typeTambahPZ}
                            value={tambah_pz.text}
                        />
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div class="input-group mb-3">
                                <span class="input-group-text">Dari</span>
                                <input 
                                    type="text" 
                                    class="form-control"
                                    name="dari"
                                    value={tambah_pz.dari}
                                    onChange={this.typeTambahPZ}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div class="input-group mb-3">
                                <span class="input-group-text">Sampai</span>
                                <input 
                                    type="text" 
                                    class="form-control"
                                    name="sampai"
                                    value={tambah_pz.sampai}
                                    onChange={this.typeTambahPZ}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahPZ}>Batal</button>
                    <button 
                        type="submit" 
                        class="btn btn-info" 
                        disabled={
                            tambah_pz.text===""
                        }
                    >
                        Tambahkan
                    </button>
                </Modal.Footer>
                </form>
                </Modal>
                <Modal show={edit_pz.is_open} onHide={this.hideEditPZ}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Perhitungan Z</Modal.Title>
                </Modal.Header>
                <form onSubmit={this.updatePZ}>
                <Modal.Body>
                    <div class="mb-4">
                        <label class="my-1 me-2" for="country">Text/Label</label>
                        <input 
                            type="text" 
                            className="form-control"
                            name="text"
                            onChange={this.typeEditPZ}
                            value={edit_pz.text}
                        />
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div class="input-group mb-3">
                                <span class="input-group-text">Dari</span>
                                <input 
                                    type="text" 
                                    class="form-control"
                                    name="dari"
                                    value={edit_pz.dari}
                                    onChange={this.typeEditPZ}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div class="input-group mb-3">
                                <span class="input-group-text">Sampai</span>
                                <input 
                                    type="text" 
                                    class="form-control"
                                    name="sampai"
                                    value={edit_pz.sampai}
                                    onChange={this.typeEditPZ}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditPZ}>Batal</button>
                    <button 
                        type="submit" 
                        class="btn btn-info" 
                        disabled={
                            edit_pz.text===""
                        }
                    >
                        Update
                    </button>
                </Modal.Footer>
                </form>
                </Modal>
                {/* HAPUS PERHITUNGAN Z */}
                <ConfirmDelete
                    show={hapus_pz.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusPZ()}
                    deleteAction={()=>this.delPZ()}
                    nested
                />

                {/* TAMBAH CHECKLIST */}
                <Modal show={tambah_checklist.is_open} onHide={this.hideTambahCK}>
                <Modal.Header closeButton>
                    <Modal.Title>Tambah Checklist Gejala</Modal.Title>
                </Modal.Header>
                <form onSubmit={this.addCK}>
                <Modal.Body>
                    <div class="mb-4">
                        <label class="my-1 me-2" for="country">Text/Label</label>
                        <input 
                            type="text" 
                            className="form-control"
                            name="text"
                            onChange={this.typeTambahCK}
                            value={tambah_checklist.text}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahCK}>Batal</button>
                    <button 
                        type="submit" 
                        class="btn btn-info" 
                        disabled={
                            tambah_checklist.text===""
                        }
                    >
                        Tambahkan
                    </button>
                </Modal.Footer>
                </form>
                </Modal>
                <Modal show={edit_checklist.is_open} onHide={this.hideEditCK}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Checklist Gejala</Modal.Title>
                </Modal.Header>
                <form onSubmit={this.updateCK}>
                <Modal.Body>
                    <div class="mb-4">
                        <label class="my-1 me-2" for="country">Text/Label</label>
                        <input 
                            type="text" 
                            className="form-control"
                            name="text"
                            onChange={this.typeEditCK}
                            value={edit_checklist.text}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditCK}>Batal</button>
                    <button 
                        type="submit" 
                        class="btn btn-info" 
                        disabled={
                            edit_checklist.text===""
                        }
                    >
                        Update
                    </button>
                </Modal.Footer>
                </form>
                </Modal>
                {/* HAPUS CHECKLIST */}
                <ConfirmDelete
                    show={hapus_checklist.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusCK()}
                    deleteAction={()=>this.delCK()}
                    nested
                />

                {/* RULE/ATURAN */}
                <Modal show={rule_aturan.is_open} size="xl" onHide={this.toggleRule} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Manajemen Rule/Aturan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderListAturan()}
                    </Modal.Body>
                </Modal>
                
                {/* LINK NAVIGASI */}
                <CustomLink
                    is_open={select_link.is_open}
                    setLink={data=>this.setLink(data)}
                    closeSelectLink={()=>this.hideSelectLink()}
                    allowEmpty
                />
            </>
        )
    }
}


export default navAuth(AddFuzzyMetode, {
    title:"Tambah Cek Kesehatan Mandiri"
})