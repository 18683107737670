import React from 'react'
import update from "immutability-helper"
import { API, API_FORM } from '../../../../config/api'
import { access_token, remove_login_data, BASE_URL, isUndefined } from "../../../../config/config"
import uniqid from "uniqid"
import { AiOutlinePlus } from 'react-icons/ai'
import { Modal } from 'react-bootstrap'
import { ConfirmDelete } from '../../../../component/ui/confirm'
import { FiUpload } from 'react-icons/fi'
import CustomLink from '../../../../component/custom_link'

class LinkIcon extends React.Component{
    state={
        data:{
            judul:"",
            show_title:true,
            link_icon:[]
        },
        tambah_link:{
            is_open:false,
            judul:"",
            icon:{},
            link_to:""
        },
        edit_link:{
            is_open:false,
            data:{},
            id_link_icon:"",
            judul:"",
            icon:{},
            link_to:""
        },
        hapus_link:{
            is_open:false,
            data:{}
        },
        select_link:{
            is_open:false
        }
    }

    componentDidMount=()=>{
        if(this.props.action==="tambah_widget"){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
        else{
            const {data}=this.props
            this.setState({
                data:{
                    judul:data.judul,
                    show_title:data.show_title,
                    link_icon:data.link_icon
                }
            })
        }
    }
    componentDidUpdate=(prevProps, prevState)=>{
        if(JSON.stringify(prevState.data)!==JSON.stringify(this.state.data)){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
    }
    disabledSubmit=()=>{
        const {data}=this.state
        if(data.judul.trim()===""||data.link_icon.length<1){
            return true
        }
        return false
    }

    //TAMBAH
    typeTambah=e=>{
        const target=e.target

        let value
        if(target.type==="checkbox"){
            value=target.checked
        }
        else{
            value=target.value
        }

        this.setState({
            data:update(this.state.data, {
                [target.name]:{$set:value}
            })
        })
    }

    //LINK ICON
    //tambah
    showTambahLink=()=>{
        this.setState({
            tambah_link:{
                is_open:true,
                judul:"",
                icon:{},
                link_to:""
            }
        })
    }
    hideTambahLink=()=>{
        this.setState({
            tambah_link:{
                is_open:false,
                judul:"",
                icon:{},
                link_to:""
            }
        })
    }
    typeTambahLink=e=>{
        const target=e.target

        this.setState({
            tambah_link:update(this.state.tambah_link, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addLink=e=>{
        e.preventDefault()

        const {tambah_link}=this.state
        const link_icon={
            id_link_icon:uniqid(),
            judul:tambah_link.judul,
            icon:tambah_link.icon,
            link_to:tambah_link.link_to
        }

        this.setState({
            data:update(this.state.data, {
                link_icon:{$set:[link_icon].concat(this.state.data.link_icon)}
            })
        }, ()=>{
            this.hideTambahLink()
        })
    }
    //edit
    showEditLink=data=>{
        this.setState({
            edit_link:{
                is_open:true,
                data:data,
                id_link_icon:data.id_link_icon,
                judul:data.judul,
                icon:data.icon,
                link_to:data.link_to
            }
        })
    }
    hideEditLink=()=>{
        this.setState({
            edit_link:{
                is_open:false,
                data:{},
                id_link_icon:"",
                judul:"",
                icon:{},
                link_to:""
            }
        })
    }
    typeEditLink=e=>{
        const target=e.target

        this.setState({
            edit_link:update(this.state.edit_link, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateLink=e=>{
        e.preventDefault()

        const {edit_link}=this.state
        const index=this.state.data.link_icon.map(bs=>JSON.stringify(bs)).indexOf(JSON.stringify(edit_link.data))
        const link_icon={
            id_link_icon:edit_link.id_link_icon,
            judul:edit_link.judul,
            icon:edit_link.icon,
            link_to:edit_link.link_to
        }

        this.setState({
            data:update(this.state.data, {
                link_icon:{
                    [index]:{$set:link_icon}
                }
            })
        }, ()=>{
            this.hideEditLink()
        })
    }
    //hapus
    showHapusLink=data=>{
        this.setState({
            hapus_link:{
                is_open:true,
                data:data
            }
        })
    }
    hideHapusLink=()=>{
        this.setState({
            hapus_link:{
                is_open:false,
                data:{}
            }
        })
    }
    deleteLink=()=>{
        const link_icon=this.state.data.link_icon.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_link.data))

        this.setState({
            data:update(this.state.data, {
                link_icon:{$set:link_icon}
            })
        }, ()=>{
            this.hideHapusLink()
        })
    }

    //UPLOAD IMAGE
    uploadImage=(e, target_state="tambah_link")=>{
        const files=e.target.files

        //initialize form data
        const form_data=new FormData()
        form_data.append("image", files[0])
        form_data.append("path", "/banners")

        //upload to server
        API_FORM("/adminweb/file_manager/upload_image", {
            tokenID:access_token(),
            data:form_data
        })
        .then(res=>{
            if(target_state==="tambah_link"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        tambah_link:{
                            ...prevState.tambah_link,
                            icon:res.data.data
                        }
                    }
                })
            }
            else if(target_state==="edit_link"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        edit_link:{
                            ...prevState.edit_link,
                            icon:res.data.data
                        }
                    }
                })
            }
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //SELECT LINK
    setLink=(data)=>{
        let target
        if(this.state.tambah_link.is_open){
            target="tambah_link"
        }
        else if(this.state.edit_link.is_open){
            target="edit_link"
        }

        this.setState(prevState=>{
            return {
                ...prevState,
                [target]:{
                    ...prevState[target],
                    link_to:data
                }
            }
        })
    }
    showSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:true
            }
        })
    }
    hideSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:false
            }
        })
    }

    render(){
        const {data, tambah_link, edit_link, hapus_link, select_link}=this.state

        return (
            <>
                <div className='row'>
                    <div className='col-12'>
                        <div className="form-group mb-3 mt-4">
                            <label class="form-label">Judul</label>
                            <div className="input-icon col">
                                <input 
                                    type="text" 
                                    className="form-control"
                                    value={data.judul}
                                    name="judul"
                                    onChange={this.typeTambah}
                                />
                                <small className="form-hint"></small>
                            </div>
                        </div>
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                name="show_title"
                                checked={data.show_title} 
                                id="cb__tampilkan_judul"
                                onChange={this.typeTambah}
                            />
                            <label class="form-check-label" for="cb__tampilkan_judul">
                                Tampilkan Judul
                            </label>
                        </div>
                        <div className="form-group mb-3 mt-4">
                            <div class="form-label d-flex justify-content-between">
                                <span className="fw-semibold">Link Icon</span>
                                <button type="button" className="btn btn-info" onClick={this.showTambahLink}><AiOutlinePlus/> Baru</button>
                            </div>
                            <div className="input-icon col">
                                <div class="table-responsive">
                                    <table class="table table-centered table-nowrap mb-0 rounded">
                                        <thead class="thead-light">
                                            <tr>
                                                <th class="border-0 rounded-start" width="50">#</th>
                                                <th class="border-0">Icon</th>
                                                <th class="border-0">Link To</th>
                                                <th class="border-0 rounded-end p-0" width="90"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.link_icon.map((list, idx)=>(
                                                <tr>
                                                    <td className="align-middle">{(idx+1)}</td>
                                                    <td><img src={BASE_URL+"/"+list.icon.path} width="200"/></td>
                                                    <td>{list.link_to}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditLink(list)}>
                                                            Edit
                                                        </button>
                                                        <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusLink(list)}>
                                                            Hapus
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* LINK ICON */}
                <Modal show={tambah_link.is_open} onHide={this.hideTambahLink} backdrop="static" backdropClassName='backdrop-nested'>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Link Icon</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeTambahLink}
                                value={tambah_link.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Icon</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e=>this.uploadImage(e, "tambah_link")}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(tambah_link.icon.path)&&
                                    <img src={`${BASE_URL}/${tambah_link.icon.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={tambah_link.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahLink}>Batal</button>
                        <button 
                            type="button" 
                            class="btn btn-info" 
                            disabled={isUndefined(tambah_link.icon.path)||tambah_link.link_to.trim()===""||tambah_link.judul.trim()===""}
                            onClick={this.addLink}
                        >
                            Save Changes
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={edit_link.is_open} onHide={this.hideEditLink} backdrop="static" backdropClassName='backdrop-nested'>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Link Icon</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeEditLink}
                                value={edit_link.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Icon</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e=>this.uploadImage(e, "edit_link")}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(edit_link.icon.path)&&
                                    <img src={`${BASE_URL}/${edit_link.icon.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={edit_link.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditLink}>Batal</button>
                        <button type="button" class="btn btn-info" onClick={this.updateLink}>Save Changes</button>
                    </Modal.Footer>
                </Modal>
                <ConfirmDelete
                    show={hapus_link.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusLink()}
                    deleteAction={()=>this.deleteLink()}
                    nested
                />

                {/* LINK NAVIGASI */}
                <CustomLink
                    is_open={select_link.is_open}
                    setLink={data=>this.setLink(data)}
                    closeSelectLink={()=>this.hideSelectLink()}
                />
            </>
        )
    }
}

export default LinkIcon