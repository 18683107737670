
import React from "react"
import qs from 'querystring'
import { BASE_URL, access_token, remove_login_data, isUndefined } from "../../../config/config"
import { API, API_FORM } from "../../../config/api"
import update from 'immutability-helper'
import { toast } from "react-toastify"
import {EditorState, convertFromRaw, convertToRaw, RichUtils} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Link } from "react-router-dom"
import navAuth from "../nav_auth"
import { Modal, Spinner } from "react-bootstrap"
import { FiCheck, FiUpload } from "react-icons/fi"
import embed from "embed-video"

class AddPost extends React.Component{
    state={
        kategori:[],
        tambah_kategori:{
            is_open:false,
            nama_kategori:""
        },
        tambah_post:{
            kategori:[],
            judul:"",
            featured_image:{},
            content:EditorState.createEmpty(),
            tags:""
        },
        is_adding:false
    }

    componentDidMount=()=>{
        this.getsKategori()
    }
    getsKategori=()=>{
        API("/adminweb/kategori/gets_all_kategori", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    kategori:res.data.data
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //tambah kategori
    showTambahKategori=()=>{
        this.setState({
            tambah_kategori:{
                is_open:true,
                nama_kategori:""
            }
        })
    }
    hideTambahKategori=()=>{
        this.setState({
            tambah_kategori:{
                is_open:false,
                nama_kategori:""
            }
        })
    }
    typeTambahKategori=(e)=>{
        const target=e.target

        this.setState(prevState=>{
            return {
                ...prevState,
                tambah_kategori:{
                    ...prevState.tambah_kategori,
                    [target.name]:target.value
                }
            }
        })
    }
    addKategori=(e)=>{
        e.preventDefault()

        API("/adminweb/kategori/add_kategori", {
            tokenID:access_token(),
            data:this.state.tambah_kategori
        })
        .then(res=>{
            this.hideTambahKategori()
            this.getsKategori()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //tambah post
    typeTambah=(e)=>{
        const target=e.target

        const value=target.type==="checkbox"?target.checked:target.value
        this.setState({
            tambah_post:update(this.state.tambah_post, {
                [target.name]:{$set:value}
            })
        })
    }
    typeTambahCheckedKategori=(e, id_kategori)=>{
        let kategori
        if(this.state.tambah_post.kategori.includes(id_kategori)){
            kategori=this.state.tambah_post.kategori.filter(cat=>cat!==id_kategori)
        }
        else{
            kategori=this.state.tambah_post.kategori.concat([id_kategori])
        }

        this.setState({
            tambah_post:update(this.state.tambah_post, {
                kategori:{$set:kategori}
            })
        })
    }
    typeTambahDraft=(value, name)=>{
        this.setState({
            tambah_post:update(this.state.tambah_post, {
                [name]:{$set:value}
            })
        })
    }
    checkedKategori=(id_kategori)=>{
        if(this.state.tambah_post.kategori.includes(id_kategori)){
            return true
        }
        else{
            return false
        }
    }
    uploadImage=(e)=>{
        const files=e.target.files

        //initialize form data
        const form_data=new FormData()
        form_data.append("image", files[0])
        form_data.append("path", "/posts")

        //upload to server
        API_FORM("/adminweb/file_manager/upload_image", {
            tokenID:access_token(),
            data:form_data
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    tambah_post:{
                        ...prevState.tambah_post,
                        featured_image:res.data.data
                    }
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    resetTambah=()=>{
        this.setState({
            tambah_post:{
                kategori:[],
                judul:"",
                featured_image:{},
                content:EditorState.createEmpty(),
                tags:""
            }
        })
    }
    addPost=(e)=>{
        e.preventDefault()

        // console.log(draftToHtml(convertToRaw(this.state.tambah_post.konten.getCurrentContent())))
        const {tambah_post}=this.state
        const content=convertToRaw(tambah_post.content.getCurrentContent())

        this.setState({
            is_adding:true
        })
        API("/adminweb/posts/add_post", {
            tokenID:access_token(),
            data:Object.assign({}, tambah_post, {
                content:content
            })
        })
        .then(res=>{
            this.resetTambah()
            toast.success("Post berhasil ditambahkan!")
            this.setState({
                is_adding:false
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    render(){
        const {
            kategori,
            tambah_kategori,
            tambah_post,
            is_adding
        }=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <form className="card" onSubmit={this.addPost}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="form-group mb-3 mt-4">
                                                <label class="form-label">Judul</label>
                                                <div className="input-icon col">
                                                    <input 
                                                        type="text" 
                                                        className="form-control"
                                                        placeholder="Judul Postingan/Artikel ..."
                                                        value={tambah_post.judul}
                                                        name="judul"
                                                        onChange={this.typeTambah}
                                                    />
                                                    <small className="form-hint"></small>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 mt-4">
                                                <div className="input-icon col">
                                                    <Editor
                                                        placeholder="Ketik Konten Disini..."
                                                        wrapperClassName="demo-wrapper"
                                                        editorClassName="demo-editor"
                                                        editorState={tambah_post.content}
                                                        onEditorStateChange={value=>this.typeTambahDraft(value, "content")}
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'textAlign', 'link', 'embedded', 'emoji', 'image'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace']
                                                            },
                                                            blockType:{
                                                                className:"bordered-option-classname"
                                                            },
                                                            textAlign:{
                                                                inDropdown:true
                                                            },
                                                            embedded: {
                                                                embedCallback: link => {
                                                                    const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                                                    return (detectedSrc && detectedSrc[1]) || link;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3 mt-4">
                                                <label class="form-label">kategori</label>
                                                <div className="input-icon input-kategori border p-3">
                                                    <div className="d-flex flex-wrap">
                                                        {kategori.map(cat=>(
                                                            <label class="form-check me-3">
                                                                <input 
                                                                    class="form-check-input" 
                                                                    type="checkbox"
                                                                    name="kategori"
                                                                    checked={this.checkedKategori(cat.id_kategori)}
                                                                    onChange={e=>this.typeTambahCheckedKategori(e, cat.id_kategori)}
                                                                />
                                                                <span class="form-check-label">{cat.nama_kategori}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                    <button type="button" className="btn btn-link link-info text-decoration-underline p-0" onClick={this.showTambahKategori}>+Tambah Kategori</button>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 mt-4">
                                                <label class="form-label">Tags</label>
                                                <div className="input-icon col">
                                                    <input 
                                                        type="text" 
                                                        className="form-control"
                                                        placeholder="Tags"
                                                        value={tambah_post.tags}
                                                        name="tags"
                                                        onChange={this.typeTambah}
                                                    />
                                                    <small className="text-muted">pisahkan tag dengan koma(,)</small>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 mt-4">
                                                <label class="form-label">Featured Image</label>
                                                <div className="input-icon col d-flex flex-column">
                                                    <div className="w-100 d-grid grip-1">
                                                        <button 
                                                            className="btn btn-gray-200" 
                                                            type="button"
                                                            onClick={()=>this.inputElement.click()}
                                                        >
                                                            <FiUpload/> Upload Gambar
                                                        </button>
                                                        <input
                                                            ref={input=>this.inputElement=input}
                                                            type="file"
                                                            style={{display:"none"}}
                                                            accept=".png, .jpg, .jpeg"
                                                            onChange={this.uploadImage}
                                                        />
                                                    </div>
                                                    <span className="text-muted mt-2">
                                                        Rekomendasi foto rasio 2:1, resolusi 1080p
                                                    </span>
                                                    <div className="mt-3">
                                                        {!isUndefined(tambah_post.featured_image.path)&&
                                                            <img src={`${BASE_URL}/${tambah_post.featured_image.path}`} alt="" className="img-fluid"/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                   <div className="d-flex justify-content-center">
                                        <button 
                                            type="submit" 
                                            class="btn btn-primary btn-width-min" 
                                            disabled={
                                                tambah_post.judul.trim()===""||
                                                isUndefined(tambah_post.featured_image.path)
                                            }
                                        >
                                            {is_adding?
                                                <Spinner size="sm" variant="light" animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            :
                                                <>Save Changes</>
                                            }
                                            
                                        </button>
                                   </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH KATEGORI */}
                <Modal show={tambah_kategori.is_open} onHide={this.hideTambahKategori}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Kategori</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addKategori}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Nama Kategori</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                value={tambah_kategori.nama_kategori}
                                name="nama_kategori"
                                onChange={this.typeTambahKategori}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahKategori}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={tambah_kategori.nama_kategori.trim()===""}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }
}


export default navAuth(AddPost, {
    title:"Tambah Post/Artikel"
})