import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal, Nav, Tab } from "react-bootstrap"
import {FiEdit} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import Provinsi from "./excerpt/provinsi"
import Kabupaten from "./excerpt/kabupaten"
import Kecamatan from "./excerpt/kecamatan"

class Lokasi extends React.Component{
    state={
        active_tab:1
    }

    //tabs
    setTabActive=(tab)=>{
        this.setState({
            active_tab:tab
        })
    }

    render(){
        const {active_tab}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <Tab.Container activeKey={active_tab}>
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link
                                                className={classNames({active:active_tab===1})}
                                                onClick={()=>this.setTabActive(1)}
                                            >
                                                Provinsi
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                className={classNames({active:active_tab===2})}
                                                onClick={()=>this.setTabActive(2)}
                                            >
                                                Kabupaten Kota
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link
                                                className={classNames({active:active_tab===3})}
                                                onClick={()=>this.setTabActive(3)}
                                            >
                                                Kecamatan
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <div class="card-body">
                                        <Tab.Content>
                                            <Tab.Pane active={active_tab===1} mountOnEnter unmountOnExit>
                                                <Provinsi {...this.props}/>
                                            </Tab.Pane>
                                            <Tab.Pane active={active_tab===2} mountOnEnter unmountOnExit>
                                                <Kabupaten {...this.props}/>
                                            </Tab.Pane>
                                            <Tab.Pane active={active_tab===3} mountOnEnter unmountOnExit>
                                                <Kecamatan {...this.props}/>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default navAuth(Lokasi, {
    title:"Manajemen Lokasi"
})