import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import { AiOutlinePlus } from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"

class Puskesmas extends React.Component{
    state={
        province:[],
        regency:[],
        district:[],
        puskesmas:[],
        filter:{
            province_id:"",
            regency_id:""
        },
        edit_puskesmas:{
            is_open:false,
            id_puskesmas:"",
            nama_puskesmas:""
        },
        tambah_puskesmas:{
            is_open:false,
            district_id:"",
            nama_puskesmas:""
        },
        hapus_puskesmas:{
            is_open:false,
            id_puskesmas:""
        }
    }

    componentDidMount=()=>{
        this.getsProvince()
    }
    getsProvince=()=>{
        API("/adminweb/region/gets_provinsi", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                province:res.data.data
            })
        })
        .catch(err=>{
            if(err.response.status===401){
                this.props.history.push("/?status=token_expired")
            }
        })
    }
    getsRegency=()=>{
        API("/adminweb/region/gets_kabupaten", {
            tokenID:access_token(),
            data:this.state.filter
        })
        .then(res=>{
            this.setState({
                regency:res.data.data
            })
        })
        .catch(err=>{
            if(err.response.status===401){
                this.props.history.push("/?status=token_expired")
            }
        })
    }
    getListDistrict=()=>{
        API("/adminweb/region/gets_kecamatan", {
            tokenID:access_token(),
            data:this.state.filter
        })
        .then(res=>{
            this.setState({
                district:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getListPuskesmas=()=>{
        API("/adminweb/puskesmas/gets_puskesmas", {
            tokenID:access_token(),
            data:this.state.filter
        })
        .then(res=>{
            this.setState({
                puskesmas:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //filter
    typeFilter=e=>{
        const target=e.target

        this.setState({
            filter:update(this.state.filter, {
                [target.name]:{$set:target.value}
            })
        }, ()=>{
            if(target.name==="province_id"){
                this.getsRegency()
            }
            else if(target.name==="regency_id"){
                this.getListDistrict()
                this.getListPuskesmas()
            }
        })
    }

    //TAMBAH PUSKESMAS
    showTambahPuskesmas=()=>{
        this.setState({
            tambah_puskesmas:{
                is_open:true,
                district_id:"",
                nama_puskesmas:""
            }
        })
    }
    hideTambahPuskesmas=()=>{
        this.setState({
            tambah_puskesmas:{
                is_open:false,
                district_id:"",
                nama_puskesmas:""
            }
        })
    }
    typeTambahPuskesmas=e=>{
        const target=e.target

        this.setState({
            tambah_puskesmas:update(this.state.tambah_puskesmas, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addPuskesmas=e=>{
        e.preventDefault()

        const {tambah_puskesmas}=this.state
        API("/adminweb/puskesmas/add_puskesmas", {
            tokenID:access_token(),
            data:{
                district_id:tambah_puskesmas.district_id,
                nama_puskesmas:tambah_puskesmas.nama_puskesmas
            }
        })
        .then(res=>{
            this.getListPuskesmas()
            this.hideTambahPuskesmas()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //EDIT PUSKESMAS
    showEditPuskesmas=data=>{
        this.setState({
            edit_puskesmas:{
                is_open:true,
                id_puskesmas:data.id_puskesmas,
                nama_puskesmas:data.nama_puskesmas
            }
        })
    }
    hideEditPuskesmas=()=>{
        this.setState({
            edit_puskesmas:{
                is_open:false,
                id_puskesmas:"",
                nama_puskesmas:""
            }
        })
    }
    typeEditPuskesmas=e=>{
        const target=e.target

        this.setState({
            edit_puskesmas:update(this.state.edit_puskesmas, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updatePuskesmas=e=>{
        e.preventDefault()

        API("/adminweb/puskesmas/update_puskesmas", {
            tokenID:access_token(),
            data:this.state.edit_puskesmas
        })
        .then(res=>{
            this.getListPuskesmas()
            this.hideEditPuskesmas()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //HAPUS SPESIALIS
    showHapusPuskesmas=data=>{
        this.setState({
            hapus_puskesmas:{
                is_open:true,
                id_puskesmas:data.id_puskesmas
            }
        })
    }
    hideHapusPuskesmas=()=>{
        this.setState({
            hapus_puskesmas:{
                is_open:false,
                id_puskesmas:""
            }
        })
    }
    deletePuskesmas=()=>{
        API("/adminweb/puskesmas/delete_puskesmas", {
            tokenID:access_token(),
            data:this.state.hapus_puskesmas
        })
        .then(res=>{
            this.getListPuskesmas()
            this.hideHapusPuskesmas()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    render(){
        const {filter, puskesmas, tambah_puskesmas, district, province, regency, edit_puskesmas, hapus_puskesmas}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex flex-column flex-md-row justify-content-between mb-4">
                                        <button className="btn btn-primary" onClick={this.showTambahPuskesmas} disabled={filter.regency_id===""}>
                                            <AiOutlinePlus/> Tambah Puskesmas
                                        </button>
                                        <div className='d-flex'>
                                            <select 
                                                className='form-select' 
                                                name="province_id" 
                                                value={filter.province_id} 
                                                onChange={this.typeFilter}
                                                style={{minWidth:"250px"}}
                                            >
                                                <option value="">-- Pilih Provinsi</option>
                                                {province.map(pro=>(
                                                    <option value={pro.id}>{pro.province_name}</option>
                                                ))}
                                            </select>
                                            <select 
                                                className='form-select ms-2' 
                                                name="regency_id" 
                                                value={filter.regency_id} 
                                                onChange={this.typeFilter}
                                                style={{minWidth:"250px"}}
                                            >
                                                <option value="">-- Pilih Kabupaten/Kota</option>
                                                {regency.map(pro=>(
                                                    <option value={pro.id}>{pro.regency_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">Kecamatan</th>
                                                    <th class="border-0">Nama Puskesmas</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {puskesmas.map((list, idx)=>(
                                                    <tr>
                                                            <td className="align-middle">{(idx+1)}</td>
                                                            <td>
                                                                {list.district_name}
                                                            </td>
                                                            <td>
                                                                {list.nama_puskesmas}
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-warning btn-sm" onClick={()=>this.showEditPuskesmas(list)}>
                                                                    Edit
                                                                </button>
                                                                <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusPuskesmas(list)}>
                                                                    Hapus
                                                                </button>
                                                            </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH PUSKESMAS */}
                <Modal show={tambah_puskesmas.is_open} onHide={this.hideTambahPuskesmas}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Puskesmas</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addPuskesmas}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Kecamatan</label>
                            <select 
                                class="form-select" 
                                name="district_id"
                                value={tambah_puskesmas.district_id}
                                onChange={this.typeTambahPuskesmas}
                            >
                                <option value="">-- Pilih Kecamatan</option>
                                {district.map(kec=>(
                                    <option value={kec.id}>{kec.district_name}</option>
                                ))}
                            </select>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Nama Puskesmas</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_puskesmas"
                                onChange={this.typeTambahPuskesmas}
                                value={tambah_puskesmas.nama_puskesmas}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahPuskesmas}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={tambah_puskesmas.nama_puskesmas===""||tambah_puskesmas.district_id===""}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL EDIT PUSKESMAS */}
                <Modal show={edit_puskesmas.is_open} onHide={this.hideEditPuskesmas}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Puskesmas</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updatePuskesmas}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Nama Puskesmas</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_puskesmas"
                                onChange={this.typeEditPuskesmas}
                                value={edit_puskesmas.nama_puskesmas}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditPuskesmas}>Batal</button>
                        <button type="submit" class="btn btn-info">Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_puskesmas.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusPuskesmas()}
                    deleteAction={()=>this.deletePuskesmas()}
                />
            </>
        )
    }
}

export default navAuth(Puskesmas, {
    title:"Manajemen Puskesmas"
})