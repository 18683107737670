import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit} from "react-icons/fi"
import {datetime} from "../../../config/datetime"

class Users extends React.Component{
    state={
        users:[],
        filter:{
            page:1,
            per_page:10,
            count_page:0,
            count_data:0
        },
        edit_user:{
            is_open:false,
            id_user:"",
            status:"unverified"
        }
    }

    componentDidMount=()=>{
        this.getListUser()
    }
    getListUser=()=>{
        API("/adminweb/users/gets_user", {
            tokenID:access_token(),
            data:{
                filter:this.state.filter
            }
        })
        .then(res=>{
            this.setState({
                users:res.data.data,
                filter:update(this.state.filter, {
                    count_page:{$set:res.data.count_page},
                    count_data:{$set:res.data.count_data}
                })
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getUserStatus=status=>{
        switch(status){
            case "active":
                return <span className="badge bg-success">Aktif</span>
            break;
            case "unverified":
                return <span className="badge bg-warning text-dark">Belum Diverifikasi</span>
            break;
            case "suspended":
                return <span className="badge bg-danger">Disuspend</span>
            break;
        }
    }
    goToPage=page=>{
        this.setState({
            filter:update(this.state.filter, {
                page:{$set:page}
            })
        }, ()=>{
            this.getListUser()
        })
    }

    //EDIT USER
    showEditUser=data=>{
        this.setState({
            edit_user:{
                is_open:true,
                id_user:data.id_user,
                status:data.status
            }
        })
    }
    hideEditUser=()=>{
        this.setState({
            edit_user:{
                is_open:false,
                id_user:"",
                status:""
            }
        })
    }
    typeEditUser=e=>{
        const target=e.target

        this.setState({
            edit_user:update(this.state.edit_user, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateUser=e=>{
        e.preventDefault()

        const {edit_user}=this.state
        API("/adminweb/users/update_user", {
            tokenID:access_token(),
            data:edit_user
        })
        .then(res=>{
            this.getListUser()
            this.hideEditUser()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }   

    render(){
        const {filter, users, edit_user}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">User/Pengguna</th>
                                                    <th class="border-0">E-Mail</th>
                                                    <th class="border-0">Bergabung pada</th>
                                                    <th class="border-0">Status</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((list, idx)=>(
                                                    <tr>
                                                            <td className="align-middle">{(idx+1)+((filter.page-1)*filter.per_page)}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="avatar">
                                                                            <Avatar 
                                                                                data={list}
                                                                                circle
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <span className="fw-semibold text-capitalize ms-2">{list.nama_lengkap}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a className="link-dark" href={`mailto:${list.email}`} target="_blank" style={{textDecoration:"underline"}}>
                                                                    {list.email}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {datetime(list.created_at)}
                                                            </td>
                                                            <td>
                                                                {this.getUserStatus(list.status)}
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-gray-200 btn-sm" onClick={()=>this.showEditUser(list)}>
                                                                    Edit
                                                                </button>
                                                            </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-4">
                                        <div className="d-flex flex-column">
                                            <div>Halaman {filter.page} dari {filter.count_page} Halaman</div>
                                            <div>Total Data : <span class="fw-bold fs-6">{filter.count_data}</span></div>
                                        </div>
                                        <div>
                                            <button 
                                                className="btn btn-gray-200" 
                                                disabled={filter.page<=1}
                                                onClick={()=>this.goToPage(filter.page-1)}
                                            >
                                                Sebelumnya
                                            </button>
                                            <button 
                                                className="btn btn-gray-200 ms-2" 
                                                disabled={filter.page>=filter.count_page}
                                                onClick={()=>this.goToPage(filter.page+1)}
                                            >
                                                Selanjutnya
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL EDIT STATUS */}
                <Modal show={edit_user.is_open} onHide={this.hideEditUser}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateUser}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Status</label>
                            <select 
                                class="form-select" 
                                name="status"
                                value={edit_user.status}
                                onChange={this.typeEditUser}
                            >
                                <option value="active">Aktif</option>
                                <option value="unverified">Belum Diverifikasi</option>
                                <option value="suspended">Disuspend</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditUser}>Batal</button>
                        <button type="submit" class="btn btn-info">Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }
}

export default navAuth(Users, {
    title:"Manajemen Users/Pasien"
})