
import React from "react"
import qs from 'querystring'
import { BASE_URL, access_token, remove_login_data, isUndefined } from "../../../config/config"
import { API, API_FORM } from "../../../config/api"
import update from 'immutability-helper'
import uniqid from "uniqid"
import { toast } from "react-toastify"
import {EditorState, convertFromRaw, convertToRaw, RichUtils} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Link } from "react-router-dom"
import navAuth from "../nav_auth"
import { Modal, Spinner } from "react-bootstrap"
import { AiOutlinePlus } from "react-icons/ai"
import { FiUpload } from "react-icons/fi"
import { ConfirmDelete } from "../../../component/ui/confirm"

//WIDGET
import CekPilihan from "./excerpt/cek_pilihan"
import LinkIcon from "./excerpt/link_icon"

class AddWidgetDashboard extends React.Component{
    state={
        tipe_widget:[
            {id_widget:"link_icon", nama_widget:"Link Icon", screenshot:"/img/widgets/link_icon.jpg"},
            {id_widget:"cek_pilihan", nama_widget:"Cek Pilihan", screenshot:"/img/widgets/cek_pilihan.jpg"}
        ],
        judul:"",
        widgets:[],
        tambah_widget:{
            is_open:false,
            tipe_widget:"",
            data:{},
            disabled:true
        },
        edit_widget:{
            is_open:false,
            widget:{},
            id_widget:"",
            tipe_widget:"",
            data:{},
            disabled:true
        },
        hapus_widget:{
            is_open:false,
            widget:{}
        },
        is_adding:false
    }

    componentDidMount=()=>{
    }

    //TAMBAH
    typeTambah=e=>{
        const target=e.target

        this.setState({
            [target.name]:target.value
        })
    }

    //WIDGET
    getWidgetScreenshot=tipe=>{
        const filtered=this.state.tipe_widget.filter(tw=>tw.id_widget===tipe)

        if(filtered.length>0){
            return <img src={filtered[0].screenshot} className="img-fluid"/>
        }
        return ""
    }
    getWidgetRenderInput=(type)=>{
        let data, tipe_widget, setData, action
        if(type==="tambah_widget"){
            data=this.state.tambah_widget.data
            tipe_widget=this.state.tambah_widget.tipe_widget
            action="tambah_widget"
            setData=(data, disabled)=>this.setDataTambahWidget(data, disabled)
        }
        else if(type==="edit_widget"){
            data=this.state.edit_widget.data
            tipe_widget=this.state.edit_widget.tipe_widget
            action="edit_widget"
            setData=(data, disabled)=>this.setDataEditWidget(data, disabled)
        }
        // else if(type==="edit_widget"){
        //     data=this.state.edit_widget.data
        // }
        
        if(tipe_widget==="cek_pilihan"){
            return <CekPilihan data={data} setData={setData} action={action}/>
        }
        else if(tipe_widget==="link_icon"){
            return <LinkIcon data={data} setData={setData} action={action}/>
        }
    }
    //tambah
    showTambahWidget=()=>{
        this.setState({
            tambah_widget:{
                is_open:true,
                tipe_widget:"",
                data:{},
                disabled:true
            }
        })
    }
    hideTambahWidget=()=>{
        this.setState({
            tambah_widget:{
                is_open:false,
                tipe_widget:"",
                data:{},
                disabled:true
            }
        })
    }
    typeTambahWidget=e=>{
        const target=e.target

        this.setState({
            tambah_widget:update(this.state.tambah_widget, {
                [target.name]:{$set:target.value}
            })
        })
    }
    setDataTambahWidget=(data={}, disabled=true)=>{
        this.setState({
            tambah_widget:update(this.state.tambah_widget, {
                data:{$set:data},
                disabled:{$set:disabled}
            })
        })
    }
    addWidget=(e)=>{
        e.preventDefault()

        const {tambah_widget}=this.state
        const widget={
            id_widget:uniqid(),
            tipe_widget:tambah_widget.tipe_widget,
            data:tambah_widget.data
        }

        this.setState({
            widgets:this.state.widgets.concat([widget])
        }, ()=>{
            this.hideTambahWidget()
        })
    }
    //edit
    showEditWidget=data=>{
        this.setState({
            edit_widget:{
                is_open:true,
                widget:data,
                id_widget:data.id_widget,
                tipe_widget:data.tipe_widget,
                data:data.data,
                disabled:true
            }
        })
    }
    hideEditWidget=()=>{
        this.setState({
            edit_widget:{
                is_open:false,
                widget:{},
                id_widget:"",
                tipe_widget:"",
                data:{},
                disabled:true
            }
        })
    }
    setDataEditWidget=(data={}, disabled=true)=>{
        this.setState({
            edit_widget:update(this.state.edit_widget, {
                data:{$set:data},
                disabled:{$set:disabled}
            })
        })
    }
    updateWidget=e=>{
        e.preventDefault()

        const {edit_widget}=this.state
        const index=this.state.widgets.map(bs=>JSON.stringify(bs)).indexOf(JSON.stringify(edit_widget.widget))
        const widget={
            id_widget:edit_widget.id_widget,
            tipe_widget:edit_widget.tipe_widget,
            data:edit_widget.data
        }

        this.setState({
            widgets:update(this.state.widgets, {
                [index]:{$set:widget}
            })
        }, ()=>{
            this.hideEditWidget()
        })
    }
    //hapus
    showHapusWidget=data=>{
        this.setState({
            hapus_widget:{
                is_open:true,
                widget:data
            }
        })
    }
    hideHapusWidget=()=>{
        this.setState({
            hapus_widget:{
                is_open:false,
                widget:{}
            }
        })
    }
    deleteWidget=()=>{
        const widgets=this.state.widgets.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_widget.widget))

        this.setState({
            widgets:widgets
        }, ()=>{
            this.hideHapusWidget()
        })
    }

    //ADD WIDGET DASHBOARD
    addWidgetDashboard=e=>{
        e.preventDefault()

        API("/adminweb/widget_dashboard/add_widget", {
            tokenID:access_token(),
            data:{
                judul:this.state.judul,
                widgets:this.state.widgets
            }
        })
        .then(res=>{
            this.setState({
                judul:"",
                widgets:[]
            })
            toast.success("Berhasil menambahkan Layanan")
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    render(){
        const {
            tipe_widget,
            judul,
            widgets,
            tambah_widget,
            edit_widget,
            hapus_widget,
            is_adding

        }=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <form className="card" onSubmit={this.addWidgetDashboard}>
                                <div className="card-body">
                                    <div className="form-group mb-3 mt-4">
                                        <label class="form-label">Judul</label>
                                        <div className="input-icon col">
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                value={judul}
                                                name="judul"
                                                onChange={this.typeTambah}
                                            />
                                            <small className="form-hint"></small>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mt-4">
                                        <div class="form-label d-flex justify-content-between">
                                            <span className="fw-semibold">Widget</span>
                                            <button type="button" className="btn btn-info" onClick={this.showTambahWidget}><AiOutlinePlus/> Baru</button>
                                        </div>
                                        <div className="input-icon col">
                                            <div class="table-responsive">
                                                <table class="table table-centered table-nowrap mb-0 rounded">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th class="border-0 rounded-start" width="50">#</th>
                                                            <th class="border-0">Tipe Widget</th>
                                                            <th class="border-0 rounded-end p-0" width="90"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {widgets.map((list, idx)=>(
                                                            <tr>
                                                                <td className="align-middle">{(idx+1)}</td>
                                                                <td>{list.tipe_widget}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditWidget(list)}>
                                                                        Edit
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusWidget(list)}>
                                                                        Hapus
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                   <div className="d-flex justify-content-center">
                                        <button 
                                            type="submit" 
                                            class="btn btn-primary btn-width-min" 
                                            disabled={
                                                this.state.judul.trim()===""||
                                                this.state.is_adding
                                            }
                                        >
                                            {is_adding?
                                                <Spinner size="sm" variant="light" animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            :
                                                <>Save Changes</>
                                            }
                                            
                                        </button>
                                   </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* WIDGET */}
                <Modal show={tambah_widget.is_open} size="lg" onHide={this.hideTambahWidget}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Widget</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addWidget}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Tipe Widget</label>
                            <select 
                                class="form-select" 
                                name="tipe_widget"
                                value={tambah_widget.tipe_widget}
                                onChange={this.typeTambahWidget}
                            >
                                <option value="">-- Pilih Widget</option>
                                {tipe_widget.map(item=>(
                                    <option value={item.id_widget}>{item.nama_widget}</option>
                                ))}
                            </select>
                        </div>
                        {tambah_widget.tipe_widget!==""&&
                            <div className="mb-4 border-top">
                                <div className="row">
                                    <div className="col-md-5 py-3">{this.getWidgetScreenshot(tambah_widget.tipe_widget)}</div>
                                    <div className="col-md-7">{this.getWidgetRenderInput("tambah_widget")}</div>
                                </div>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahWidget}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={tambah_widget.disabled}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <Modal show={edit_widget.is_open} size="lg" onHide={this.hideEditWidget}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Widget</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateWidget}>
                    <Modal.Body>
                        {edit_widget.tipe_widget!==""&&
                            <div className="mb-4 border-top">
                                <div className="row">
                                    <div className="col-md-5 py-3">{this.getWidgetScreenshot(edit_widget.tipe_widget)}</div>
                                    <div className="col-md-7">{this.getWidgetRenderInput("edit_widget")}</div>
                                </div>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditWidget}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={edit_widget.disabled}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <ConfirmDelete
                    show={hapus_widget.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusWidget()}
                    deleteAction={()=>this.deleteWidget()}
                />
            </>
        )
    }
}


export default navAuth(AddWidgetDashboard, {
    title:"Tambah Widget Dashboard"
})