import React from 'react'
import update from "immutability-helper"
import { API } from '../../../../config/api'
import { access_token, remove_login_data } from "../../../../config/config"
import CustomLink from '../../../../component/custom_link'

class Jumbotron extends React.Component{
    state={
        data:{
            judul:"",
            show_title:true,
            header:"",
            deskripsi:"",
            btn_text:"",
            btn_link:""
        },
        select_link:{
            is_open:false
        }
    }

    componentDidMount=()=>{
        if(this.props.action==="tambah_widget"){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
        else{
            const {data}=this.props
            this.setState({
                data:{
                    judul:data.judul,
                    show_title:data.show_title,
                    header:data.header,
                    deskripsi:data.deskripsi,
                    btn_text:data.btn_text,
                    btn_link:data.btn_link
                }
            })
        }
    }
    componentDidUpdate=(prevProps, prevState)=>{
        if(JSON.stringify(prevState.data)!==JSON.stringify(this.state.data)){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
    }
    disabledSubmit=()=>{
        const {data}=this.state
        if(data.judul.trim()===""||data.header.trim()===""||data.deskripsi.trim()===""||data.btn_text.trim()===""||data.btn_link.trim()===""){
            return true
        }
        return false
    }

    //TAMBAH
    typeTambah=e=>{
        const target=e.target

        let value
        if(target.type==="checkbox"){
            value=target.checked
        }
        else{
            value=target.value
        }

        this.setState({
            data:update(this.state.data, {
                [target.name]:{$set:value}
            })
        })
    }

    //SELECT LINK
    setLink=(data)=>{
        this.setState(prevState=>{
            return {
                ...prevState,
                data:{
                    ...prevState.data,
                    btn_link:data
                }
            }
        })
    }
    showSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:true
            }
        })
    }
    hideSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:false
            }
        })
    }

    render(){
        const {data, spesialis, select_link}=this.state

        return (
            <>
            <div className='row'>
                <div className='col-12'>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Judul</label>
                        <div className="input-icon col">
                            <input 
                                type="text" 
                                className="form-control"
                                value={data.judul}
                                name="judul"
                                onChange={this.typeTambah}
                            />
                            <small className="form-hint"></small>
                        </div>
                    </div>
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="checkbox" 
                            name="show_title"
                            checked={data.show_title} 
                            id="cb__tampilkan_judul"
                            onChange={this.typeTambah}
                        />
                        <label class="form-check-label" for="cb__tampilkan_judul">
                            Tampilkan Judul
                        </label>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Header</label>
                        <div className="input-icon col">
                            <input 
                                type="text" 
                                className="form-control"
                                value={data.header}
                                name="header"
                                onChange={this.typeTambah}
                            />
                            <small className="form-hint"></small>
                        </div>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Deskripsi</label>
                        <div className="input-icon col">
                            <textarea
                                rows="3"
                                className="form-control"
                                value={data.deskripsi}
                                name="deskripsi"
                                onChange={this.typeTambah}
                            ></textarea>
                            <small className="form-hint"></small>
                        </div>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Button Text</label>
                        <div className="input-icon col">
                            <input 
                                type="text" 
                                className="form-control"
                                value={data.btn_text}
                                name="btn_text"
                                onChange={this.typeTambah}
                            />
                            <small className="form-hint"></small>
                        </div>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Button Link</label>
                        <div className="input-icon col">
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={data.btn_link}
                                    disabled
                                />
                            </div>
                            <small className="form-hint"></small>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* LINK NAVIGASI */}
            <CustomLink
                is_open={select_link.is_open}
                setLink={data=>this.setLink(data)}
                closeSelectLink={()=>this.hideSelectLink()}
            />
            </>
        )
    }
}

export default Jumbotron