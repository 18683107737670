import React from 'react'
import update from "immutability-helper"
import { API, API_FORM } from '../../../../config/api'
import { access_token, remove_login_data, BASE_URL, isUndefined } from "../../../../config/config"
import uniqid from "uniqid"
import { AiOutlinePlus } from 'react-icons/ai'
import { Modal } from 'react-bootstrap'
import { ConfirmDelete } from '../../../../component/ui/confirm'
import { FiUpload } from 'react-icons/fi'
import CustomLink from '../../../../component/custom_link'

class InputPertanyaan extends React.Component{
    state={
        data:{
            pertanyaan:"",
            konteks:"",
            kategori:[],
            jawaban_type:"input",
            jawaban_pilihan:[]
        },
        rumus_formula:[
            {name:"Bahu Turun", value:"bahu_turun", data:{a:0, b:0}},
            {name:"Segitiga", value:"segitiga", data:{a:0, b:0, c:0}},
            {name:"Bahu Naik", value:"bahu_naik", data:{a:0, b:0}}
        ],
        tambah_kategori:{
            is_open:false,
            nama_kategori:"",
            rumus_kategori:"",
            data:{}
        },
        edit_kategori:{
            is_open:false,
            kategori:{},
            nama_kategori:"",
            rumus_kategori:"",
            data:{}
        },
        hapus_kategori:{
            is_open:false,
            kategori:{}
        },
        tambah_jawaban:{
            is_open:false,
            text:"",
            value:""
        },
        edit_jawaban:{
            is_open:false,
            jawaban:{},
            text:"",
            value:""
        },
        hapus_jawaban:{
            is_open:false,
            jawaban:{}
        }
    }

    componentDidMount=()=>{
        if(this.props.action==="tambah"){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
        else{
            const {data}=this.props
            this.setState({
                data:{
                    pertanyaan:data.pertanyaan,
                    konteks:data.konteks,
                    kategori:data.kategori,
                    jawaban_type:data.jawaban_type,
                    jawaban_pilihan:data.jawaban_pilihan
                }
            })
        }
    }
    componentDidUpdate=(prevProps, prevState)=>{
        if(JSON.stringify(prevState.data)!==JSON.stringify(this.state.data)){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
    }
    disabledSubmit=()=>{
        const {data}=this.state
        let disabled=false

        if(data.pertanyaan.trim()==="") disabled=true
        if(data.kategori.length===0) disabled=true
        if(data.jawaban_type.trim()==="") disabled=true
        if(data.jawaban_type==="pilihan"&&data.jawaban_pilihan.length===0) disabled=true

        return disabled
    }

    //DATA
    typeData=e=>{
        const target=e.target

        this.setState({
            data:update(this.state.data, {
                [target.name]:{$set:target.value}
            })
        }, ()=>{
            if(target.name==="kategori"){
                let rumus_data
                switch(target.value){
                    case "bahu_turun":
                        rumus_data={a:0, b:0}
                    break;
                    case "segitiga":
                        rumus_data={a:0, b:0, c:0}
                    break;
                    case "bahu_naik":
                        rumus_data={a:0, b:0}
                    break;
                    default :
                        rumus_data={}
                }

                this.setState({
                    data:update(this.state.data, {
                        rumus_data:{$set:rumus_data}
                    })
                })
            }
            if(target.name==="jawaban_type"){
                this.setState({
                    data:update(this.state.data, {
                        jawaban_pilihan:{$set:[]}
                    })
                })
            }
        })
    }
    renderKategori=()=>{
        const {rumus_data, kategori}=this.state.data

        return (
            <div class="mb-4">
                <div class="form-label d-flex justify-content-between align-items-center">
                    <span className="fw-semibold">Kategori</span>
                    {/* <button type="button" className="btn btn-info btn-sm" onClick={this.showTambahKategori}><AiOutlinePlus/> Baru</button> */}
                </div>
                <div class="table-responsive">
                    <table class="table table-centered table-nowrap mb-0 rounded">
                        <thead class="thead-light">
                            <tr>
                                <th class="border-0 rounded-start" width="50">#</th>
                                <th class="border-0">Nama Kategori</th>
                                <th class="border-0 rounded-end p-0" width="90"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {kategori.map((list, idx)=>(
                                <tr>
                                    <td className="align-middle">{(idx+1)}</td>
                                    <td>{list.nama_kategori}</td>
                                    <td>
                                        {/* <button type="button" className="btn btn-warning btn-sm ms-2" onClick={()=>this.showEditKategori(list)}>
                                            Edit
                                        </button>
                                        <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusKategori(list)}>
                                            Hapus
                                        </button> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    renderTipeJawaban=()=>{
        const {data}=this.state

        if(data.jawaban_type==="pilihan"){
            return (
                <>
                    {data.jawaban_type==="pilihan"&&
                        <div class="mb-4">
                            <div class="form-label d-flex justify-content-between align-items-center">
                                <span className="fw-semibold">Tipe Jawaban</span>
                                {data.jawaban_type==="pilihan"&&
                                    <button type="button" className="btn btn-info btn-sm" onClick={this.showTambahJawaban}><AiOutlinePlus/> Baru</button>
                                }
                            </div>
                            <div class="table-responsive">
                                <table class="table table-centered table-nowrap mb-0 rounded">
                                    <thead class="thead-light">
                                        <tr>
                                            <th class="border-0 rounded-start" width="50">#</th>
                                            <th class="border-0">Text</th>
                                            <th class="border-0 rounded-end p-0" width="90"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.jawaban_pilihan.map((list, idx)=>(
                                            <tr>
                                                <td className="align-middle">{(idx+1)}</td>
                                                <td>{list.text}</td>
                                                <td>
                                                    <button type="button" className="btn btn-warning btn-sm ms-2" onClick={()=>this.showEditJawaban(list)}>
                                                        Edit
                                                    </button>
                                                    <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusJawaban(list)}>
                                                        Hapus
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {/* <div class="mb-1">
                        <input 
                            type="text" 
                            className="form-control"
                            name="pilihan_text"
                            onChange={this.typeTambahPertanyaan}
                            value={data.pilihan_text}
                            placeholder="Text..."
                        />
                    </div>
                    <div class="mb-1">
                        <input 
                            type="text" 
                            className="form-control"
                            name="pilihan_value"
                            onChange={this.typeTambahPertanyaan}
                            value={data.pilihan_value}
                            placeholder="Value..."
                        />
                    </div>
                    <div class="mb-4">
                        <button className="btn btn-primary" type="button" onClick={this.addTambahJawaban}>Add</button>
                    </div>
                    <div class="mb-4">
                        <label class="my-1 me-2" for="country">Opsi Jawaban</label>
                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap mb-0 rounded">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="border-0 rounded-start" width="50">#</th>
                                        <th class="border-0">Jawaban</th>
                                        <th class="border-0 rounded-end p-0" width="90"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.jawaban_pilihan.map((list, idx)=>(
                                        <tr>
                                            <td className="align-middle">{(idx+1)}</td>
                                            <td>{list.text}</td>
                                            <td>
                                                <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.delTambahJawaban(list)}>
                                                    Hapus
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                </>
            )
        }
    }

    //KATEGORI
    //add
    showTambahKategori=()=>{
        this.setState({
            tambah_kategori:{
                is_open:true,
                nama_kategori:"",
                rumus_kategori:"",
                data:{}
            }
        })
    }
    hideTambahKategori=()=>{
        this.setState({
            tambah_kategori:{
                is_open:false,
                nama_kategori:"",
                rumus_kategori:"",
                data:{}
            }
        })
    }
    typeTambahKategori=e=>{
        const target=e.target

        this.setState({
            tambah_kategori:update(this.state.tambah_kategori, {
                [target.name]:{$set:target.value}
            })
        }, ()=>{
            if(target.name==="rumus_kategori"){
                let rumus_data
                switch(target.value){
                    case "bahu_turun":
                        rumus_data={a:0, b:0}
                    break;
                    case "segitiga":
                        rumus_data={a:0, b:0, c:0}
                    break;
                    case "bahu_naik":
                        rumus_data={a:0, b:0}
                    break;
                    default :
                        rumus_data={}
                }

                this.setState({
                    tambah_kategori:update(this.state.tambah_kategori, {
                        data:{$set:rumus_data}
                    })
                })
            }
        })
    }
    typeTambahKategoriRumusData=e=>{
        const target=e.target

        this.setState({
            tambah_kategori:update(this.state.tambah_kategori, {
                data:{
                    [target.name]:{$set:target.value}
                }
            })
        })
    }
    renderTambahKategoriRumusData=()=>{
        const {rumus_kategori, data}=this.state.tambah_kategori

        let render_data=(
            <>
                <div className="mb-4 col-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">A</span>
                        <input 
                            type="text" 
                            class="form-control"
                            name="a"
                            value={data.a}
                            onChange={this.typeTambahKategoriRumusData}
                        />
                    </div>
                </div>
                <div className="mb-4 col-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">B</span>
                        <input 
                            type="text" 
                            class="form-control"
                            name="b"
                            value={data.b}
                            onChange={this.typeTambahKategoriRumusData}
                        />
                    </div>
                </div>
            </>
        )
        switch(rumus_kategori){
            case "bahu_turun":
            case "bahu_naik":
                return (
                    <div className="row">
                        {render_data}
                    </div>
                )
            break;
            case "segitiga":
                return (
                    <div className="row">
                        {render_data}
                        <div className="mb-4 col-4">
                            <div class="input-group mb-3">
                                <span class="input-group-text">C</span>
                                <input 
                                    type="text" 
                                    class="form-control"
                                    name="c"
                                    value={data.c}
                                    onChange={this.typeTambahKategoriRumusData}
                                />
                            </div>
                        </div>
                    </div>
                )
            break;
        }
    }
    disabledTambahKategori=()=>{
        let disabled=false
        
        const {tambah_kategori}=this.state
        if(tambah_kategori.nama_kategori.trim()==="") disabled=true
        if(tambah_kategori.rumus_kategori.trim()==="") disabled=true
        if(tambah_kategori.rumus_kategori==="bahu_turun"&&(tambah_kategori.data.a===""||tambah_kategori.data.b==="")) disabled=true
        if(tambah_kategori.rumus_kategori==="segitiga"&&(tambah_kategori.data.a===""||tambah_kategori.data.b===""||tambah_kategori.data.c==="")) disabled=true
        if(tambah_kategori.rumus_kategori==="bahu_naik"&&(tambah_kategori.data.a===""||tambah_kategori.data.b==="")) disabled=true

        return disabled
    }
    addKategori=e=>{
        e.preventDefault()

        const {tambah_kategori}=this.state
        const data={
            id:uniqid(),
            nama_kategori:tambah_kategori.nama_kategori,
            rumus_kategori:tambah_kategori.rumus_kategori,
            data:tambah_kategori.data
        }
        this.setState({
            data:update(this.state.data, {
                kategori:{$set:this.state.data.kategori.concat([data])}
            })
        })
        this.hideTambahKategori()
    }
    //edit
    showEditKategori=(data)=>{
        this.setState({
            edit_kategori:{
                is_open:true,
                kategori:data,
                nama_kategori:data.nama_kategori,
                rumus_kategori:data.rumus_kategori,
                data:data.data
            }
        })
    }
    hideEditKategori=()=>{
        this.setState({
            edit_kategori:{
                is_open:false,
                kategori:{},
                nama_kategori:"",
                rumus_kategori:"",
                data:{}
            }
        })
    }
    typeEditKategori=e=>{
        const target=e.target
    
        this.setState({
            edit_kategori:update(this.state.edit_kategori, {
                [target.name]:{$set:target.value}
            })
        }, ()=>{
            if(target.name==="rumus_kategori"){
                let rumus_data
                switch(target.value){
                    case "bahu_turun":
                        rumus_data={a:0, b:0}
                    break;
                    case "segitiga":
                        rumus_data={a:0, b:0, c:0}
                    break;
                    case "bahu_naik":
                        rumus_data={a:0, b:0}
                    break;
                    default :
                        rumus_data={}
                }
    
                this.setState({
                    edit_kategori:update(this.state.edit_kategori, {
                        data:{$set:rumus_data}
                    })
                })
            }
        })
    }
    typeEditKategoriRumusData=e=>{
        const target=e.target
    
        this.setState({
            edit_kategori:update(this.state.edit_kategori, {
                data:{
                    [target.name]:{$set:target.value}
                }
            })
        })
    }
    renderEditKategoriRumusData=()=>{
        const {rumus_kategori, data}=this.state.edit_kategori
    
        let render_data=(
            <>
                <div className="mb-4 col-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">A</span>
                        <input 
                            type="text" 
                            class="form-control"
                            name="a"
                            value={data.a}
                            onChange={this.typeEditKategoriRumusData}
                        />
                    </div>
                </div>
                <div className="mb-4 col-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text">B</span>
                        <input 
                            type="text" 
                            class="form-control"
                            name="b"
                            value={data.b}
                            onChange={this.typeEditKategoriRumusData}
                        />
                    </div>
                </div>
            </>
        )
        switch(rumus_kategori){
            case "bahu_turun":
            case "bahu_naik":
                return (
                    <div className="row">
                        {render_data}
                    </div>
                )
            break;
            case "segitiga":
                return (
                    <div className="row">
                        {render_data}
                        <div className="mb-4 col-4">
                            <div class="input-group mb-3">
                                <span class="input-group-text">C</span>
                                <input 
                                    type="text" 
                                    class="form-control"
                                    name="c"
                                    value={data.c}
                                    onChange={this.typeEditKategoriRumusData}
                                />
                            </div>
                        </div>
                    </div>
                )
            break;
        }
    }
    disabledEditKategori=()=>{
        let disabled=false
        
        const {edit_kategori}=this.state
        if(edit_kategori.nama_kategori.trim()==="") disabled=true
        if(edit_kategori.rumus_kategori.trim()==="") disabled=true
        if(edit_kategori.rumus_kategori==="bahu_turun"&&(edit_kategori.data.a===""||edit_kategori.data.b==="")) disabled=true
        if(edit_kategori.rumus_kategori==="segitiga"&&(edit_kategori.data.a===""||edit_kategori.data.b===""||edit_kategori.data.c==="")) disabled=true
        if(edit_kategori.rumus_kategori==="bahu_naik"&&(edit_kategori.data.a===""||edit_kategori.data.b==="")) disabled=true
    
        return disabled
    }
    updateKategori=e=>{
        e.preventDefault()
    
        const {edit_kategori}=this.state
        const index=this.state.data.kategori.map(k=>JSON.stringify(k)).indexOf(JSON.stringify(edit_kategori.kategori))
        const data={
            id:uniqid(),
            nama_kategori:edit_kategori.nama_kategori,
            rumus_kategori:edit_kategori.rumus_kategori,
            data:edit_kategori.data
        }
        this.setState({
            data:update(this.state.data, {
                kategori:{
                    [index]:{$set:data}
                }
            })
        })
        this.hideEditKategori()
    }
    //hapus
    showHapusKategori=data=>{
        this.setState({
            hapus_kategori:{
                is_open:true,
                kategori:data
            }
        })
    }
    hideHapusKategori=()=>{
        this.setState({
            hapus_kategori:{
                is_open:false,
                kategori:{}
            }
        })
    }
    delKategori=()=>{
        const kategori=this.state.data.kategori.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_kategori.kategori))

        this.setState({
            data:update(this.state.data, {
                kategori:{$set:kategori}
            })
        }, ()=>{
            this.hideHapusKategori()
        })
    }

    //PILIHAN JAWABAN
    //add
    showTambahJawaban=()=>{
        this.setState({
            tambah_jawaban:{
                is_open:true,
                text:"",
                value:""
            }
        })
    }
    hideTambahJawaban=()=>{
        this.setState({
            tambah_jawaban:{
                is_open:false,
                text:"",
                value:""
            }
        })
    }
    typeTambahJawaban=e=>{
        const target=e.target

        this.setState({
            tambah_jawaban:update(this.state.tambah_jawaban, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addJawaban=(e)=>{
        e.preventDefault()

        const {tambah_jawaban}=this.state
        const data={
            id:uniqid(),
            text:tambah_jawaban.text,
            value:tambah_jawaban.value
        }
        this.setState({
            data:update(this.state.data, {
                jawaban_pilihan:{$set:this.state.data.jawaban_pilihan.concat([data])}
            })
        })
        this.hideTambahJawaban()
    }
    disabledTambahJawaban=()=>{
        if(this.state.tambah_jawaban.text===""||this.state.tambah_jawaban.value===""){
            return true
        }
        return false
    }
    //edit
    showEditJawaban=(data)=>{
        this.setState({
            edit_jawaban:{
                is_open:true,
                jawaban:data,
                text:data.text,
                value:data.value
            }
        })
    }
    hideEditJawaban=()=>{
        this.setState({
            edit_jawaban:{
                is_open:false,
                jawaban:{},
                text:"",
                value:""
            }
        })
    }
    typeEditJawaban=e=>{
        const target=e.target

        this.setState({
            edit_jawaban:update(this.state.edit_jawaban, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateJawaban=(e)=>{
        e.preventDefault()

        const {edit_jawaban}=this.state
        const index=this.state.data.jawaban_pilihan.map(jp=>JSON.stringify(jp)).indexOf(JSON.stringify(this.state.edit_jawaban.jawaban))
        const data={
            id:uniqid(),
            text:edit_jawaban.text,
            value:edit_jawaban.value
        }
        this.setState({
            data:update(this.state.data, {
                jawaban_pilihan:{
                    [index]:{$set:data}
                }
            })
        })
        this.hideEditJawaban()
    }
    disabledEditJawaban=()=>{
        if(this.state.edit_jawaban.text===""||this.state.edit_jawaban.value===""){
            return true
        }
        return false
    }
    //hapus
    showHapusJawaban=data=>{
        this.setState({
            hapus_jawaban:{
                is_open:true,
                jawaban:data
            }
        })
    }
    hideHapusJawaban=()=>{
        this.setState({
            hapus_jawaban:{
                is_open:false,
                jawaban:{}
            }
        })
    }
    delJawaban=()=>{
        const jawaban=this.state.data.jawaban_pilihan.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_jawaban.jawaban))

        this.setState({
            data:update(this.state.data, {
                jawaban_pilihan:{$set:jawaban}
            })
        }, ()=>{
            this.hideHapusJawaban()
        })
    }

    render(){
        const {data, tambah_kategori, rumus_formula, edit_kategori, hapus_kategori, tambah_jawaban, edit_jawaban, hapus_jawaban}=this.state

        return (
            <>
                <div class="mb-4">
                    <label class="my-1 me-2" for="country">Pertanyaan</label>
                    <textarea
                        rows="2"
                        className="form-control"
                        name="pertanyaan"
                        onChange={this.typeData}
                        value={data.pertanyaan}
                    ></textarea>
                </div>
                <div class="mb-4">
                    <label class="my-1 me-2" for="country">Konteks</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Contoh : suhu, laju napas"
                        name="konteks"
                        onChange={this.typeData}
                        value={data.konteks}
                        disabled
                    />
                </div>
                <div className="row">
                    <div className="col-6">
                        {this.renderKategori()}
                    </div>
                    <div className="col-6">
                        <div class="mb-4">
                            <div class="form-label d-flex justify-content-between align-items-center">
                                <span className="fw-semibold">Tipe Jawaban</span>
                            </div>
                            <select className="form-select" name="jawaban_type" value={data.jawaban_type} onChange={this.typeData}>
                                <option value="">--Pilih Tipe Jawaban</option>
                                <option value="input">Input</option>
                                <option value="pilihan">Pilihan</option>
                            </select>
                        </div>
                        {this.renderTipeJawaban()}
                    </div>
                </div>
                
                {/* KATEGORI */}
                <Modal show={tambah_kategori.is_open} onHide={this.hideTambahKategori} backdrop="static" backdropClassName='backdrop-nested'>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Kategori</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Nama Kategori</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_kategori"
                                onChange={this.typeTambahKategori}
                                value={tambah_kategori.nama_kategori}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Rumus/Formula</label>
                            <select className="form-select" name="rumus_kategori" value={tambah_kategori.rumus_kategori} onChange={this.typeTambahKategori}>
                                <option value="">--Pilih Rumus</option>
                                {rumus_formula.map(rj=><option value={rj.value}>{rj.name}</option>)}
                            </select>
                        </div>
                        {this.renderTambahKategoriRumusData()}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahKategori}>Batal</button>
                        <button 
                            type="button" 
                            class="btn btn-info" 
                            disabled={this.disabledTambahKategori()}
                            onClick={this.addKategori}
                        >
                            Tambahkan
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={edit_kategori.is_open} onHide={this.hideEditKategori} backdrop="static" backdropClassName='backdrop-nested'>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Kategori</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Nama Kategori</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_kategori"
                                onChange={this.typeEditKategori}
                                value={edit_kategori.nama_kategori}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Rumus/Formula</label>
                            <select className="form-select" name="rumus_kategori" value={edit_kategori.rumus_kategori} onChange={this.typeEditKategori}>
                                <option value="">--Pilih Rumus</option>
                                {rumus_formula.map(rj=><option value={rj.value}>{rj.name}</option>)}
                            </select>
                        </div>
                        {this.renderEditKategoriRumusData()}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditKategori}>Batal</button>
                        <button 
                            type="button" 
                            class="btn btn-info" 
                            disabled={this.disabledEditKategori()}
                            onClick={this.updateKategori}
                        >
                            Update
                        </button>
                    </Modal.Footer>
                </Modal>
                <ConfirmDelete
                    show={hapus_kategori.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusKategori()}
                    deleteAction={()=>this.delKategori()}
                    nested
                />

                {/* JAWABAN */}
                <Modal show={tambah_jawaban.is_open} onHide={this.hideTambahJawaban} backdrop="static" backdropClassName='backdrop-nested'>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Jawaban</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Text</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="text"
                                onChange={this.typeTambahJawaban}
                                value={tambah_jawaban.text}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Value</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="value"
                                onChange={this.typeTambahJawaban}
                                value={tambah_jawaban.value}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahJawaban}>Batal</button>
                        <button 
                            type="button" 
                            class="btn btn-info" 
                            disabled={this.disabledTambahJawaban()}
                            onClick={this.addJawaban}
                        >
                            Tambahkan
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={edit_jawaban.is_open} onHide={this.hideEditJawaban} backdrop="static" backdropClassName='backdrop-nested'>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Jawaban</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Text</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="text"
                                onChange={this.typeEditJawaban}
                                value={edit_jawaban.text}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Value</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="value"
                                onChange={this.typeEditJawaban}
                                value={edit_jawaban.value}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditJawaban}>Batal</button>
                        <button 
                            type="button" 
                            class="btn btn-info" 
                            disabled={this.disabledEditJawaban()}
                            onClick={this.updateJawaban}
                        >
                            Update
                        </button>
                    </Modal.Footer>
                </Modal>
                <ConfirmDelete
                    show={hapus_jawaban.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusJawaban()}
                    deleteAction={()=>this.delJawaban()}
                    nested
                />
            </>
        )
    }
}

export default InputPertanyaan