import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { API } from "./config/api";
import { access_token, is_login, login_data } from "./config/config";
import moment from "moment"
import 'moment/locale/id'

//login
import Login from "./pages/login";
import Dashboard from "./pages/admin";

moment.locale("id")
class App extends React.Component{
    state={
        is_login:false,
        is_loading:true
    }

    componentDidMount=()=>{
        if(is_login()){
            API("/adminweb/authentication/verify_token", {
                tokenID:access_token()
            })
            .then(res=>{
                this.setState({
                    is_loading:false,
                    is_login:true
                })
            })
            .catch(err=>{
                switch(err.response.data.err_code){
                    case "ERR_TOKEN_UNVERIFIED":
                        this.setState({
                            is_loading:false,
                            is_login:false
                        })
                    break;
                }
            })
        }
        else{
            this.setState({
                is_loading:false,
                is_login:false
            })
        }
    }

    render(){
        const {is_loading, is_login}=this.state

        return (
            <>
                {!is_loading&&
                    <>
                        {!is_login?
                            <Login {...this.props}/>
                        :
                            <Dashboard {...this.props}/>
                        }
                    </>
                }

                <ToastContainer
                    position="top-right"
                    autoClose={500000}
                    newestOnTop={false}
                    rtl={false}
                    closeOnClick
                    hideProgressBar
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        )
    }
}

export default withRouter(App)