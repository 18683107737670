import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API, API_FORM } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, isUndefined, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit, FiUpload} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"
import { Link } from "react-router-dom"

class QNA extends React.Component{
    state={
        tanya_jawab:[],
        hapus_tanya_jawab:{
            is_open:false,
            id_tanya_jawab:""
        }
    }

    componentDidMount=()=>{
        this.getsQNA()
    }
    getsQNA=()=>{
        API("/adminweb/tanya_jawab/gets_qna", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                tanya_jawab:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //HAPUS
    showHapusTanyaJawab=data=>{
        this.setState({
            hapus_tanya_jawab:{
                is_open:true,
                id_tanya_jawab:data.id_tanya_jawab
            }
        })
    }
    hideHapusTanyaJawab=()=>{
        this.setState({
            hapus_tanya_jawab:{
                is_open:false,
                id_tanya_jawab:""
            }
        })
    }
    deleteTanyaJawab=()=>{
        API("/adminweb/tanya_jawab/delete_qna", {
            tokenID:access_token(),
            data:this.state.hapus_tanya_jawab
        })
        .then(res=>{
            this.getsQNA()
            this.hideHapusTanyaJawab()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }


    render(){
        const {tanya_jawab, hapus_tanya_jawab}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex mb-4">
                                        <Link to="/tanya_jawab/add" className="btn btn-primary">
                                            <AiOutlinePlus/> Tambah Tanya Jawab
                                        </Link>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">Judul</th>
                                                    <th class="border-0">Created At</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tanya_jawab.map((list, idx)=>(
                                                    <tr>
                                                        <td className="align-middle">{(idx+1)}</td>
                                                        <td>{list.judul}</td>
                                                        <td>{datetime(list.created_at)}</td>
                                                        <td>
                                                            <Link to={`/tanya_jawab/edit/${list.id_tanya_jawab}`} className="btn btn-warning btn-sm">
                                                                Edit
                                                            </Link>
                                                            <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusTanyaJawab(list)}>
                                                                Hapus
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_tanya_jawab.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusTanyaJawab()}
                    deleteAction={()=>this.deleteTanyaJawab()}
                />
            </>
        )
    }
}

export default navAuth(QNA, {
    title:"Tanya Jawab"
})