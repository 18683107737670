import React from 'react'
import update from "immutability-helper"
import { API } from '../../../../config/api'
import { access_token, remove_login_data } from "../../../../config/config"

class ChatDokter extends React.Component{
    state={
        spesialis:[],
        data:{
            judul:"",
            show_title:true,
            id_spesialis:""
        }
    }

    componentDidMount=()=>{
        if(this.props.action==="tambah_widget"){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
        else{
            const {data}=this.props
            this.setState({
                data:{
                    judul:data.judul,
                    show_title:data.show_title,
                    id_spesialis:data.id_spesialis
                }
            })
        }
        this.getsSpesialis()
    }
    componentDidUpdate=(prevProps, prevState)=>{
        if(JSON.stringify(prevState.data)!==JSON.stringify(this.state.data)){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
    }
    disabledSubmit=()=>{
        const {data}=this.state
        if(data.judul.trim()===""||data.id_spesialis.trim()===""){
            return true
        }
        return false
    }
    getsSpesialis=()=>{
        API("/adminweb/spesialis/gets_all_spesialis", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                spesialis:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //TAMBAH
    typeTambah=e=>{
        const target=e.target

        let value
        if(target.type==="checkbox"){
            value=target.checked
        }
        else{
            value=target.value
        }

        this.setState({
            data:update(this.state.data, {
                [target.name]:{$set:value}
            })
        })
    }

    render(){
        const {data, spesialis}=this.state

        return (
            <div className='row'>
                <div className='col-12'>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Judul</label>
                        <div className="input-icon col">
                            <input 
                                type="text" 
                                className="form-control"
                                value={data.judul}
                                name="judul"
                                onChange={this.typeTambah}
                            />
                            <small className="form-hint"></small>
                        </div>
                    </div>
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="checkbox" 
                            name="show_title"
                            checked={data.show_title} 
                            id="cb__tampilkan_judul"
                            onChange={this.typeTambah}
                        />
                        <label class="form-check-label" for="cb__tampilkan_judul">
                            Tampilkan Judul
                        </label>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Id Spesialis</label>
                        <div className="input-icon col">
                            <select 
                                class="form-select" 
                                name="id_spesialis"
                                value={data.id_spesialis}
                                onChange={this.typeTambah}
                            >
                                <option value="">-- Pilih Spesialis</option>
                                {spesialis.map(item=>(
                                    <option value={item.id_spesialis}>{item.nama_spesialis}</option>
                                ))}
                            </select>
                            <small className="form-hint">id spesialis digunakan untuk link ke halaman chat</small>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChatDokter