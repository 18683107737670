import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API, API_FORM } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, isUndefined, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit, FiUpload} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"
import CustomLink from "../../../component/custom_link"

class Banners extends React.Component{
    state={
        banners:[],
        tambah_banner:{
            is_open:false,
            judul:"",
            deskripsi:"",
            gambar:{},
            link_to:""
        },
        edit_banner:{
            is_open:false,
            id_banner:"",
            judul:"",
            deskripsi:"",
            gambar:{},
            link_to:""
        },
        hapus_banner:{
            is_open:false,
            id_banner:""
        },
        select_link:{
            is_open:false
        }
    }

    componentDidMount=()=>{
        this.getsBanner()
    }
    getsBanner=()=>{
        API("/adminweb/banners/gets_banner", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                banners:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //TAMBAH BANNER
    showTambahBanner=()=>{
        this.setState({
            tambah_banner:{
                is_open:true,
                judul:"",
                deskripsi:"",
                gambar:{},
                link_to:""
            }
        })
    }
    hideTambahBanner=()=>{
        this.setState({
            tambah_banner:{
                is_open:false,
                judul:"",
                deskripsi:"",
                gambar:{},
                link_to:""
            }
        })
    }
    typeTambahBanner=e=>{
        const target=e.target

        this.setState({
            tambah_banner:update(this.state.tambah_banner, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addBanner=e=>{
        e.preventDefault()

        API("/adminweb/banners/add_banner", {
            tokenID:access_token(),
            data:this.state.tambah_banner
        })
        .then(res=>{
            this.getsBanner()
            this.hideTambahBanner()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //EDIT BANNER
    showEditBanner=data=>{
        this.setState({
            edit_banner:{
                is_open:true,
                id_banner:data.id_banner,
                judul:data.judul,
                deskripsi:data.deskripsi,
                gambar:data.gambar,
                link_to:data.link_to
            }
        })
    }
    hideEditBanner=()=>{
        this.setState({
            edit_banner:{
                is_open:false,
                id_banner:"",
                judul:"",
                deskripsi:"",
                gambar:{},
                link_to:""
            }
        })
    }
    typeEditBanner=e=>{
        const target=e.target

        this.setState({
            edit_banner:update(this.state.edit_banner, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateBanner=e=>{
        e.preventDefault()

        API("/adminweb/banners/update_banner", {
            tokenID:access_token(),
            data:this.state.edit_banner
        })
        .then(res=>{
            this.getsBanner()
            this.hideEditBanner()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //HAPUS BANNER
    showHapusBanner=data=>{
        this.setState({
            hapus_banner:{
                is_open:true,
                id_banner:data.id_banner
            }
        })
    }
    hideHapusBanner=()=>{
        this.setState({
            hapus_banner:{
                is_open:false,
                id_banner:""
            }
        })
    }
    deleteBanner=()=>{
        API("/adminweb/banners/delete_banner", {
            tokenID:access_token(),
            data:this.state.hapus_banner
        })
        .then(res=>{
            this.getsBanner()
            this.hideHapusBanner()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //UPLOAD IMAGE
    uploadImage=(e, target_state="tambah_banner")=>{
        const files=e.target.files

        //initialize form data
        const form_data=new FormData()
        form_data.append("image", files[0])
        form_data.append("path", "/banners")

        //upload to server
        API_FORM("/adminweb/file_manager/upload_image", {
            tokenID:access_token(),
            data:form_data
        })
        .then(res=>{
            if(target_state==="tambah_banner"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        tambah_banner:{
                            ...prevState.tambah_banner,
                            gambar:res.data.data
                        }
                    }
                })
            }
            else if(target_state==="edit_banner"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        edit_banner:{
                            ...prevState.edit_banner,
                            gambar:res.data.data
                        }
                    }
                })
            }
            
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //SELECT LINK
    setLink=(data)=>{
        let target
        if(this.state.tambah_banner.is_open){
            target="tambah_banner"
        }
        else if(this.state.edit_banner.is_open){
            target="edit_banner"
        }

        this.setState(prevState=>{
            return {
                ...prevState,
                [target]:{
                    ...prevState[target],
                    link_to:data
                }
            }
        })
    }
    showSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:true
            }
        })
    }
    hideSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:false
            }
        })
    }

    render(){
        const {banners, tambah_banner, edit_banner, hapus_banner, select_link}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex mb-4">
                                        <button className="btn btn-primary" onClick={this.showTambahBanner}>
                                            <AiOutlinePlus/> Tambah Banner
                                        </button>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">Gambar</th>
                                                    <th class="border-0">Link To</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {banners.map((list, idx)=>(
                                                    <tr>
                                                        <td className="align-middle">{(idx+1)}</td>
                                                        <td><img src={BASE_URL+"/"+list.gambar.path} width="200"/></td>
                                                        <td>{list.link_to}</td>
                                                        <td>
                                                            <button className="btn btn-warning btn-sm" onClick={()=>this.showEditBanner(list)}>
                                                                Edit
                                                            </button>
                                                            <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusBanner(list)}>
                                                                Hapus
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH BANNER */}
                <Modal show={tambah_banner.is_open} onHide={this.hideTambahBanner}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Banner</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addBanner}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeTambahBanner}
                                value={tambah_banner.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <textarea
                                rows={3} 
                                className="form-control"
                                name="deskripsi"
                                onChange={this.typeTambahBanner}
                                value={tambah_banner.deskripsi}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Gambar</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e=>this.uploadImage(e, "tambah_banner")}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(tambah_banner.gambar.path)&&
                                    <img src={`${BASE_URL}/${tambah_banner.gambar.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={tambah_banner.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahBanner}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={isUndefined(tambah_banner.gambar.path)||tambah_banner.link_to.trim()===""}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL EDIT BANNER */}
                <Modal show={edit_banner.is_open} onHide={this.hideEditBanner}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Banner</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateBanner}>
                    <Modal.Body>
                    <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeEditBanner}
                                value={edit_banner.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <textarea
                                rows={3} 
                                className="form-control"
                                name="deskripsi"
                                onChange={this.typeEditBanner}
                                value={edit_banner.deskripsi}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Gambar</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e=>this.uploadImage(e, "edit_banner")}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(edit_banner.gambar.path)&&
                                    <img src={`${BASE_URL}/${edit_banner.gambar.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={edit_banner.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditBanner}>Batal</button>
                        <button type="submit" class="btn btn-info">Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_banner.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusBanner()}
                    deleteAction={()=>this.deleteBanner()}
                />

                {/* LINK NAVIGASI */}
                <CustomLink
                    is_open={select_link.is_open}
                    setLink={data=>this.setLink(data)}
                    closeSelectLink={()=>this.hideSelectLink()}
                />
            </>
        )
    }
}

export default navAuth(Banners, {
    title:"Banners Grid"
})