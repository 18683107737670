import React, { Component } from 'react'
import qs from "querystring"
import update from "immutability-helper"
import { API } from '../../../../config/api'
import { access_token } from '../../../../config/config'
import {AiOutlinePlus} from "react-icons/ai"
import { Modal } from 'react-bootstrap'
import { ConfirmDelete } from '../../../../component/ui/confirm'

export default class Provinsi extends Component {
    state={
        region:[],
        edit_region:{
            is_open:false,
            id:"",
            name:""
        },
        tambah_region:{
            is_open:false,
            regency_id:"0",
            province_id:"0",
            name:""
        },
        hapus_region:{
            is_open:false,
            id:""
        }
    }

    componentDidMount=()=>{
        this.getsRegion()
    }
    getsRegion=()=>{
        API("/adminweb/region/gets_provinsi", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                region:res.data.data
            })
        })
        .catch(err=>{
            if(err.response.status===401){
                this.props.history.push("/?status=token_expired")
            }
        })
    }

    //edit region
    showEditRegion=(data)=>{
        this.setState({
            edit_region:{
                is_open:true,
                id:data.id,
                name:data.name
            }
        })
    }
    hideEditRegion=()=>{
        this.setState({
            edit_region:{
                is_open:false,
                id:"",
                name:""
            }
        })
    }
    typeEdit=(e)=>{
        const target=e.target

        this.setState({
            edit_region:update(this.state.edit_region, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateRegion=(e)=>{
        e.preventDefault()

        API("/adminweb/region/update_region", {
            tokenID:access_token(),
            data:this.state.edit_region
        })
        .then(res=>{
            this.hideEditRegion()
            this.getsRegion()
        })
        .catch(err=>{
            if(err.response.status===401){
                this.props.history.push("/?status=token_expired")
            }
        })
    }

    //tambah region
    toggleTambahRegion=()=>{
        this.setState({
            tambah_region:{
                is_open:!this.state.tambah_region.is_open,
                regency_id:"0",
                province_id:"0",
                name:"",
            }
        })
    }
    typeTambah=(e)=>{
        const target=e.target

        this.setState({
            tambah_region:update(this.state.tambah_region, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addRegion=(e)=>{
        e.preventDefault()

        API("/adminweb/region/add_region", {
            tokenID:access_token(),
            data:this.state.tambah_region
        })
        .then(res=>{
            this.toggleTambahRegion()
            this.getsRegion()
        })
        .catch(err=>{
            if(err.response.status===401){
                this.props.history.push("/?status=token_expired")
            }
        })
    }

    //hapus region
    showHapusRegion=data=>{
        this.setState({
            hapus_region:{
                is_open:true,
                id:data.id
            }
        })
    }
    hideHapusRegion=()=>{
        this.setState({
            hapus_region:{
                is_open:false,
                id:""
            }
        })
    }
    deleteRegion=()=>{
        API("/adminweb/region/delete_region", {
            tokenID:access_token(),
            data:this.state.hapus_region
        })
        .then(res=>{
            this.hideHapusRegion()
            this.getsRegion()
        })
        .catch(err=>{
            if(err.response.status===401){
                this.props.history.push("/?status=token_expired")
            }
        })
    }


    render() {
        const {region, tambah_region, edit_region, hapus_region}=this.state

        return (
            <>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div>
                            <button className='btn btn-primary' onClick={this.toggleTambahRegion}>
                                <AiOutlinePlus/> Tambah Provinsi
                            </button>
                        </div>
                        <div class="table-responsive mt-3">
                            <table class="table table-centered table-nowrap mb-0 rounded">
                                <thead className='thead-light'>
                                    <tr>
                                        <th className='border-0 rounded-start'>Region</th>
                                        <th className='border-0 rounded-end' width="100">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {region.map(reg=>(
                                        <tr>
                                            <td>{reg.province_name}</td>
                                            <td>
                                                <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditRegion(reg)}>Edit</button>
                                                <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusRegion(reg)}>Hapus</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* MODAL EDIT PROVINSI */}
                <Modal show={edit_region.is_open} onHide={this.hideEditRegion}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Provinsi</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateRegion}>
                    <Modal.Body>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Provinsi</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="name"
                                onChange={this.typeEdit}
                                value={edit_region.name}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="mt-3">
                        <button 
                            type="button" 
                            class="btn btn-link text-gray me-auto" 
                            onClick={this.hideEditRegion}
                        >
                            Batal
                        </button>
                        <button 
                            type="submit" 
                            class="btn btn-info"
                            disabled={
                                edit_region.name.trim()===""
                            }
                        >
                            Save Changes
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL TAMBAH PROVINSI */}
                 <Modal show={tambah_region.is_open} onHide={this.toggleTambahRegion}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Provinsi</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addRegion}>
                    <Modal.Body>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Provinsi</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="name"
                                onChange={this.typeTambah}
                                value={tambah_region.name}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="mt-3">
                        <button 
                            type="button" 
                            class="btn btn-link text-gray me-auto" 
                            onClick={this.toggleTambahRegion}
                        >
                            Batal
                        </button>
                        <button 
                            type="submit" 
                            class="btn btn-info"
                            disabled={
                                tambah_region.name.trim()===""
                            }
                        >
                            Save Changes
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_region.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusRegion()}
                    deleteAction={()=>this.deleteRegion()}
                />
            </>
        )
    }
}
