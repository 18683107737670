import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app';

//css
import "react-toggle/style.css"
import 'react-toastify/dist/ReactToastify.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import "react-datepicker/dist/react-datepicker.css"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import "./styles/volt.css"
import "./styles/styles.css"

ReactDOM.render(
  <Router>
    <App/>
  </Router>,
  document.getElementById('root')
)