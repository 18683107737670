import React from 'react'
import classNames from "classnames"
import ContentLoader from 'react-content-loader'
import { akronim, BASE_URL, isUndefined } from '../../config/config'

export default function Avatar({data, circle=false}) {
    const get_avatar_url=(avatar_url)=>{
        let avatar
        if(isUndefined(avatar_url)) avatar=""
        else avatar=avatar_url

        if(avatar.search("./uploads/")===0){
            return BASE_URL+"/"+avatar
        }
        else{
            return avatar
        }
    }

    return (
        <>
            {isUndefined(data)?
                <ContentLoader 
                    viewBox="0 0 48 48" 
                    height={50} 
                    width={50}
                    backgroundColor="#eff2f8"
                    foregroundColor="#dfdfdf"
                >
                    <circle 
                        cx="24" cy="24" r="24"/>
                </ContentLoader>
            :
                <>
                    {data.avatar_url===""?
                        <>{akronim(data.nama_lengkap)}</>
                    :
                        <img 
                            src={get_avatar_url(data.avatar_url)} 
                            className={classNames({"rounded-circle":circle})}
                        />
                    }
                </>
            }
        </>
    )
}
