import axios from "axios";
import { BASE_URL, isUndefined } from "./config";


export const API=(url, content={})=>{
    let headers={'content-type':'application/json'}, data={}
    if(!isUndefined(content.tokenID)){
        headers={
            'content-type':'application/json',
            'Authorization':`Bearer ${content.tokenID}`
        }
    }
    if(!isUndefined(content.data)){
        data=content.data
    }

    return axios.post(BASE_URL+url, data, {
        headers:headers,
        timeout:30000
    })
}

export const API_FORM=(url, content={})=>{
    let headers={'content-type':'multipart/form-data'}, data=new FormData()
    if(!isUndefined(content.tokenID)){
        headers={
            'content-type':'multipart/form-data',
            'Authorization':`Bearer ${content.tokenID}`
        }
    }
    if(!isUndefined(content.data)){
        data=content.data
    }

    return axios.post(BASE_URL+url, data, {
        headers:headers,
        timeout:30000
    })
}

export const FETCH_DATA=(url, content={})=>{
    let headers={'content-type':'application/json'}, data={}
    if(!isUndefined(content.tokenID)){
        headers={
            'content-type':'application/json',
            'Authorization':`Bearer ${content.tokenID}`
        }
    }
    if(!isUndefined(content.data)){
        data=content.data
    }

    return fetch(BASE_URL+url, {
        method:"POST",
        body:data,
        headers:headers
    })
}