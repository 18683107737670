import React from 'react'
import update from "immutability-helper"
import { API, API_FORM } from '../../../../config/api'
import { access_token, isUndefined, remove_login_data, BASE_URL } from "../../../../config/config"
import {FiUpload} from "react-icons/fi"
import CustomLink from '../../../../component/custom_link'

class CekPilihan extends React.Component{
    state={
        data:{
            icon:{},
            judul:"",
            deskripsi:"",
            btn_text:"",
            btn_link:""
        },
        select_link:{
            is_open:false
        }
    }

    componentDidMount=()=>{
        if(this.props.action==="tambah_widget"){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
        else{
            const {data}=this.props
            this.setState({
                data:{
                    icon:data.icon,
                    judul:data.judul,
                    deskripsi:data.deskripsi,
                    btn_text:data.btn_text,
                    btn_link:data.btn_link
                }
            })
        }
    }
    componentDidUpdate=(prevProps, prevState)=>{
        if(JSON.stringify(prevState.data)!==JSON.stringify(this.state.data)){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
    }
    disabledSubmit=()=>{
        const {data}=this.state
        if(data.judul.trim()===""||data.deskripsi.trim()===""||data.btn_text.trim()===""||data.btn_link.trim()===""||isUndefined(data.icon.path)){
            return true
        }
        return false
    }

    //TAMBAH
    typeTambah=e=>{
        const target=e.target

        let value
        if(target.type==="checkbox"){
            value=target.checked
        }
        else{
            value=target.value
        }

        this.setState({
            data:update(this.state.data, {
                [target.name]:{$set:value}
            })
        })
    }

    //UPLOAD IMAGE
    uploadImage=(e)=>{
        const files=e.target.files

        //initialize form data
        const form_data=new FormData()
        form_data.append("image", files[0])
        form_data.append("path", "/banners")

        //upload to server
        API_FORM("/adminweb/file_manager/upload_image", {
            tokenID:access_token(),
            data:form_data
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    data:{
                        ...prevState.data,
                        icon:res.data.data
                    }
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //SELECT LINK
    setLink=(data)=>{
        this.setState(prevState=>{
            return {
                ...prevState,
                data:{
                    ...prevState.data,
                    btn_link:data
                }
            }
        })
    }
    showSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:true
            }
        })
    }
    hideSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:false
            }
        })
    }

    render(){
        const {data, select_link}=this.state

        return (
            <>
            <div className='row'>
                <div className='col-12'>
                    <div class="mb-4">
                        <label class="my-1 me-2" for="country">Icon</label>
                        <div className="w-100 d-grid grip-1">
                            <button 
                                className="btn btn-gray-200" 
                                type="button"
                                onClick={()=>this.inputElement.click()}
                            >
                                <FiUpload/> Upload Gambar
                            </button>
                            <input
                                ref={input=>this.inputElement=input}
                                type="file"
                                style={{display:"none"}}
                                accept=".png, .jpg, .jpeg"
                                onChange={e=>this.uploadImage(e)}
                            />
                        </div>
                        <div className="mt-3">
                            {!isUndefined(data.icon.path)&&
                                <img src={`${BASE_URL}/${data.icon.path}`} alt="" className="img-fluid"/>
                            }
                        </div>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Judul</label>
                        <div className="input-icon col">
                            <input 
                                type="text" 
                                className="form-control"
                                value={data.judul}
                                name="judul"
                                onChange={this.typeTambah}
                            />
                            <small className="form-hint"></small>
                        </div>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Deskripsi</label>
                        <div className="input-icon col">
                            <textarea
                                rows="3"
                                className="form-control"
                                value={data.deskripsi}
                                name="deskripsi"
                                onChange={this.typeTambah}
                            ></textarea>
                            <small className="form-hint"></small>
                        </div>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Button Text</label>
                        <div className="input-icon col">
                            <input 
                                type="text" 
                                className="form-control"
                                value={data.btn_text}
                                name="btn_text"
                                onChange={this.typeTambah}
                            />
                            <small className="form-hint"></small>
                        </div>
                    </div>
                    <div className="form-group mb-3 mt-4">
                        <label class="form-label">Button Link</label>
                        <div className="input-icon col">
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={data.btn_link}
                                    disabled
                                />
                            </div>
                            <small className="form-hint"></small>
                        </div>
                    </div>
                </div>
            </div>

            {/* LINK NAVIGASI */}
            <CustomLink
                is_open={select_link.is_open}
                setLink={data=>this.setLink(data)}
                closeSelectLink={()=>this.hideSelectLink()}
            />
            </>
        )
    }
}

export default CekPilihan