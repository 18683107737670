import moment from "moment"

export const datetime=date=>{
    return moment(date, "YYYY-MM-DD HH:mm:ss").format('MMMM Do, YYYY [at] HH:mm')
}
export const absensi_time=time=>{
    if(time==="00:00:00" || time===""){
        return ""
    }
    else{
        return moment(time, "HH:mm:ss").format("HH:mm")
    }
}
export const date=date=>{
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("MMM Do, YYYY")
}
export const time=date=>{
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm a")
}
export const date_raw=date=>{
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
}