import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import { AiOutlinePlus } from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"
import CustomLink from "../../../component/custom_link"

class Menu extends React.Component{
    state={
        menu:[],
        edit_menu:{
            is_open:false,
            id_menu:"",
            text:"",
            link_to:"",
            sub_menu:"0"
        },
        tambah_menu:{
            is_open:false,
            text:"",
            link_to:"",
            sub_menu:"0"
        },
        hapus_menu:{
            is_open:false,
            id_menu:""
        },
        select_link:{
            is_open:false
        }
    }

    componentDidMount=()=>{
        this.getsMenu()
    }
    getsMenu=()=>{
        API("/adminweb/menu/gets_menu", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                menu:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getsMenuParent=()=>{
        return this.state.menu.map(menu=>{
            return {
                id_menu:menu.id_menu,
                text:menu.text,
                link_to:menu.link_to
            }
        })
    }
    renderListMenu=(menu)=>{
        return (
            <li class="list-group-item border">
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                        {menu.text}<br/>
                        <span className="text-muted"><em>{menu.link_to}</em></span>
                    </span>
                    <div>
                        <button className="btn btn-warning btn-sm" onClick={()=>this.showEditMenu(menu)}>
                            Edit
                        </button>
                        <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusMenu(menu)}>
                            Hapus
                        </button>
                    </div>
                </div>
                {menu.dropdown.length>0&&
                    <ul class="list-group mt-2">
                        {menu.dropdown.map(sub=>this.renderListMenu(sub))}
                    </ul>
                }
            </li>
        )
    }

    //TAMBAH MENU
    showTambahMenu=()=>{
        this.setState({
            tambah_menu:{
                is_open:true,
                text:"",
                link_to:"",
                sub_menu:"0"
            }
        })
    }
    hideTambahMenu=()=>{
        this.setState({
            tambah_menu:{
                is_open:false,
                text:"",
                link_to:"",
                sub_menu:"0"
            }
        })
    }
    typeTambahMenu=e=>{
        const target=e.target

        this.setState({
            tambah_menu:update(this.state.tambah_menu, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addMenu=e=>{
        e.preventDefault()

        const {tambah_menu}=this.state
        API("/adminweb/menu/add_menu", {
            tokenID:access_token(),
            data:tambah_menu
        })
        .then(res=>{
            this.getsMenu()
            this.hideTambahMenu()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //EDIT MENU
    showEditMenu=data=>{
        this.setState({
            edit_menu:{
                is_open:true,
                id_menu:data.id_menu,
                text:data.text,
                link_to:data.link_to,
                sub_menu:data.sub_menu
            }
        })
    }
    hideEditMenu=()=>{
        this.setState({
            edit_menu:{
                is_open:false,
                id_menu:"",
                text:"",
                link_to:"",
                sub_menu:"0"
            }
        })
    }
    typeEditMenu=e=>{
        const target=e.target

        this.setState({
            edit_menu:update(this.state.edit_menu, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateMenu=e=>{
        e.preventDefault()

        API("/adminweb/menu/update_menu", {
            tokenID:access_token(),
            data:this.state.edit_menu
        })
        .then(res=>{
            this.getsMenu()
            this.hideEditMenu()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //HAPUS DATA
    showHapusMenu=data=>{
        this.setState({
            hapus_menu:{
                is_open:true,
                id_menu:data.id_menu
            }
        })
    }
    hideHapusMenu=()=>{
        this.setState({
            hapus_menu:{
                is_open:false,
                id_menu:""
            }
        })
    }
    deleteMenu=()=>{
        API("/adminweb/menu/delete_menu", {
            tokenID:access_token(),
            data:this.state.hapus_menu
        })
        .then(res=>{
            this.getsMenu()
            this.hideHapusMenu()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //SELECT LINK
    setLink=(data)=>{
        let target
        if(this.state.tambah_menu.is_open){
            target="tambah_menu"
        }
        else if(this.state.edit_menu.is_open){
            target="edit_menu"
        }

        this.setState(prevState=>{
            return {
                ...prevState,
                [target]:{
                    ...prevState[target],
                    link_to:data
                }
            }
        })
    }
    showSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:true
            }
        })
    }
    hideSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:false
            }
        })
    }

    render(){
        const {menu, tambah_menu, edit_menu, hapus_menu, select_link}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex flex-column flex-md-row justify-content-between mb-4">
                                        <button className="btn btn-primary" onClick={this.showTambahMenu}>
                                            <AiOutlinePlus/> Tambah Menu
                                        </button>
                                    </div>
                                    <div class="table-responsive">
                                        <ul class="list-group">
                                            {menu.map(nav=>this.renderListMenu(nav))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH MENU */}
                <Modal show={tambah_menu.is_open} onHide={this.hideTambahMenu}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Menu</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addMenu}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Text</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="text"
                                onChange={this.typeTambahMenu}
                                value={tambah_menu.text}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={tambah_menu.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Sub Menu</label>
                            <select 
                                class="form-select" 
                                name="sub_menu"
                                value={tambah_menu.sub_menu}
                                onChange={this.typeTambahMenu}
                            >
                                <option value="0">-- Top Level</option>
                                {this.getsMenuParent().map(par=>(
                                    <option value={par.id_menu}>{par.text}</option>
                                ))}
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahMenu}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={tambah_menu.text===""||tambah_menu.link_to===""}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL EDIT MENU */}
                <Modal show={edit_menu.is_open} onHide={this.hideEditMenu}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Puskesmas</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateMenu}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Text</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="text"
                                onChange={this.typeEditMenu}
                                value={edit_menu.text}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={edit_menu.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Sub Menu</label>
                            <select 
                                class="form-select" 
                                name="sub_menu"
                                value={edit_menu.sub_menu}
                                onChange={this.typeEditMenu}
                            >
                                <option value="0">-- Top Level</option>
                                {this.getsMenuParent().map(par=>{
                                    if(par.id_menu===edit_menu.id_menu) return ""

                                    return (
                                        <option value={par.id_menu}>{par.text}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditMenu}>Batal</button>
                        <button type="submit" class="btn btn-info">Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_menu.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusMenu()}
                    deleteAction={()=>this.deleteMenu()}
                />

                {/* LINK NAVIGASI */}
                <CustomLink
                    is_open={select_link.is_open}
                    setLink={data=>this.setLink(data)}
                    closeSelectLink={()=>this.hideSelectLink()}
                />
            </>
        )
    }
}

export default navAuth(Menu, {
    title:"Menu Navigasi"
})