
import React from "react"
import qs from 'querystring'
import { BASE_URL, access_token, remove_login_data, isUndefined } from "../../../config/config"
import { API, API_FORM } from "../../../config/api"
import update from 'immutability-helper'
import uniqid from "uniqid"
import { toast } from "react-toastify"
import {EditorState, convertFromRaw, convertToRaw, RichUtils} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Link } from "react-router-dom"
import navAuth from "../nav_auth"
import { Modal, Spinner } from "react-bootstrap"
import { AiOutlinePlus, AiOutlineTable } from "react-icons/ai"
import { FiUpload } from "react-icons/fi"
import { ConfirmDelete } from "../../../component/ui/confirm"
import TextareaAutosize from "react-textarea-autosize"


class AddQNA extends React.Component{
    state={
        judul:"",
        tanya_jawab:[],
        tambah_tanya_jawab:{
            is_open:false,
            tanya:"",
            jawab:""
        },
        edit_tanya_jawab:{
            is_open:false,
            qna:{},
            tanya:"",
            jawab:""
        },
        hapus_tanya_jawab:{
            is_open:false,
            qna:{}
        },
        is_adding:false
    }

    componentDidMount=()=>{
    }

    //ACTION
    typeInput=e=>{
        const target=e.target

        this.setState({
            [target.name]:target.value
        })
    }

    //TAMBAH TANYA JAWAB
    showTambahTJ=()=>{
        this.setState({
            tambah_tanya_jawab:{
                is_open:true,
                tanya:"",
                jawab:""
            }
        })
    }
    hideTambahTJ=()=>{
        this.setState({
            tambah_tanya_jawab:{
                is_open:false,
                tanya:"",
                jawab:""
            }
        })
    }
    typeTambahTJ=e=>{
        const target=e.target

        this.setState({
            tambah_tanya_jawab:update(this.state.tambah_tanya_jawab, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addTJ=e=>{
        e.preventDefault()
        
        const {tambah_tanya_jawab}=this.state
        let data={
            id:uniqid(),
            tanya:tambah_tanya_jawab.tanya,
            jawab:tambah_tanya_jawab.jawab
        }

        this.setState({
            tanya_jawab:this.state.tanya_jawab.concat([data])
        }, ()=>{
            this.hideTambahTJ()
        })
    }

    //EDIT TANYA JAWAB
    showEditTJ=data=>{
        this.setState({
            edit_tanya_jawab:{
                is_open:true,
                qna:data,
                tanya:data.tanya,
                jawab:data.jawab
            }
        })
    }
    hideEditTJ=()=>{
        this.setState({
            edit_tanya_jawab:{
                is_open:false,
                qna:{},
                tanya:"",
                jawab:""
            }
        })
    }
    typeEditTJ=e=>{
        const target=e.target
    
        this.setState({
            edit_tanya_jawab:update(this.state.edit_tanya_jawab, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateTJ=e=>{
        e.preventDefault()
        
        const {edit_tanya_jawab}=this.state
        const index=this.state.tanya_jawab.map(ph=>JSON.stringify(ph)).indexOf(JSON.stringify(this.state.edit_tanya_jawab.qna))
        let data={
            id:uniqid(),
            tanya:edit_tanya_jawab.tanya,
            jawab:edit_tanya_jawab.jawab
        }
    
        this.setState({
            tanya_jawab:update(this.state.tanya_jawab, {
                [index]:{$set:data}
            })
        }, ()=>{
            this.hideEditTJ()
        })
    }

    //HAPUS TANYA JAWAB
    showHapusTJ=data=>{
        this.setState({
            hapus_tanya_jawab:{
                is_open:true,
                qna:data
            }
        })
    }
    hideHapusTJ=()=>{
        this.setState({
            hapus_tanya_jawab:{
                is_open:false,
                qna:{}
            }
        })
    }
    delTJ=()=>{
        const tj=this.state.tanya_jawab.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_tanya_jawab.qna))

        this.setState({
            tanya_jawab:tj
        }, ()=>{
            this.hideHapusTJ()
        })
    }

    //SUBMIT
    addTanyaJawab=e=>{
        e.preventDefault()

        const data={
            judul:this.state.judul,
            tanya_jawab:this.state.tanya_jawab
        }

        this.setState({is_adding:true})
        API("/adminweb/tanya_jawab/add_qna", {
            tokenID:access_token(),
            data:data
        })
        .then(res=>{
            this.setState({
                judul:"",
                tanya_jawab:[]
            })
            toast.success("Berhasil menambahkan Tanya Jawab")
            this.setState({is_adding:false})
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    

    render(){
        const {
            judul,
            tanya_jawab,
            is_adding,
            tambah_tanya_jawab,
            edit_tanya_jawab,
            hapus_tanya_jawab

        }=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <form className="card" onSubmit={this.addTanyaJawab}>
                                <div className="card-body">
                                    <div className="form-group mb-3 mt-4">
                                        <label class="form-label">Judul</label>
                                        <div className="input-icon col">
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                value={judul}
                                                name="judul"
                                                onChange={this.typeInput}
                                            />
                                            <small className="form-hint"></small>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mt-4">
                                        <div class="form-label d-flex justify-content-between">
                                            <span className="fw-semibold">List Tanya Jawab</span>
                                            <button type="button" className="btn btn-info" onClick={this.showTambahTJ}><AiOutlinePlus/> Baru</button>
                                        </div>
                                        <div className="input-icon col">
                                            <div class="table-responsive">
                                                <table class="table table-centered table-nowrap mb-0 rounded">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th class="border-0 rounded-start" width="50">#</th>
                                                            <th class="border-0">Pertanyaan</th>
                                                            <th class="border-0 rounded-end p-0" width="90"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tanya_jawab.map((list, idx)=>(
                                                            <tr>
                                                                <td className="align-middle">{(idx+1)}</td>
                                                                <td>{list.tanya}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditTJ(list)}>
                                                                        Edit
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusTJ(list)}>
                                                                        Hapus
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                   <div className="d-flex justify-content-center">
                                        <button 
                                            type="submit" 
                                            class="btn btn-primary btn-width-min" 
                                            disabled={
                                                judul.trim()===""||
                                                tanya_jawab.length===0||
                                                is_adding
                                            }
                                        >
                                            {is_adding?
                                                <Spinner size="sm" variant="light" animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            :
                                                <>Save Changes</>
                                            }
                                            
                                        </button>
                                   </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* TAMBAH TANYA JAWAB */}
                <Modal show={tambah_tanya_jawab.is_open} onHide={this.hideTambahTJ}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Tanya Jawab</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addTJ}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Pertanyaan</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="tanya"
                                onChange={this.typeTambahTJ}
                                value={tambah_tanya_jawab.tanya}
                            />
                        </div>
                        
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <TextareaAutosize
                                minRows={3}
                                name="jawab"
                                onChange={this.typeTambahTJ}
                                value={tambah_tanya_jawab.jawab}
                                className="form-control"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahTJ}>Batal</button>
                        <button 
                            type="submit" 
                            class="btn btn-info" 
                            disabled={
                                tambah_tanya_jawab.tanya===""||
                                tambah_tanya_jawab.jawab===""
                            }
                        >
                            Tambahkan
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* EDIT TANYA JAWAB */}
                <Modal show={edit_tanya_jawab.is_open} onHide={this.hideEditTJ}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Tanya Jawab</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateTJ}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Pertanyaan</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="tanya"
                                onChange={this.typeEditTJ}
                                value={edit_tanya_jawab.tanya}
                            />
                        </div>
                        
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <TextareaAutosize
                                minRows={3}
                                name="jawab"
                                onChange={this.typeEditTJ}
                                value={edit_tanya_jawab.jawab}
                                className="form-control"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditTJ}>Batal</button>
                        <button 
                            type="submit" 
                            class="btn btn-info" 
                            disabled={
                                edit_tanya_jawab.tanya===""||
                                edit_tanya_jawab.jawab===""
                            }
                        >
                            Update
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* HAPUS TANYA JAWAB */}
                <ConfirmDelete
                    show={hapus_tanya_jawab.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusTJ()}
                    deleteAction={()=>this.delTJ()}
                    nested
                />
            </>
        )
    }
}


export default navAuth(AddQNA, {
    title:"Tambah Tanya Jawab"
})