import React from 'react'
import update from "immutability-helper"
import { API, API_FORM } from '../../../../config/api'
import { access_token, remove_login_data, BASE_URL, isUndefined } from "../../../../config/config"
import uniqid from "uniqid"
import { AiOutlinePlus } from 'react-icons/ai'
import { Modal } from 'react-bootstrap'
import { ConfirmDelete } from '../../../../component/ui/confirm'
import { FiUpload } from 'react-icons/fi'
import CustomLink from '../../../../component/custom_link'

class BannerStatic extends React.Component{
    state={
        data:{
            judul:"",
            show_title:true,
            banner_static:[]
        },
        tambah_banner:{
            is_open:false,
            judul:"",
            deskripsi:"",
            gambar:{},
            link_to:""
        },
        edit_banner:{
            is_open:false,
            data:{},
            id_banner:"",
            judul:"",
            deskripsi:"",
            gambar:{},
            link_to:""
        },
        hapus_banner:{
            is_open:false,
            data:{}
        },
        select_link:{
            is_open:false
        }
    }

    componentDidMount=()=>{
        if(this.props.action==="tambah_widget"){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
        else{
            const {data}=this.props
            this.setState({
                data:{
                    judul:data.judul,
                    show_title:data.show_title,
                    banner_static:data.banner_static
                }
            })
        }
    }
    componentDidUpdate=(prevProps, prevState)=>{
        if(JSON.stringify(prevState.data)!==JSON.stringify(this.state.data)){
            this.props.setData(this.state.data, this.disabledSubmit())
        }
    }
    disabledSubmit=()=>{
        const {data}=this.state
        if(data.judul.trim()===""||data.banner_static.length<1){
            return true
        }
        return false
    }

    //TAMBAH
    typeTambah=e=>{
        const target=e.target

        let value
        if(target.type==="checkbox"){
            value=target.checked
        }
        else{
            value=target.value
        }

        this.setState({
            data:update(this.state.data, {
                [target.name]:{$set:value}
            })
        })
    }

    //BANNER
    //tambah
    showTambahBanner=()=>{
        this.setState({
            tambah_banner:{
                is_open:true,
                judul:"",
                deskripsi:"",
                gambar:{},
                link_to:""
            }
        })
    }
    hideTambahBanner=()=>{
        this.setState({
            tambah_banner:{
                is_open:false,
                judul:"",
                deskripsi:"",
                gambar:{},
                link_to:""
            }
        })
    }
    typeTambahBanner=e=>{
        const target=e.target

        this.setState({
            tambah_banner:update(this.state.tambah_banner, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addBanner=e=>{
        e.preventDefault()

        const {tambah_banner}=this.state
        const banner={
            id_banner:uniqid(),
            judul:tambah_banner.judul,
            deskripsi:tambah_banner.deskripsi,
            gambar:tambah_banner.gambar,
            link_to:tambah_banner.link_to
        }

        this.setState({
            data:update(this.state.data, {
                banner_static:{$set:[banner].concat(this.state.data.banner_static)}
            })
        }, ()=>{
            this.hideTambahBanner()
        })
    }
    //edit
    showEditBanner=data=>{
        this.setState({
            edit_banner:{
                is_open:true,
                data:data,
                id_banner:data.id_banner,
                judul:data.judul,
                deskripsi:data.deskripsi,
                gambar:data.gambar,
                link_to:data.link_to
            }
        })
    }
    hideEditBanner=()=>{
        this.setState({
            edit_banner:{
                is_open:false,
                data:{},
                id_banner:"",
                judul:"",
                deskripsi:"",
                gambar:{},
                link_to:""
            }
        })
    }
    typeEditBanner=e=>{
        const target=e.target

        this.setState({
            edit_banner:update(this.state.edit_banner, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateBanner=e=>{
        e.preventDefault()

        const {edit_banner}=this.state
        const index=this.state.data.banner_static.map(bs=>JSON.stringify(bs)).indexOf(JSON.stringify(edit_banner.data))
        const banner={
            id_banner:edit_banner.id_banner,
            judul:edit_banner.judul,
            deskripsi:edit_banner.deskripsi,
            gambar:edit_banner.gambar,
            link_to:edit_banner.link_to
        }

        this.setState({
            data:update(this.state.data, {
                banner_static:{
                    [index]:{$set:banner}
                }
            })
        }, ()=>{
            this.hideEditBanner()
        })
    }
    //hapus
    showHapusBanner=data=>{
        this.setState({
            hapus_banner:{
                is_open:true,
                data:data
            }
        })
    }
    hideHapusBanner=()=>{
        this.setState({
            hapus_banner:{
                is_open:false,
                data:{}
            }
        })
    }
    deleteBanner=()=>{
        const banner_static=this.state.data.banner_static.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_banner.data))

        this.setState({
            data:update(this.state.data, {
                banner_static:{$set:banner_static}
            })
        }, ()=>{
            this.hideHapusBanner()
        })
    }

    //UPLOAD IMAGE
    uploadImage=(e, target_state="tambah_banner")=>{
        const files=e.target.files

        //initialize form data
        const form_data=new FormData()
        form_data.append("image", files[0])
        form_data.append("path", "/banners")

        //upload to server
        API_FORM("/adminweb/file_manager/upload_image", {
            tokenID:access_token(),
            data:form_data
        })
        .then(res=>{
            if(target_state==="tambah_banner"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        tambah_banner:{
                            ...prevState.tambah_banner,
                            gambar:res.data.data
                        }
                    }
                })
            }
            else if(target_state==="edit_banner"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        edit_banner:{
                            ...prevState.edit_banner,
                            gambar:res.data.data
                        }
                    }
                })
            }
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //SELECT LINK
    setLink=(data)=>{
        let target
        if(this.state.tambah_banner.is_open){
            target="tambah_banner"
        }
        else if(this.state.edit_banner.is_open){
            target="edit_banner"
        }

        this.setState(prevState=>{
            return {
                ...prevState,
                [target]:{
                    ...prevState[target],
                    link_to:data
                }
            }
        })
    }
    showSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:true
            }
        })
    }
    hideSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:false
            }
        })
    }

    render(){
        const {data, tambah_banner, edit_banner, hapus_banner, select_link}=this.state

        return (
            <>
                <div className='row'>
                    <div className='col-12'>
                        <div className="form-group mb-3 mt-4">
                            <label class="form-label">Judul</label>
                            <div className="input-icon col">
                                <input 
                                    type="text" 
                                    className="form-control"
                                    value={data.judul}
                                    name="judul"
                                    onChange={this.typeTambah}
                                />
                                <small className="form-hint"></small>
                            </div>
                        </div>
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                name="show_title"
                                checked={data.show_title} 
                                id="cb__tampilkan_judul"
                                onChange={this.typeTambah}
                            />
                            <label class="form-check-label" for="cb__tampilkan_judul">
                                Tampilkan Judul
                            </label>
                        </div>
                        <div className="form-group mb-3 mt-4">
                            <div class="form-label d-flex justify-content-between">
                                <span className="fw-semibold">Banner static</span>
                                <button type="button" className="btn btn-info" onClick={this.showTambahBanner}><AiOutlinePlus/> Baru</button>
                            </div>
                            <div className="input-icon col">
                                <div class="table-responsive">
                                    <table class="table table-centered table-nowrap mb-0 rounded">
                                        <thead class="thead-light">
                                            <tr>
                                                <th class="border-0 rounded-start" width="50">#</th>
                                                <th class="border-0">Gambar</th>
                                                <th class="border-0">Link To</th>
                                                <th class="border-0 rounded-end p-0" width="90"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.banner_static.map((list, idx)=>(
                                                <tr>
                                                    <td className="align-middle">{(idx+1)}</td>
                                                    <td><img src={BASE_URL+"/"+list.gambar.path} width="200"/></td>
                                                    <td>{list.link_to}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditBanner(list)}>
                                                            Edit
                                                        </button>
                                                        <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusBanner(list)}>
                                                            Hapus
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* BANNER */}
                <Modal show={tambah_banner.is_open} onHide={this.hideTambahBanner} backdrop="static" backdropClassName='backdrop-nested'>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Banner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeTambahBanner}
                                value={tambah_banner.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <textarea
                                rows={3} 
                                className="form-control"
                                name="deskripsi"
                                onChange={this.typeTambahBanner}
                                value={tambah_banner.deskripsi}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Gambar</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e=>this.uploadImage(e, "tambah_banner")}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(tambah_banner.gambar.path)&&
                                    <img src={`${BASE_URL}/${tambah_banner.gambar.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={tambah_banner.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahBanner}>Batal</button>
                        <button 
                            type="button" 
                            class="btn btn-info" 
                            disabled={isUndefined(tambah_banner.gambar.path)||tambah_banner.link_to.trim()===""}
                            onClick={this.addBanner}
                        >
                            Save Changes
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={edit_banner.is_open} onHide={this.hideEditBanner} backdrop="static" backdropClassName='backdrop-nested'>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Kategori</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeEditBanner}
                                value={edit_banner.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <textarea
                                rows={3} 
                                className="form-control"
                                name="deskripsi"
                                onChange={this.typeEditBanner}
                                value={edit_banner.deskripsi}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Gambar</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e=>this.uploadImage(e, "edit_banner")}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(edit_banner.gambar.path)&&
                                    <img src={`${BASE_URL}/${edit_banner.gambar.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={edit_banner.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditBanner}>Batal</button>
                        <button type="button" class="btn btn-info" onClick={this.updateBanner}>Save Changes</button>
                    </Modal.Footer>
                </Modal>
                <ConfirmDelete
                    show={hapus_banner.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusBanner()}
                    deleteAction={()=>this.deleteBanner()}
                    nested
                />

                {/* LINK NAVIGASI */}
                <CustomLink
                    is_open={select_link.is_open}
                    setLink={data=>this.setLink(data)}
                    closeSelectLink={()=>this.hideSelectLink()}
                />
            </>
        )
    }
}

export default BannerStatic