import React from "react"
import update from "immutability-helper"
import {Modal} from "react-bootstrap"
import { API } from "../config/api"
import {access_token, remove_login_data, login_data, removeParam, generateParams} from "../config/config"

class CustomLink extends React.Component{
    state={
        tipe_navigasi:[
            {name:"Post", value:"post"},
            {name:"Kategori", value:"kategori"},
            {name:"Layanan", value:"layanan"},
            {name:"Cek Kesehatan", value:"cek_kesehatan"},
            {name:"Tanya Jawab", value:"tanya_jawab"},
            {name:"Link Lainnya", value:"more_link"},
            {name:"External Link", value:"external_link"}
        ],
        select_link:[],
        spesialis:[],
        tambah:{
            tipe:"external_link",
            link_to:"",
            params:[]
        }
    }

    componentDidMount=()=>{
        this.getsSpesialis()
    }


    //tambah
    typeTambah=(e)=>{
        const target=e.target


        this.setState(prevState=>{
            return {
                ...prevState,
                tambah:{
                    ...prevState.tambah,
                    [target.name]:target.value
                }
            }
        }, ()=>{
            if(target.name==="tipe"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        tambah:{
                            ...prevState.tambah,
                            link_to:""
                        }
                    }
                })

                if(target.value==="post"){
                    this.getPosts()
                }
                else if(target.value==="kategori"){
                    this.getCategories()
                }
                else if(target.value==="layanan"){
                    this.getsLayanan()
                }
                else if(target.value==="cek_kesehatan"){
                    this.getsQuest()
                }
                else if(target.value==="tanya_jawab"){
                    this.getsQNA()
                }
                else if(target.value==="more_link"){
                    this.getsMoreLink()
                }
            }
            if(target.name!=="params"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        tambah:{
                            ...prevState.tambah,
                            params:[]
                        }
                    }
                })
            }
        })
    }
    closeSelectLink=()=>{
        this.setState({
            tambah:{
                tipe:"external_link",
                link_to:""
            }
        }, ()=>{
            this.props.closeSelectLink()
        })
    }
    setLink=(e)=>{
        e.preventDefault()
        this.closeSelectLink()
        this.props.setLink(this.state.tambah.link_to.concat(generateParams(this.state.tambah.params)))
    }
    typeTambahParam=e=>{
        const target=e.target

        let data
        if(target.value===""){
            data=this.state.tambah.params.filter(pr=>pr.key!==target.name)
        }
        else{
            data=this.state.tambah.params.concat([{
                key:target.name,
                value:target.value
            }])
        }

        this.typeTambah({target:{name:"params", value:data}})
    }

    //api type
    getPosts=()=>{
        API("/adminweb/navigasi/gets_post", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    select_link:res.data.data,
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getCategories=()=>{
        const user=login_data()

        API("/adminweb/navigasi/gets_categorie", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    select_link:res.data.data
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getsLayanan=()=>{
        const user=login_data()

        API("/adminweb/navigasi/gets_layanan", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    select_link:res.data.data
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getsQuest=()=>{
        const user=login_data()

        API("/adminweb/navigasi/gets_quest", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    select_link:res.data.data
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getsQNA=()=>{
        const user=login_data()

        API("/adminweb/navigasi/gets_qna", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    select_link:res.data.data
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getsSpesialis=()=>{
        const user=login_data()

        API("/adminweb/spesialis/gets_all_spesialis", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    spesialis:res.data.data
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getsMoreLink=()=>{
        const user=login_data()

        API("/adminweb/navigasi/gets_more_link", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    select_link:res.data.data
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    render(){
        const {is_open}=this.props
        const {select_link, tambah, tipe_navigasi, spesialis}=this.state

        return (
            <Modal show={is_open} onHide={this.closeSelectLink} backdrop="static" backdropClassName='backdrop-nested-2' className="modal-nested-2">
                <Modal.Header closeButton>Pilih Link Navigasi</Modal.Header>
                <Modal.Body>
                    <div class="form-group mb-3 row">
                        <label class="form-label col-3 col-form-label">Tipe Navigasi</label>
                        <div class="col">
                            <select class="form-select" name="tipe" value={tambah.tipe} onChange={this.typeTambah}>
                                {tipe_navigasi.map(tipe=>(
                                    <option value={tipe.value}>{tipe.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {tambah.tipe!=="external_link"&&
                        <div class="form-group mb-3 row">
                            <label class="form-label col-3 col-form-label">Navigasi Ke</label>
                            <div class="col">
                                <select class="form-select" name="link_to" value={tambah.link_to} onChange={this.typeTambah}>
                                    <option value="">-- pilih link</option>
                                    {select_link.map(link=>(
                                        <option value={link.value}>{link.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    }
                    {tambah.tipe==="external_link"&&
                        <div class="form-group mb-3 row">
                            <label class="form-label col-3 col-form-label">Navigasi Ke</label>
                            <div class="col">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    value={tambah.link_to} 
                                    name="link_to" 
                                    onChange={this.typeTambah}
                                    placeholder="https://"
                                />
                            </div>
                        </div>
                    }

                    {/* PARAMETER */}
                    {tambah.link_to.search("/dashboard/chat_message")>=0&&
                        <div class="form-group mb-3 row">
                            <label class="form-label col-3 col-form-label">Parameter</label>
                            <div class="col">
                                <div class="input-group">
                                    <label class="input-group-text mb-0">Spesialis</label>
                                    <select class="form-select" name="id_spesialis" onChange={this.typeTambahParam}>
                                        <option value="">-- [Kosong]</option>
                                        {spesialis.map(link=>(
                                            <option value={link.id_spesialis}>{link.nama_spesialis}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                    {tambah.link_to.search("/dashboard/pemeriksaan")>=0&&
                        <div class="form-group mb-3 row">
                            <label class="form-label col-3 col-form-label">Parameter</label>
                            <div class="col">
                                <div class="input-group">
                                    <label class="input-group-text mb-0">Tipe Pemeriksaan</label>
                                    <select class="form-select" name="tipe_pemeriksaan" onChange={this.typeTambahParam}>
                                        <option value="">-- [Kosong]</option>
                                        <option value="anc">ANC</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        type="button" 
                        class="btn btn-link link-secondary" 
                        onClick={this.closeSelectLink}
                    >
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary ml-auto" onClick={this.setLink} disabled={!this.props.allowEmpty&&tambah.link_to===""}>
                        Gunakan Link
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}
CustomLink.defaultProps={
    allowEmpty:false
}

export default CustomLink