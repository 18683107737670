import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import {ConfirmDelete} from "../../../component/ui/confirm"
import {toast} from "react-toastify"

class Dokter extends React.Component{
    state={
        puskesmas:[],
        spesialis:[],
        users:[],
        filter:{
            page:1,
            per_page:10,
            count_page:0,
            count_data:0
        },
        edit_user:{
            is_open:false,
            id_user:"",
            status:"unverified"
        },
        tambah_dokter:{
            is_open:false,
            nama_lengkap:"",
            username:"",
            password:"",
            no_str_aktif:"",
            no_sip_aktif:"",
            id_puskesmas:"",
            id_spesialis:""
        },
        hapus_user:{
            is_open:false,
            id_user:""
        }
    }

    componentDidMount=()=>{
        this.getListDokter()
        this.getListPuskesmas()
        this.getListSpesialis()
    }
    getListDokter=()=>{
        API("/adminweb/dokter/gets_user", {
            tokenID:access_token(),
            data:{
                filter:this.state.filter
            }
        })
        .then(res=>{
            this.setState({
                users:res.data.data,
                filter:update(this.state.filter, {
                    count_page:{$set:res.data.count_page},
                    count_data:{$set:res.data.count_data}
                })
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getListPuskesmas=()=>{
        API("/adminweb/puskesmas/gets_puskesmas_group", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                puskesmas:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getListSpesialis=()=>{
        API("/adminweb/spesialis/gets_all_spesialis", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState({
                spesialis:res.data.data
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    getUserStatus=status=>{
        switch(status){
            case "active":
                return <span className="badge bg-success">Aktif</span>
            break;
            case "unverified":
                return <span className="badge bg-warning text-dark">Belum Diverifikasi</span>
            break;
            case "suspended":
                return <span className="badge bg-danger">Disuspend</span>
            break;
        }
    }
    goToPage=page=>{
        this.setState({
            filter:update(this.state.filter, {
                page:{$set:page}
            })
        }, ()=>{
            this.getListDokter()
        })
    }

    //EDIT USER
    showEditUser=data=>{
        this.setState({
            edit_user:{
                is_open:true,
                id_user:data.id_user,
                status:data.status
            }
        })
    }
    hideEditUser=()=>{
        this.setState({
            edit_user:{
                is_open:false,
                id_user:"",
                status:""
            }
        })
    }
    typeEditUser=e=>{
        const target=e.target

        this.setState({
            edit_user:update(this.state.edit_user, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateDokter=e=>{
        e.preventDefault()

        const {edit_user}=this.state
        API("/adminweb/dokter/update_dokter", {
            tokenID:access_token(),
            data:edit_user
        })
        .then(res=>{
            this.getListDokter()
            this.hideEditUser()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }   

    //TAMBAH DOKTER
    toggleTambahDokter=()=>{
        this.setState({
            tambah_dokter:{
                is_open:!this.state.tambah_dokter.is_open,
                nama_lengkap:"",
                username:"",
                password:"",
                no_str_aktif:"",
                no_sip_aktif:"",
                id_puskesmas:"",
                id_spesialis:""
            }
        })
    }
    typeTambahDokter=e=>{
        const target=e.target
        
        this.setState(prevState=>{
            return {
                ...prevState,
                tambah_dokter:{
                    ...prevState.tambah_dokter,
                    [target.name]:target.value
                }
            }
        })
    }
    addDokter=e=>{
        e.preventDefault()

        const {tambah_dokter}=this.state
        API("/adminweb/dokter/add_dokter", {
            tokenID:access_token(),
            data:tambah_dokter
        })
        .then(res=>{
            this.getListDokter()
            this.toggleTambahDokter()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
                case "ERR_USERNAME_FOUND":
                    toast.warn("Username telah digunakan!")
                break;
            }
        })
    }

    //HAPUS USER
    showHapusUser=data=>{
        this.setState({
            hapus_user:{
                is_open:true,
                id_user:data.id_user
            }
        })
    }
    hideHapusUser=()=>{
        this.setState({
            hapus_user:{
                is_open:false,
                id_user:""
            }
        })
    }
    deleteUser=()=>{
        API("/adminweb/dokter/delete_dokter", {
            tokenID:access_token(),
            data:this.state.hapus_user
        })
        .then(res=>{
            this.hideHapusUser()
            this.getListDokter()
        })
        .catch(err=>{
            if(err.response.status===401){
                this.props.history.push("/?status=token_expired")
            }
        })
    }

    render(){
        const {filter, users, edit_user, tambah_dokter, spesialis, puskesmas, hapus_user}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex mb-4">
                                        <button className="btn btn-primary" onClick={this.toggleTambahDokter}>
                                            <AiOutlinePlus/> Tambah Dokter
                                        </button>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">User/Pengguna</th>
                                                    <th class="border-0">E-Mail</th>
                                                    <th class="border-0">Bergabung pada</th>
                                                    <th class="border-0">Status</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((list, idx)=>(
                                                    <tr>
                                                            <td className="align-middle">{(idx+1)+((filter.page-1)*filter.per_page)}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="avatar">
                                                                            <Avatar 
                                                                                data={list}
                                                                                circle
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <span className="fw-semibold text-capitalize ms-2">{list.nama_lengkap}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a className="link-dark" href={`mailto:${list.email}`} target="_blank" style={{textDecoration:"underline"}}>
                                                                    {list.email}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {datetime(list.created_at)}
                                                            </td>
                                                            <td>
                                                                {this.getUserStatus(list.status)}
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-warning btn-sm" onClick={()=>this.showEditUser(list)}>
                                                                    Edit
                                                                </button>
                                                                <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusUser(list)}>
                                                                    Hapus
                                                                </button>
                                                            </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-4">
                                        <div className="d-flex flex-column">
                                            <div>Halaman {filter.page} dari {filter.count_page} Halaman</div>
                                            <div>Total Data : <span class="fw-bold fs-6">{filter.count_data}</span></div>
                                        </div>
                                        <div>
                                            <button 
                                                className="btn btn-gray-200" 
                                                disabled={filter.page<=1}
                                                onClick={()=>this.goToPage(filter.page-1)}
                                            >
                                                Sebelumnya
                                            </button>
                                            <button 
                                                className="btn btn-gray-200 ms-2" 
                                                disabled={filter.page>=filter.count_page}
                                                onClick={()=>this.goToPage(filter.page+1)}
                                            >
                                                Selanjutnya
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH DOKTER */}
                <Modal show={tambah_dokter.is_open} onHide={this.toggleTambahDokter}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Dokter</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addDokter}>
                    <Modal.Body>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Nama Lengkap</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_lengkap"
                                onChange={this.typeTambahDokter}
                                value={tambah_dokter.nama_lengkap}
                            />
                        </div>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Username</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="username"
                                onChange={this.typeTambahDokter}
                                value={tambah_dokter.username}
                            />
                        </div>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Password</label>
                            <input 
                                type="password" 
                                className="form-control"
                                name="password"
                                onChange={this.typeTambahDokter}
                                value={tambah_dokter.password}
                            />
                        </div>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">No. STR Aktif</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="no_str_aktif"
                                onChange={this.typeTambahDokter}
                                value={tambah_dokter.no_str_aktif}
                            />
                        </div>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">No. SIP Aktif</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="no_sip_aktif"
                                onChange={this.typeTambahDokter}
                                value={tambah_dokter.no_sip_aktif}
                            />
                        </div>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Puskesmas</label>
                            <select 
                                class="form-select" 
                                name="id_puskesmas"
                                value={tambah_dokter.id_puskesmas}
                                onChange={this.typeTambahDokter}
                            >
                                <option value="">-- Pilih Puskesmas</option>
                                {puskesmas.map(pus=>(
                                    <optgroup label={pus.regency_name}>
                                        {pus.puskesmas.map(item=>(
                                            <option value={item.id_puskesmas}>{item.nama_puskesmas}</option>
                                        ))}
                                    </optgroup>
                                ))}
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="my-1 me-2" for="country">Spesialis</label>
                            <select 
                                class="form-select" 
                                name="id_spesialis"
                                value={tambah_dokter.id_spesialis}
                                onChange={this.typeTambahDokter}
                            >
                                <option value="">-- Pilih Spesialis</option>
                                {spesialis.map(user=>(
                                    <option value={user.id_spesialis}>{user.nama_spesialis}</option>
                                ))}
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="mt-3">
                        <button 
                            type="button" 
                            class="btn btn-link text-gray me-auto" 
                            onClick={this.toggleTambahDokter}
                        >
                            Batal
                        </button>
                        <button 
                            type="submit" 
                            class="btn btn-info"
                            disabled={
                                tambah_dokter.id_spesialis.trim()===""||
                                tambah_dokter.id_puskesmas.trim()===""||
                                tambah_dokter.no_sip_aktif.trim()===""||
                                tambah_dokter.nama_lengkap.trim()===""||
                                tambah_dokter.username.trim()===""||
                                tambah_dokter.password.trim()===""
                            }
                        >
                            Save Changes
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL EDIT STATUS */}
                <Modal show={edit_user.is_open} onHide={this.hideEditUser}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Dokter</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateDokter}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Status</label>
                            <select 
                                class="form-select" 
                                name="status"
                                value={edit_user.status}
                                onChange={this.typeEditUser}
                            >
                                <option value="active">Aktif</option>
                                <option value="unverified">Belum Diverifikasi</option>
                                <option value="suspended">Disuspend</option>
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditUser}>Batal</button>
                        <button type="submit" class="btn btn-info">Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_user.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusUser()}
                    deleteAction={()=>this.deleteUser()}
                />
            </>
        )
    }
}

export default navAuth(Dokter, {
    title:"Manajemen Dokter"
})