import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"
import { Link } from "react-router-dom"

class Posts extends React.Component{
    state={
        posts:[],
        filter:{
            page:1,
            per_page:10,
            count_page:0,
            count_data:0
        },
        hapus_post:{
            is_open:false,
            id_post:""
        }
    }

    componentDidMount=()=>{
        this.getsPost()
    }
    getsPost=()=>{
        API("/adminweb/posts/gets_post", {
            tokenID:access_token(),
            data:{
                filter:this.state.filter
            }
        })
        .then(res=>{
            this.setState({
                posts:res.data.data,
                filter:update(this.state.filter, {
                    count_page:{$set:res.data.count_page},
                    count_data:{$set:res.data.count_data}
                })
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    goToPage=page=>{
        this.setState({
            filter:update(this.state.filter, {
                page:{$set:page}
            })
        }, ()=>{
            this.getsPost()
        })
    }

    //HAPUS POST
    showHapusPost=data=>{
        this.setState({
            hapus_post:{
                is_open:true,
                id_post:data.id_post
            }
        })
    }
    hideHapusPost=()=>{
        this.setState({
            hapus_post:{
                is_open:false,
                id_post:""
            }
        })
    }
    deletePost=()=>{
        API("/adminweb/posts/delete_post", {
            tokenID:access_token(),
            data:this.state.hapus_post
        })
        .then(res=>{
            this.getsPost()
            this.hideHapusPost()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    render(){
        const {filter, posts, hapus_post}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex mb-4">
                                        <Link to="/posts/add" className="btn btn-primary">
                                            <AiOutlinePlus/> Tambah Post
                                        </Link>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">Judul</th>
                                                    <th class="border-0">Tags</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {posts.map((list, idx)=>(
                                                    <tr>
                                                        <td className="align-middle">{(idx+1)+((filter.page-1)*filter.per_page)}</td>
                                                        <td>{list.judul}</td>
                                                        <td>{list.tags}</td>
                                                        <td>
                                                            <Link to={`/posts/edit/${list.id_post}`} className="btn btn-warning btn-sm">
                                                                Edit
                                                            </Link>
                                                            <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusPost(list)}>
                                                                Hapus
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-4">
                                        <div className="d-flex flex-column">
                                            <div>Halaman {filter.page} dari {filter.count_page} Halaman</div>
                                            <div>Total Data : <span class="fw-bold fs-6">{filter.count_data}</span></div>
                                        </div>
                                        <div>
                                            <button 
                                                className="btn btn-gray-700" 
                                                disabled={filter.page<=1}
                                                onClick={()=>this.goToPage(filter.page-1)}
                                            >
                                                Sebelumnya
                                            </button>
                                            <button 
                                                className="btn btn-gray-700 ms-2" 
                                                disabled={filter.page>=filter.count_page}
                                                onClick={()=>this.goToPage(filter.page+1)}
                                            >
                                                Selanjutnya
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_post.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusPost()}
                    deleteAction={()=>this.deletePost()}
                />
            </>
        )
    }
}

export default navAuth(Posts, {
    title:"Manajemen Post/Artikel"
})