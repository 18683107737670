
import React from "react"
import qs from 'querystring'
import { BASE_URL, access_token, remove_login_data, isUndefined } from "../../../config/config"
import { API, API_FORM } from "../../../config/api"
import update from 'immutability-helper'
import uniqid from "uniqid"
import { toast } from "react-toastify"
import {EditorState, convertFromRaw, convertToRaw, RichUtils} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Link } from "react-router-dom"
import navAuth from "../nav_auth"
import { Modal, Spinner } from "react-bootstrap"
import { AiOutlinePlus } from "react-icons/ai"
import { FiUpload } from "react-icons/fi"
import { ConfirmDelete } from "../../../component/ui/confirm"

//WIDGET
import ChatDokter from "./excerpt/chat_dokter"
import Jumbotron from "./excerpt/jumbotron"
import BannerStatic from "./excerpt/banner_static"
import LinkIcon from "./excerpt/link_icon"
import CustomLink from "../../../component/custom_link"

class AddLayanan extends React.Component{
    state={
        kategori:[],
        tipe_widget:[
            {id_widget:"chat_dokter", nama_widget:"Chat ke Dokter", screenshot:"/img/widgets/chat_dokter.jpg"},
            {id_widget:"jumbotron", nama_widget:"Jumbotron", screenshot:"/img/widgets/jumbotron.jpg"},
            {id_widget:"banner_statis", nama_widget:"Banner Statis", screenshot:"/img/widgets/banner_statis.jpg"},
            {id_widget:"link_icon", nama_widget:"Link Icon", screenshot:"/img/widgets/link_icon.jpg"}
        ],
        nama_layanan:"",
        banner_static:[],
        id_kategori:"",
        widgets:[],
        tambah_banner:{
            is_open:false,
            judul:"",
            deskripsi:"",
            gambar:{},
            link_to:""
        },
        edit_banner:{
            is_open:false,
            data:{},
            id_banner:"",
            judul:"",
            deskripsi:"",
            gambar:{},
            link_to:""
        },
        hapus_banner:{
            is_open:false,
            data:{}
        },
        tambah_widget:{
            is_open:false,
            tipe_widget:"",
            data:{},
            disabled:true
        },
        edit_widget:{
            is_open:false,
            widget:{},
            id_widget:"",
            tipe_widget:"",
            data:{},
            disabled:true
        },
        hapus_widget:{
            is_open:false,
            widget:{}
        },
        is_adding:false,
        select_link:{
            is_open:false
        }
    }

    componentDidMount=()=>{
        this.getsKategori()
    }
    getsKategori=()=>{
        API("/adminweb/kategori/gets_all_kategori", {
            tokenID:access_token()
        })
        .then(res=>{
            this.setState(prevState=>{
                return {
                    ...prevState,
                    kategori:res.data.data
                }
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //TAMBAH
    typeTambah=e=>{
        const target=e.target

        this.setState({
            [target.name]:target.value
        })
    }

    //BANNER
    //tambah
    showTambahBanner=()=>{
        this.setState({
            tambah_banner:{
                is_open:true,
                judul:"",
                deskripsi:"",
                gambar:{},
                link_to:""
            }
        })
    }
    hideTambahBanner=()=>{
        this.setState({
            tambah_banner:{
                is_open:false,
                judul:"",
                deskripsi:"",
                gambar:{},
                link_to:""
            }
        })
    }
    typeTambahBanner=e=>{
        const target=e.target

        this.setState({
            tambah_banner:update(this.state.tambah_banner, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addBanner=e=>{
        e.preventDefault()

        const {tambah_banner}=this.state
        const banner={
            id_banner:uniqid(),
            judul:tambah_banner.judul,
            deskripsi:tambah_banner.deskripsi,
            gambar:tambah_banner.gambar,
            link_to:tambah_banner.link_to
        }

        this.setState({
            banner_static:[banner].concat(this.state.banner_static)
        }, ()=>{
            this.hideTambahBanner()
        })
    }
    //edit
    showEditBanner=data=>{
        this.setState({
            edit_banner:{
                is_open:true,
                data:data,
                id_banner:data.id_banner,
                judul:data.judul,
                deskripsi:data.deskripsi,
                gambar:data.gambar,
                link_to:data.link_to
            }
        })
    }
    hideEditBanner=()=>{
        this.setState({
            edit_banner:{
                is_open:false,
                data:{},
                id_banner:"",
                judul:"",
                deskripsi:"",
                gambar:{},
                link_to:""
            }
        })
    }
    typeEditBanner=e=>{
        const target=e.target

        this.setState({
            edit_banner:update(this.state.edit_banner, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateBanner=e=>{
        e.preventDefault()

        const {edit_banner}=this.state
        const index=this.state.banner_static.map(bs=>JSON.stringify(bs)).indexOf(JSON.stringify(edit_banner.data))
        const banner={
            id_banner:edit_banner.id_banner,
            judul:edit_banner.judul,
            deskripsi:edit_banner.deskripsi,
            gambar:edit_banner.gambar,
            link_to:edit_banner.link_to
        }

        this.setState({
            banner_static:update(this.state.banner_static, {
                [index]:{$set:banner}
            })
        }, ()=>{
            this.hideEditBanner()
        })
    }
    //hapus
    showHapusBanner=data=>{
        this.setState({
            hapus_banner:{
                is_open:true,
                data:data
            }
        })
    }
    hideHapusBanner=()=>{
        this.setState({
            hapus_banner:{
                is_open:false,
                data:{}
            }
        })
    }
    deleteBanner=()=>{
        const banner_static=this.state.banner_static.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_banner.data))

        this.setState({
            banner_static:banner_static
        }, ()=>{
            this.hideHapusBanner()
        })
    }

    //WIDGET
    getWidgetScreenshot=tipe=>{
        const filtered=this.state.tipe_widget.filter(tw=>tw.id_widget===tipe)

        if(filtered.length>0){
            return <img src={filtered[0].screenshot} className="img-fluid"/>
        }
        return ""
    }
    getWidgetRenderInput=(type)=>{
        let data, tipe_widget, setData, action
        if(type==="tambah_widget"){
            data=this.state.tambah_widget.data
            tipe_widget=this.state.tambah_widget.tipe_widget
            action="tambah_widget"
            setData=(data, disabled)=>this.setDataTambahWidget(data, disabled)
        }
        else if(type==="edit_widget"){
            data=this.state.edit_widget.data
            tipe_widget=this.state.edit_widget.tipe_widget
            action="edit_widget"
            setData=(data, disabled)=>this.setDataEditWidget(data, disabled)
        }
        // else if(type==="edit_widget"){
        //     data=this.state.edit_widget.data
        // }
        
        if(tipe_widget==="chat_dokter"){
            return <ChatDokter data={data} setData={setData} action={action}/>
        }
        else if(tipe_widget==="jumbotron"){
            return <Jumbotron data={data} setData={setData} action={action}/>
        }
        else if(tipe_widget==="banner_statis"){
            return <BannerStatic data={data} setData={setData} action={action}/>
        }
        else if(tipe_widget==="link_icon"){
            return <LinkIcon data={data} setData={setData} action={action}/>
        }
    }
    //tambah
    showTambahWidget=()=>{
        this.setState({
            tambah_widget:{
                is_open:true,
                tipe_widget:"",
                data:{},
                disabled:true
            }
        })
    }
    hideTambahWidget=()=>{
        this.setState({
            tambah_widget:{
                is_open:false,
                tipe_widget:"",
                data:{},
                disabled:true
            }
        })
    }
    typeTambahWidget=e=>{
        const target=e.target

        this.setState({
            tambah_widget:update(this.state.tambah_widget, {
                [target.name]:{$set:target.value}
            })
        })
    }
    setDataTambahWidget=(data={}, disabled=true)=>{
        this.setState({
            tambah_widget:update(this.state.tambah_widget, {
                data:{$set:data},
                disabled:{$set:disabled}
            })
        })
    }
    addWidget=(e)=>{
        e.preventDefault()

        const {tambah_widget}=this.state
        const widget={
            id_widget:uniqid(),
            tipe_widget:tambah_widget.tipe_widget,
            data:tambah_widget.data
        }

        this.setState({
            widgets:this.state.widgets.concat([widget])
        }, ()=>{
            this.hideTambahWidget()
        })
    }
    //edit
    showEditWidget=data=>{
        this.setState({
            edit_widget:{
                is_open:true,
                widget:data,
                id_widget:data.id_widget,
                tipe_widget:data.tipe_widget,
                data:data.data,
                disabled:true
            }
        })
    }
    hideEditWidget=()=>{
        this.setState({
            edit_widget:{
                is_open:false,
                widget:{},
                id_widget:"",
                tipe_widget:"",
                data:{},
                disabled:true
            }
        })
    }
    setDataEditWidget=(data={}, disabled=true)=>{
        this.setState({
            edit_widget:update(this.state.edit_widget, {
                data:{$set:data},
                disabled:{$set:disabled}
            })
        })
    }
    updateWidget=e=>{
        e.preventDefault()

        const {edit_widget}=this.state
        const index=this.state.widgets.map(bs=>JSON.stringify(bs)).indexOf(JSON.stringify(edit_widget.widget))
        const widget={
            id_widget:edit_widget.id_widget,
            tipe_widget:edit_widget.tipe_widget,
            data:edit_widget.data
        }

        this.setState({
            widgets:update(this.state.widgets, {
                [index]:{$set:widget}
            })
        }, ()=>{
            this.hideEditWidget()
        })
    }
    //hapus
    showHapusWidget=data=>{
        this.setState({
            hapus_widget:{
                is_open:true,
                widget:data
            }
        })
    }
    hideHapusWidget=()=>{
        this.setState({
            hapus_widget:{
                is_open:false,
                widget:{}
            }
        })
    }
    deleteWidget=()=>{
        const widgets=this.state.widgets.filter(bs=>JSON.stringify(bs)!==JSON.stringify(this.state.hapus_widget.widget))

        this.setState({
            widgets:widgets
        }, ()=>{
            this.hideHapusWidget()
        })
    }

    //LAYANAN
    addLayanan=e=>{
        e.preventDefault()

        const data={
            banner_static:this.state.banner_static,
            id_kategori:this.state.id_kategori,
            widgets:this.state.widgets
        }

        API("/adminweb/layanan/add_layanan", {
            tokenID:access_token(),
            data:{
                nama_layanan:this.state.nama_layanan,
                data:data
            }
        })
        .then(res=>{
            this.setState({
                nama_layanan:"",
                banner_static:[],
                id_kategori:"",
                widgets:[]
            })
            toast.success("Berhasil menambahkan Layanan")
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }


    //UPLOAD IMAGE
    uploadImage=(e, target_state="tambah_banner")=>{
        const files=e.target.files

        //initialize form data
        const form_data=new FormData()
        form_data.append("image", files[0])
        form_data.append("path", "/banners")

        //upload to server
        API_FORM("/adminweb/file_manager/upload_image", {
            tokenID:access_token(),
            data:form_data
        })
        .then(res=>{
            if(target_state==="tambah_banner"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        tambah_banner:{
                            ...prevState.tambah_banner,
                            gambar:res.data.data
                        }
                    }
                })
            }
            else if(target_state==="edit_banner"){
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        edit_banner:{
                            ...prevState.edit_banner,
                            gambar:res.data.data
                        }
                    }
                })
            }
            
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //SELECT LINK
    setLink=(data)=>{
        let target
        if(this.state.tambah_banner.is_open){
            target="tambah_banner"
        }
        else if(this.state.edit_banner.is_open){
            target="edit_banner"
        }

        this.setState(prevState=>{
            return {
                ...prevState,
                [target]:{
                    ...prevState[target],
                    link_to:data
                }
            }
        })
    }
    showSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:true
            }
        })
    }
    hideSelectLink=()=>{
        this.setState({
            select_link:{
                is_open:false
            }
        })
    }

    render(){
        const {
            kategori,
            tipe_widget,
            nama_layanan,
            banner_static,
            id_kategori,
            widgets,
            tambah_banner,
            edit_banner,
            hapus_banner,
            tambah_widget,
            edit_widget,
            hapus_widget,
            is_adding,
            select_link

        }=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <form className="card" onSubmit={this.addLayanan}>
                                <div className="card-body">
                                    <div className="form-group mb-3 mt-4">
                                        <label class="form-label">Nama Layanan</label>
                                        <div className="input-icon col">
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                value={nama_layanan}
                                                name="nama_layanan"
                                                onChange={this.typeTambah}
                                            />
                                            <small className="form-hint"></small>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mt-4">
                                        <label class="form-label">Kategori Artikel</label>
                                        <div className="input-icon col">
                                            <select 
                                                class="form-select" 
                                                name="id_kategori"
                                                value={id_kategori}
                                                onChange={this.typeTambah}
                                            >
                                                <option value="">-- Pilih Kategori</option>
                                                {kategori.map(item=>(
                                                    <option value={item.id_kategori}>{item.nama_kategori}</option>
                                                ))}
                                            </select>
                                            <small className="form-hint text-muted">digunakan untuk menampilkan artikel terbaru dari kategori yang dipilih di halaman layanan ini</small>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mt-4">
                                        <div class="form-label d-flex justify-content-between">
                                            <span className="fw-semibold">Banner static</span>
                                            <button type="button" className="btn btn-info" onClick={this.showTambahBanner}><AiOutlinePlus/> Baru</button>
                                        </div>
                                        <div className="input-icon col">
                                            <div class="table-responsive">
                                                <table class="table table-centered table-nowrap mb-0 rounded">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th class="border-0 rounded-start" width="50">#</th>
                                                            <th class="border-0">Gambar</th>
                                                            <th class="border-0">Link To</th>
                                                            <th class="border-0 rounded-end p-0" width="90"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {banner_static.map((list, idx)=>(
                                                            <tr>
                                                                <td className="align-middle">{(idx+1)}</td>
                                                                <td><img src={BASE_URL+"/"+list.gambar.path} width="200"/></td>
                                                                <td>{list.link_to}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-warning btn-sm" onClick={()=>this.showEditBanner(list)}>
                                                                        Edit
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusBanner(list)}>
                                                                        Hapus
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 mt-4">
                                        <div class="form-label d-flex justify-content-between">
                                            <span className="fw-semibold">Widgets</span>
                                            <button type="button" className="btn btn-info" onClick={this.showTambahWidget}><AiOutlinePlus/> Baru</button>
                                        </div>
                                        <div className="input-icon col">
                                            <div className="row">
                                                {widgets.map(wid=>(
                                                    <div className="col-md-6 mb-3">
                                                        <div className="d-flex justify-content-between border p-3 rounded">
                                                            <div>
                                                                <h5 className="fw-semibold mb-0">{wid.data.judul}</h5>
                                                                <span className="text-muted">{wid.tipe_widget}</span>
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                <button className="btn btn-secondary" type="button" onClick={(e)=>this.showEditWidget(wid)}>Edit</button>
                                                                <button className="btn btn-danger mt-2" type="button" onClick={e=>this.showHapusWidget(wid)}>Hapus</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                   <div className="d-flex justify-content-center">
                                        <button 
                                            type="submit" 
                                            class="btn btn-primary btn-width-min" 
                                            disabled={
                                                this.state.nama_layanan.trim()===""||
                                                this.state.id_kategori.trim()===""||
                                                this.state.banner_static.length<1
                                            }
                                        >
                                            {is_adding?
                                                <Spinner size="sm" variant="light" animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            :
                                                <>Save Changes</>
                                            }
                                            
                                        </button>
                                   </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* WIDGET */}
                <Modal show={tambah_widget.is_open} size="lg" onHide={this.hideTambahWidget}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Widget</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addWidget}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Tipe Widget</label>
                            <select 
                                class="form-select" 
                                name="tipe_widget"
                                value={tambah_widget.tipe_widget}
                                onChange={this.typeTambahWidget}
                            >
                                <option value="">-- Pilih Widget</option>
                                {tipe_widget.map(item=>(
                                    <option value={item.id_widget}>{item.nama_widget}</option>
                                ))}
                            </select>
                        </div>
                        {tambah_widget.tipe_widget!==""&&
                            <div className="mb-4 border-top">
                                <div className="row">
                                    <div className="col-md-5 py-3">{this.getWidgetScreenshot(tambah_widget.tipe_widget)}</div>
                                    <div className="col-md-7">{this.getWidgetRenderInput("tambah_widget")}</div>
                                </div>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahWidget}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={tambah_widget.disabled}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <Modal show={edit_widget.is_open} size="lg" onHide={this.hideEditWidget}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Widget</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateWidget}>
                    <Modal.Body>
                        {edit_widget.tipe_widget!==""&&
                            <div className="mb-4 border-top">
                                <div className="row">
                                    <div className="col-md-5 py-3">{this.getWidgetScreenshot(edit_widget.tipe_widget)}</div>
                                    <div className="col-md-7">{this.getWidgetRenderInput("edit_widget")}</div>
                                </div>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditWidget}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={edit_widget.disabled}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <ConfirmDelete
                    show={hapus_widget.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusWidget()}
                    deleteAction={()=>this.deleteWidget()}
                />


                {/* BANNER */}
                <Modal show={tambah_banner.is_open} onHide={this.hideTambahBanner}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Banner</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addBanner}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeTambahBanner}
                                value={tambah_banner.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <textarea
                                rows={3} 
                                className="form-control"
                                name="deskripsi"
                                onChange={this.typeTambahBanner}
                                value={tambah_banner.deskripsi}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Gambar</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e=>this.uploadImage(e, "tambah_banner")}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(tambah_banner.gambar.path)&&
                                    <img src={`${BASE_URL}/${tambah_banner.gambar.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={tambah_banner.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahBanner}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={isUndefined(tambah_banner.gambar.path)||tambah_banner.link_to.trim()===""}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <Modal show={edit_banner.is_open} onHide={this.hideEditBanner}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Banner</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateBanner}>
                    <Modal.Body>
                    <div class="mb-4">
                            <label class="my-1 me-2" for="country">Judul</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="judul"
                                onChange={this.typeEditBanner}
                                value={edit_banner.judul}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Deskripsi</label>
                            <textarea
                                rows={3} 
                                className="form-control"
                                name="deskripsi"
                                onChange={this.typeEditBanner}
                                value={edit_banner.deskripsi}
                            />
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Gambar</label>
                            <div className="w-100 d-grid grip-1">
                                <button 
                                    className="btn btn-gray-200" 
                                    type="button"
                                    onClick={()=>this.inputElement.click()}
                                >
                                    <FiUpload/> Upload Gambar
                                </button>
                                <input
                                    ref={input=>this.inputElement=input}
                                    type="file"
                                    style={{display:"none"}}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e=>this.uploadImage(e, "edit_banner")}
                                />
                            </div>
                            <div className="mt-3">
                                {!isUndefined(edit_banner.gambar.path)&&
                                    <img src={`${BASE_URL}/${edit_banner.gambar.path}`} alt="" className="img-fluid"/>
                                }
                            </div>
                        </div>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Link To</label>
                            <div class="input-group mb-3">
                                <button 
                                    class="btn btn-info" 
                                    type="button" 
                                    id="button-addon1"
                                    onClick={this.showSelectLink}
                                >
                                    Pilih Link
                                </button>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    placeholder="" 
                                    aria-label="Example text with button addon" 
                                    aria-describedby="button-addon1"
                                    value={edit_banner.link_to}
                                    disabled
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditBanner}>Batal</button>
                        <button type="submit" class="btn btn-info">Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <ConfirmDelete
                    show={hapus_banner.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus mungkin tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusBanner()}
                    deleteAction={()=>this.deleteBanner()}
                />

                {/* LINK NAVIGASI */}
                <CustomLink
                    is_open={select_link.is_open}
                    setLink={data=>this.setLink(data)}
                    closeSelectLink={()=>this.hideSelectLink()}
                />
            </>
        )
    }
}


export default navAuth(AddLayanan, {
    title:"Tambah Layanan"
})