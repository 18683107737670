import React from "react"
import classNames from "classnames"
import update from "immutability-helper"
import { API } from "../../../config/api"
import { access_token, akronim, BASE_URL, goto_page, remove_login_data } from "../../../config/config"
import navAuth from "../nav_auth"
import Avatar from "../../../component/ui/avatar"
import NumberFormat from "react-number-format"
import moment from "moment"
import { Modal } from "react-bootstrap"
import {FiEdit} from "react-icons/fi"
import {datetime} from "../../../config/datetime"
import {AiOutlinePlus} from "react-icons/ai"
import { ConfirmDelete } from "../../../component/ui/confirm"

class Kategori extends React.Component{
    state={
        kategori:[],
        filter:{
            page:1,
            per_page:10,
            count_page:0,
            count_data:0
        },
        tambah_kategori:{
            is_open:false,
            nama_kategori:""
        },
        edit_kategori:{
            is_open:false,
            id_kategori:"",
            nama_kategori:""
        },
        hapus_kategori:{
            is_open:false,
            id_kategori:""
        }
    }

    componentDidMount=()=>{
        this.getsKategori()
    }
    getsKategori=()=>{
        API("/adminweb/kategori/gets_kategori", {
            tokenID:access_token(),
            data:{
                filter:this.state.filter
            }
        })
        .then(res=>{
            this.setState({
                kategori:res.data.data,
                filter:update(this.state.filter, {
                    count_page:{$set:res.data.count_page},
                    count_data:{$set:res.data.count_data}
                })
            })
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }
    goToPage=page=>{
        this.setState({
            filter:update(this.state.filter, {
                page:{$set:page}
            })
        }, ()=>{
            this.getsKategori()
        })
    }

    //TAMBAH KATEGORI
    showTambahKategori=()=>{
        this.setState({
            tambah_kategori:{
                is_open:true,
                nama_kategori:""
            }
        })
    }
    hideTambahKategori=()=>{
        this.setState({
            tambah_kategori:{
                is_open:false,
                nama_kategori:""
            }
        })
    }
    typeTambahKategori=e=>{
        const target=e.target

        this.setState({
            tambah_kategori:update(this.state.tambah_kategori, {
                [target.name]:{$set:target.value}
            })
        })
    }
    addKategori=e=>{
        e.preventDefault()

        API("/adminweb/kategori/add_kategori", {
            tokenID:access_token(),
            data:this.state.tambah_kategori
        })
        .then(res=>{
            this.getsKategori()
            this.hideTambahKategori()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //EDIT KATEGORI
    showEditKategori=data=>{
        this.setState({
            edit_kategori:{
                is_open:true,
                id_kategori:data.id_kategori,
                nama_kategori:data.nama_kategori
            }
        })
    }
    hideEditKategori=()=>{
        this.setState({
            edit_kategori:{
                is_open:false,
                id_kategori:"",
                nama_kategori:""
            }
        })
    }
    typeEditKategori=e=>{
        const target=e.target

        this.setState({
            edit_kategori:update(this.state.edit_kategori, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateKategori=e=>{
        e.preventDefault()

        API("/adminweb/kategori/update_kategori", {
            tokenID:access_token(),
            data:this.state.edit_kategori
        })
        .then(res=>{
            this.getsKategori()
            this.hideEditKategori()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    //HAPUS KATEGORI
    showHapusKategori=data=>{
        this.setState({
            hapus_kategori:{
                is_open:true,
                id_kategori:data.id_kategori
            }
        })
    }
    hideHapusKategori=()=>{
        this.setState({
            hapus_kategori:{
                is_open:false,
                id_kategori:""
            }
        })
    }
    deleteKategori=()=>{
        API("/adminweb/kategori/delete_kategori", {
            tokenID:access_token(),
            data:this.state.hapus_kategori
        })
        .then(res=>{
            this.getsKategori()
            this.hideHapusKategori()
        })
        .catch(err=>{
            switch(err.response.data.err_code){
                case "ERR_TOKEN_UNVERIFIED":
                    remove_login_data()
                break;
            }
        })
    }

    render(){
        const {filter, kategori, tambah_kategori, edit_kategori, hapus_kategori}=this.state

        return (
            <>
                <div className="d-flex flex-column mt-5" style={{minHeight:"67vh"}}>
                    <div class="row">
                        <div className="col-12">
                            <div class="card border-0 shadow mb-4">
                                <div class="card-body">
                                    <div className="d-flex mb-4">
                                        <button className="btn btn-primary" onClick={this.showTambahKategori}>
                                            <AiOutlinePlus/> Tambah Kategori
                                        </button>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-centered table-nowrap mb-0 rounded">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th class="border-0 rounded-start" width="50">#</th>
                                                    <th class="border-0">Nama Kategori</th>
                                                    <th class="border-0 rounded-end" width="90"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {kategori.map((list, idx)=>(
                                                    <tr>
                                                        <td className="align-middle">{(idx+1)+((filter.page-1)*filter.per_page)}</td>
                                                        <td>
                                                            {list.nama_kategori}
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-warning btn-sm" onClick={()=>this.showEditKategori(list)}>
                                                                Edit
                                                            </button>
                                                            <button type="button" className="btn btn-danger btn-sm ms-2" onClick={()=>this.showHapusKategori(list)}>
                                                                Hapus
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-between mt-4">
                                        <div className="d-flex flex-column">
                                            <div>Halaman {filter.page} dari {filter.count_page} Halaman</div>
                                            <div>Total Data : <span class="fw-bold fs-6">{filter.count_data}</span></div>
                                        </div>
                                        <div>
                                            <button 
                                                className="btn btn-gray-700" 
                                                disabled={filter.page<=1}
                                                onClick={()=>this.goToPage(filter.page-1)}
                                            >
                                                Sebelumnya
                                            </button>
                                            <button 
                                                className="btn btn-gray-700 ms-2" 
                                                disabled={filter.page>=filter.count_page}
                                                onClick={()=>this.goToPage(filter.page+1)}
                                            >
                                                Selanjutnya
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH KATEGORI */}
                <Modal show={tambah_kategori.is_open} onHide={this.hideTambahKategori}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Kategori</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.addKategori}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Nama Kategori</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_kategori"
                                onChange={this.typeTambahKategori}
                                value={tambah_kategori.nama_kategori}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideTambahKategori}>Batal</button>
                        <button type="submit" class="btn btn-info" disabled={tambah_kategori.nama_kategori===""}>Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL EDIT KATEGORI */}
                <Modal show={edit_kategori.is_open} onHide={this.hideEditKategori}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Kategori</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.updateKategori}>
                    <Modal.Body>
                        <div class="mb-4">
                            <label class="my-1 me-2" for="country">Nama Kategori</label>
                            <input 
                                type="text" 
                                className="form-control"
                                name="nama_kategori"
                                onChange={this.typeEditKategori}
                                value={edit_kategori.nama_kategori}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" class="btn btn-link text-gray me-auto" onClick={this.hideEditKategori}>Batal</button>
                        <button type="submit" class="btn btn-info">Save Changes</button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS */}
                <ConfirmDelete
                    show={hapus_kategori.is_open}
                    title="Apakah anda Yakin?"
                    sub_title="Data yang sudah dihapus tidak bisa dikembalikan lagi!"
                    toggle={()=>this.hideHapusKategori()}
                    deleteAction={()=>this.deleteKategori()}
                />
            </>
        )
    }
}

export default navAuth(Kategori, {
    title:"Kategori Post/Artikel"
})